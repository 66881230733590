import { Injectable } from '@angular/core';
import { ProductApiService } from '../../product-api/product-api.service';
import { Observable, BehaviorSubject, of, filter, switchMap, takeUntil, reduce, take, delay, tap } from 'rxjs';
import { BroadbandDeal, BroadbandOrder, BroadbandOrderGeneralDetails, BroadbandPaymentDetails, BroadbandSwitchObject } from 'src/app/types/beta-optimize-models.model';
import * as forge from 'node-forge';
import { environment } from 'src/environments/environment';
import { PersonalizationModel } from 'src/app/types';
import { ModalService } from '../../modal-service/modal-service.service';
import { ProductService } from '../../product-service/product-service.service';
import produce, { Draft } from 'immer';
import { Store } from '@ngrx/store';
import { BroadbandState, setOrderDetails, setServiceModel } from 'src/app/components/product/dashboard/subscription-panel/switch-panel-broadband/state';


@Injectable()
export class BroadbandService {

  constructor(
    protected product_api: ProductApiService,
    protected modal_service: ModalService,
    protected product: ProductService,
    protected api: ProductApiService,
    protected store: Store<{ broadband_switch: BroadbandState }>

  ) { }

  private modelSubject: BehaviorSubject<BroadbandSwitchObject> = new BehaviorSubject<BroadbandSwitchObject>(null);
  public model$: Observable<BroadbandSwitchObject> = this.modelSubject.asObservable();

  private prePopulateubject: BehaviorSubject<BroadbandSwitchObject> = new BehaviorSubject<BroadbandSwitchObject>({subscription_id: '<>'});
  public prePopulate$: Observable<BroadbandSwitchObject> = this.modelSubject.asObservable();

  private broadbandSwitchModelsMap = new Map<string, BehaviorSubject<BroadbandSwitchObject | null>>();
  private broadbandSwitchModelsHangning = new Map<string, BehaviorSubject<string>>();
  private lastDealsAttributes: PersonalizationModel | null = null;

  public getBroadbandSwitchModel(sub_id: string): BehaviorSubject<BroadbandSwitchObject | null> {
    if (!this.broadbandSwitchModelsMap.has(sub_id)) {
      var m :BroadbandSwitchObject  = { subscription_id: sub_id };
      const newSubject = new BehaviorSubject<BroadbandSwitchObject | null>(m);
      this.broadbandSwitchModelsMap.set(sub_id, newSubject);
      
      this.product.getPersonalizatioAttributes().pipe(take(1)).subscribe( atts => {
        this.updateModel(sub_id, draft => {
          if (atts.address_id) {
            draft.postcode = atts.postcode.value;
            draft.selected_address_id = atts.address_id.value;
          }
        });
      });

      const modalOpen$ = this.modal_service.subscribeToModalChanges().pipe(
        filter(modal => modal.name === 'personalization' && modal.is_open)
      );
      
      const modalClose$ = this.modal_service.subscribeToModalChanges().pipe(
        filter(modal => modal.name === 'personalization' && !modal.is_open)
      );
      
      const lastAttributesOnClose$ = modalOpen$.pipe(
        switchMap(() => this.product.getPersonalizatioAttributes().pipe(
          takeUntil(modalClose$),
          reduce((acc, curr) => curr, null)
        ))
      );
      
      lastAttributesOnClose$.pipe(
        filter(r =>
          JSON.stringify(r) != JSON.stringify(this.lastDealsAttributes || {})
        ),
      ).subscribe(lastAttributes => {
        if (lastAttributes !== null && lastAttributes.address_id.value) {
          this.getDeals(sub_id).subscribe();
          this.lastDealsAttributes = lastAttributes;
        }
      });
      
    }
    return this.broadbandSwitchModelsMap.get(sub_id)!;
  }

  public updateModel(sub_id: string, updateFunction: (draft: Draft<BroadbandSwitchObject>) => void) {
    const currentModelSubject = this.broadbandSwitchModelsMap.get(sub_id);
    if (!currentModelSubject || !currentModelSubject.value) return;

    const updatedModel = produce(currentModelSubject.value, updateFunction);
    this.mapModelToApi(updatedModel);
    currentModelSubject.next(updatedModel);
  }

  public getDeals(subscription_id: string) {
    const model = this.broadbandSwitchModelsMap.get(subscription_id).value;

    return this.product.getPersonalizatioAttributes().pipe(take(1),
      switchMap(attrs => {
        this.lastDealsAttributes = attrs;
        
        if (this.lastDealsAttributes.address_id) {
          if (attrs.address_id) {
            model.postcode = attrs.postcode.value;
            model.selected_address_id = attrs.address_id.value;
          } else {
            return of(null);
          }
        }

        this.getBroadbandSwitchModelStatus(subscription_id).next('deals');
        return this.api.optimizeBroadband(model).pipe(delay(3000), tap(
          r => {
            this.broadbandSwitchModelsMap.get(subscription_id)?.next(r);
            this.getBroadbandSwitchModelStatus(subscription_id).next(null);
          }
        ));
      }));
  }

  public getBroadbandSwitchModelStatus(sub_id: string): BehaviorSubject<string> {
    if (!this.broadbandSwitchModelsHangning.has(sub_id)) {
      const newSubject = new BehaviorSubject<string>('hanging');
      this.broadbandSwitchModelsHangning.set(sub_id, newSubject);
    }
    return this.broadbandSwitchModelsHangning.get(sub_id)!;
  }

  // Journey

  public mapModelToApi(model: BroadbandSwitchObject) {
    if (model.deals) {

    }
  }

  protected getDealDetails(sub_id: string) {
    const model = this.broadbandSwitchModelsMap.get(sub_id).value;
    return this.product_api.optimizeAutoBranch('broadband', model );
  }
  


  public getModel(): Observable<BroadbandSwitchObject> {
    return this.store.select(state => state.broadband_switch.models[state.broadband_switch.current_sub_id]);
  }

  public setModel(model: BroadbandSwitchObject): void {
    this.store.dispatch( setServiceModel({model: model}));
  }

  public setOrder(orderDetails: BroadbandOrder): void {
    this.store.dispatch(
      setOrderDetails({ orderDetails: orderDetails})
    );
  }

  public getSelectedDeal(): BroadbandDeal {
    if (!this.modelSubject.value || !this.modelSubject.value.selected_deal_id) {
      return null;
    }
    const model = this.modelSubject.value;
    return model.deals.find((deal) => deal.id === model.selected_deal_id);
  }

  public encryptData(data: string): string {
    const publicKey = environment.sensitive_data_public_rsa_key.replace(/\\n/g, '\n');
    const publicKeyObj = forge.pki.publicKeyFromPem(publicKey);
    const encrypted = publicKeyObj.encrypt(data, 'RSA-OAEP');
    const enc = forge.util.encode64(encrypted);
    return enc;
  }

  public getPrepopulatedModel(): BehaviorSubject<BroadbandSwitchObject> {
    return this.prePopulateubject;
  }
  public setPrePopulatePostcode(postcode) {    
    this.prePopulateubject.next({...this.prePopulateubject.value, postcode: postcode});
  }

  public setPrePopulateGeneralDetails(general_details : BroadbandOrderGeneralDetails) {
    console.log(this.prePopulateubject.value);
    
    const a = this.prePopulateubject.value;
    a.order = {
      generalDetails : general_details

    }

    this.prePopulateubject.next(a);
  }

  public setPrePopulateLandline(landline : string) {
    console.log(this.prePopulateubject.value);

    this.prePopulateubject.next({
      ...this.prePopulateubject.value,
      order: {
        installationAndLandline: {
          landlineNumber : landline
        }
      }
    });
  }

}
