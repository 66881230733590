import { Component, OnInit, Input } from '@angular/core';
import { ProductService } from 'src/app/services/product-service/product-service.service';
import { ProductState, removeToast } from '../../state/product-state.state';
import { Store } from '@ngrx/store';


export class MsgPopuopModel {
  loading : boolean = false;
  loading_msg : string;
  loading_subtitle : string;
  error : string;

  public setError(error : string) {
    this.error = error;
    // setTimeout(() => {
    //   this.clearError();
    // }, 7000);
  }

  public clearError() {
    this.error = null;
  }
}

export class ActionPopupModel {
  title : string;
  subtitle : string;
  actions : {
    text : string, 
    light : boolean,
    callback : Function;
  }[]

  reset() {
    this.title = '';
    this.subtitle = '';
    this.actions = [];
  }
}

@Component({
  selector: 'app-msg-popup',
  templateUrl: './msg-popup.component.html',
  styleUrls: ['./msg-popup.component.scss']
})
export class MsgPopupComponent implements OnInit {
  @Input() msgModel : MsgPopuopModel;
  @Input() actionPopup : ActionPopupModel;
  toasts ;

  constructor(
    protected product : ProductService,
    protected store: Store<{ product : ProductState }>
  ) { }


  ngOnInit(): void {
    this.toasts = this.store.select(state => state.product.toasts);
  }

  hideToast(toast) {
    this.store.dispatch(removeToast({id: toast.id}));
  }

  applyCallback(a) {
    a.callback();
    this.product.actionModel.reset();
  }

}
