<div class="d-flex flex-column py-lg-3 px-lg-16 py-lg-24 px-lg-64 w-100 h-100 gap-lg-16 profile" style="min-height: 65vh;">

    <button class="modal-close-btn" autofocus="false" (click)="_activeModal.dismiss()" appIsInsideModal>
        <img alt="close panel button" src="assets/icons/thick-x.svg">
      </button>
      
  <span class="subtitle2-md-heading1 d-none d-lg-flex w-100">Settings</span>

  <nav class="nav nav-pills nav-fill flex-row gap-8">
    <a class="nav-link" [class.active]="currentTab == i" href="javascript:void(0);" *ngFor="let i of tabNames" (click)="changeTab(i)" appPointerFeedback> {{ i }}</a>
  </nav>

  <div class="flex-grow-1 py-8 py-lg-0">
    <ng-container [ngSwitch]="currentTab">
      <ng-container *ngSwitchDefault>
        <app-my-account></app-my-account>
      </ng-container>

      <ng-container *ngSwitchCase="'Contact Us'">
        <app-contact-us></app-contact-us>
      </ng-container>

      <ng-container *ngSwitchCase="'Logout'">
        <app-logout></app-logout>
      </ng-container>
    </ng-container>

  </div>



</div>