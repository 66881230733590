import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { createAction, props, Store } from "@ngrx/store";
import { map, mergeMap, switchMap, tap, withLatestFrom } from "rxjs";
import { fetchAttributes } from "../common/modals/personalization-modal/state";
import { fetchNotifications } from "../dashboard/feed/state/state";
import { initiateInterests } from "../dashboard/subscription-panel/switch-panel-interests/state";
import { fetchAccountDetails, fetchCustomization, fetchJourneyState, fetchUserDetails, initilaizeDashboard, ProductState, setInitialLoadComplete } from "./product-state.state";
import { ROUTER_SERVICE_TOKEN } from "src/app/services/product-router/router-token.di";
import { BaseRouterService } from "src/app/services/product-router/product-router.service";
import { ActivatedRoute } from "@angular/router";
import { AUTH_SERVICE_TOKEN } from "src/app/services/authentication_services/auth-token.di";
import { ProductAuthenticationService } from "src/app/services/authentication_services/product-auth.service";

export const navigateRestrictedAccess = createAction('[Lifecycle] navigateRestrictedAccess');
export const NoOp = createAction('[Lifecycle] no-op', props<{ message: string }>());


@Injectable()
export class ProductLifecycleEffects {
    constructor(
        private actions$: Actions,
        private store: Store<{ product: ProductState, }>,
        @Inject(ROUTER_SERVICE_TOKEN) private _product_router: BaseRouterService,
        @Inject(AUTH_SERVICE_TOKEN) private authService: ProductAuthenticationService,

        private activated_route: ActivatedRoute
    ) { }


    fetchDashboard$ = createEffect(() => this.actions$.pipe(
        ofType(initilaizeDashboard),
        withLatestFrom(
            this.store.select(state => state.product),
        ),
        switchMap(([action, state]) => {
            return [
                fetchCustomization(),
                fetchUserDetails(),
                fetchAccountDetails(),
                fetchJourneyState(),
                fetchNotifications()
            ]
        }
        )));

    postIntialLoadCalls$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setInitialLoadComplete),
            mergeMap(() => [
                fetchAttributes(),
                initiateInterests()
            ])
        )
    );

    navigateRestrictedAccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(navigateRestrictedAccess),
            tap(a => {
                Object.keys(localStorage).forEach(key => {
                    if (key.startsWith('CognitoIdentityServiceProvider')) {
                        localStorage.removeItem(key);
                    }
                });
            }),
            tap(a => this._product_router.restrictedAccess(this.activated_route)),
            map(() => NoOp({ message: 'navigateRestrictedAccess' }))
        )
    );



}