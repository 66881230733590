import {animate, group, state, style, transition, trigger} from "@angular/animations";
import { translateXY } from "@swimlane/ngx-datatable";


export const FadeAnimation = [
    trigger('FadeAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('250ms ease-in-out', style({ opacity: 0 })),
      ]),  ])
  ]

  export const DelayedFadeAnimtaion = [
    trigger('DelayedFadeAnimtaion', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms 3s ease-in-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ popacity: 1 }),
        animate('250ms ease-in-out', style({ opacity: 0 })),
      ]),  ])
  ]

  export const enterLeftleaveLeft = [
    trigger('enterLeftleaveLeft', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0  }),
        animate('500ms ease-in-out', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('500ms ease-in-out', style({ transform: 'translateX(-100%)', opacity: 0 })),
      ]),  ])
  ]

  export const leaveLeft = [
    trigger('leaveLeft', [
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('500ms ease-in-out', style({ transform: 'translateX(-100%)', opacity: 0 })),
      ]),  ])
  ]