import { Component, OnInit, NgZone, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'src/app/services/product-service/product-service.service';

declare global {
  interface Window {
    Calendly: {
      initInlineWidget: (config: {
        url: string;
        parentElement: HTMLElement;
        prefill?: any;
        utm?: any;
      }) => void;
    };
  }
}

@Component({
  selector: 'app-calendly-modal',
  templateUrl: './calendly-modal.component.html',
  styleUrls: ['./calendly-modal.component.scss']
})
export class CalendlyModalComponent implements OnInit {

  constructor(
    private ngZone: NgZone,
    protected product: ProductService,
    public _activeModal: NgbActiveModal,
  ) { }

  prefill_name : string;
  prefill_email : string;
  hanging : boolean = false;
  demo_mode : boolean = false;

  ngOnInit(): void {
    // this._cognito.getUser().subscribe(res => {
    //   res.getUserAttributes( (err, atts) => {

    //       let at = atts.filter( a => a.getName() == 'name')
    //       if (at.length == 1) {
    //         this.prefill_name =  at[0].getValue();
    //       } 

    //       at = atts.filter( a => a.getName() == 'email');
    //       if (at.length == 1) {
    //         this.prefill_email = at[0].getValue();
    //       }

    //   });
    // })
    
    this.prefill_name = 'Saver Steve';
    this.prefill_email = 'steveTheSaver@gmail.com';
    setTimeout(() => {
      this.loadCalendly();
    }, 300);
  }


  loadCalendly() {
    this.hanging = true;
    window.Calendly.initInlineWidget({
      url: 'https://calendly.com/paylow-partnerships/30min?hide_event_type_details=1',
      parentElement: document.getElementById('calendly-div'),
      prefill: {
        name: this.prefill_name || '',
        email: this.prefill_email || '',
        customAnswers: {
          a1: '', // Phone number
          a2: 'Paylow identified I could be suitable to save money by refinancing my mortgage, as my current plan ends in less than six months.', // Please share anything that will help prepare for our meeting.
          a3: '' // Send text messages to
        }
      },
      utm: {}
     });
     this.setupMessageListener();
  }

  private isCalendlyEvent(e: MessageEvent): boolean {
    return (
      e.origin === 'https://calendly.com' &&
      e.data.event &&
      e.data.event.indexOf('calendly.') === 0
    );
  }

  private setupMessageListener(): void {
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('message', (e: MessageEvent) => {
        this.ngZone.run(() => {
          if (this.isCalendlyEvent(e)) {
            const eventName = e.data.event;
            const eventPayload = e.data.payload;
            this.handleCalendlyEvent(eventName, eventPayload);
          }
        });
      });
    });
  }

  handleCalendlyEvent(name, payload): void {  
    console.log(name);
    
    switch(name) {
      case 'calendly.event_type_viewed':
        this.hanging = false;
        break;
      case 'calendly.event_scheduled':
        this._activeModal.close(true);
        break;
    }
  
  }
}
