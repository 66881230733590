

<div class="d-flex flex-column align-items-center gap-16 p-32" id="crossbrowser-msg">
    <button class="modal-close-btn" autofocus="false" (click)="_activeModal.dismiss()">
        <img alt="close panel button" src="assets/icons/thick-x.svg">
      </button>
      
    <div class="d-flex flex-column align-items-center text-center gap-16">
        <span class="subtitle1">Ops, you need to login again</span>
        <span class="body1">Its seems that privacy settings require you to login once again for the bank link to complete.</span>
    </div>
    
    <button class="mt-3 btn btn-primary btn-submit" id="btn-next" (click)="_activeModal.dismiss()"> Continue
    </button>
</div>
