import {PaylowBadge} from './paylow-badge.model';

interface PlanBase {
  name: string;
  id: string;
  description: string;
  logoUrl: string | null;
  price: number;
  paymentPeriod: string;
}

export interface ActivePlan extends PlanBase {
}

export interface SuggestedPlan extends PlanBase {
  reason: string;
  yearlySave?: number;
  reasonHighlight?: string;
}


// OLD
export interface SubscriptionItem {
  name: string;
  logoUrl: string;
  tags: string[];
  activePlan: ActivePlan;
  badges: PaylowBadge[];
  plans: SuggestedPlan[];
  activeFlow: 'cancel' | 'switch' | 'none';
  isSwitching?: boolean;
  isCanceled?: boolean;
  transactions_refs?: any;
  tag: string;
}

export type SubscriptionStatus2 = 'improvable' | 'unimprovable' | 'improving' | 'canceled';

export interface UserSubscription {
  name: string;
  logoUrl: string;
  tags: string[];
  activePlan: ActivePlan;
  badges: PaylowBadge[];
  plans: SuggestedPlan[];
  status: SubscriptionStatus2;

  identification_source: string;
  tag: string;
}

// export interface SubscriptionProvider {
//   provider_id: number;
//   provider_name: string;
//   presentative_name: string;
//   category: string;
//   domain: string;
//   // regex : string;
// }

export interface SubscriptionQA extends UserSubscription {
  transactions_refs?: any;
  // provider?: SubscriptionProvider;
  regex?: string;
}

export interface SubscriptionsRes {
  body: {
    subscriptions: SubscriptionQA[];
  };
}

export interface ProviderRes {
  body: {
    text: string
  }
}
