<div class="section-left" *ngIf="mode == 'standalone'">
    <button class="modal-close-btn" autofocus="false" (click)="_activeModal.dismiss()" appIsInsideModal>
        <img alt="close panel button" src="assets/icons/thick-x.svg">
    </button>

    <ng-template #header>
        <div class="d-flex flex-row  align-items-center gap-16 p-8">
            <div class="bg-primary p-16 rounded text-white logo">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
                    [attr.style]="'fill: white; stroke: white;'">
                    <path
                        d="M163.9 136.9c-29.4-29.8-29.4-78.2 0-108s77-29.8 106.4 0l17.7 18 17.7-18c29.4-29.8 77-29.8 106.4 0s29.4 78.2 0 108L310.5 240.1c-6.2 6.3-14.3 9.4-22.5 9.4s-16.3-3.1-22.5-9.4L163.9 136.9zM568.2 336.3c13.1 17.8 9.3 42.8-8.5 55.9L433.1 485.5c-23.4 17.2-51.6 26.5-80.7 26.5L192 512 32 512c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l36.8 0 44.9-36c22.7-18.2 50.9-28 80-28l78.3 0 16 0 64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0-16 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l120.6 0 119.7-88.2c17.8-13.1 42.8-9.3 55.9 8.5zM193.6 384c0 0 0 0 0 0l-.9 0c.3 0 .6 0 .9 0z" />
                </svg>

            </div>
            <div class="d-flex flex-column">
                <span class="subtitle2-md-subtitle1 gradient-text">Benefits</span>
                <span class="body1-md-subtitle2 ">Claim what is yours</span>
            </div>
        </div>

    </ng-template>

    <app-switch-steps [step]="this.step_index" [steps_names]="step_names" [show_header]="false"
        [header_template]="header" (step_cliecked_callback)="stepClicked($event)">
    </app-switch-steps>
</div>

<div class="flex-grow-1 h-100 section-right pt-lg-16 p-md-40  p- overflow-auto flex-row">

    <ng-container *ngIf="this.hanging_step == null else hanging_step_template">
        <ng-container [ngSwitch]="this.step">
            <ng-container *ngSwitchCase="'light-questions'">
                <div class="d-flex h-100">

                    <app-light-estimation [questions]="(this.light_questions | async)"
                        (next)="lightQuestionsNext($event)" (back)="dealBackClicked($event)">
                    </app-light-estimation>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'estimation'">
                <div class="d-flex h-100">
                    <app-estimation [estimation]="(this.estimation | async)" (back)="dealBackClicked($event)"
                        (next)="estimationNext()">
                    </app-estimation>
                </div>

            </ng-container>

            <ng-container *ngSwitchCase="'full-questions'">
                <div [@FadeAnimation] class="d-flex h-100">

                    <app-full-questions (back)="dealBackClicked('estimation')" (next)="fullQuestionsNext()">
                    </app-full-questions>
                </div>

            </ng-container>

            <ng-container *ngSwitchCase="'deals'">
                <div [@FadeAnimation] class="d-flex h-100">

                    <app-deals-list [deals]="(this.deals | async)" (dealClick)="dealClicked($event)"
                        (back)="dealBackClicked('full-questions')" [showFeedback]="this.request_feedback"
                        (switchFeedback)="switchFeedback($event)" (dealCheckout)="dealCheckout($event)">
                    </app-deals-list>
                </div>

            </ng-container>

            <ng-container *ngSwitchCase="'feedback'">
                <div [@FadeAnimation] class="d-flex h-100">

                    <app-feedback (back)="dealBackClicked('deals')" (switchFeedback)="null">
                    </app-feedback>
                </div>

            </ng-container>

        </ng-container>

    </ng-container>

    <ng-template #hanging_step_template>
        <ng-container [ngSwitch]="hanging_step">
            <div *ngSwitchDefault class="loader">
                <i class="fa fa-spinner fa-spin"> </i>
            </div>
        
            <div *ngSwitchCase="'deals'" [@FadeAnimation] style="height: calc(100% - 5px);">
                <div class="d-flex justify-content-center align-items-center" style="height: calc(100% - 5px);">
                    <app-long-loader
                        [show_animation]="true"
                        [show_loader]="false"
                        [texts_cycle]="[
                                    'Scanning benefits...', 
                                    'Personalising...']">
                    </app-long-loader>
                </div>
            </div>
        </ng-container>

    </ng-template>





</div>