import { Action, Store, createAction, createFeatureSelector, createSelector, props, select } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merge, of } from 'rxjs';
import { map, mergeMap, catchError, withLatestFrom, tap, filter, concatMap, delay, take, first, finalize, switchMap } from 'rxjs/operators';
import { ProductApiService, addMinimumWaitTime } from 'src/app/services/product-api/product-api.service';
import { MobileSwitchObject, UserAttribute } from 'src/app/types/beta-optimize-models.model';
import { ProductService } from 'src/app/services/product-service/product-service.service';
import { Draft, produce } from 'immer';
import { state } from '@angular/animations';
import { attributeUpdated } from '../switch-panel-broadband/state';
import { UserAttributesState, setAttributes, setNewAttributes } from '../../../common/modals/personalization-modal/state';
import { Provider } from 'src/app/types';
import { addToast } from '../../../state/product-state.state';


export interface BaseOffer {
    offer_id : number;
    title : string;
    text : string;
    checkout_url : string;
    source : string;
    provider_name : string;
    provider_id : number;
    logo_url : string;
    price? : number;
    category?: string
}

export interface InterestsOffer extends BaseOffer {
    indications : string[]
}

export interface InterestsSwitchObject {
    offers: InterestsOffer[],
    dismissed: number[],
    claimed : string[],
    selected_offer_id: string,
}

export interface InterestsSwitchState {
    api_calls: string[],
    sub_step: string;
    step: string;
    model : InterestsSwitchObject,
    display_mode: string

    
}

// Initial state
const initialState: InterestsSwitchState = {
    model: {    offers: [],
        dismissed: [],
        claimed : [],
        selected_offer_id: ''},
    api_calls: [],
    sub_step: '',
    step: '',
    display_mode: 'active'
};


export interface BenefitQuestionObject {
    text: string;
  }

// Define actions
export const initiateInterests = createAction('[Interests] initiate');
export const updateModel = createAction('[Interests] Update model', props<{ name: string, model: InterestsSwitchObject }>());
export const fetchClaimed = createAction('[Interests] fetchClaimed', props<{ id: string}>());

export const removeSubscription = createAction('[Interests] Remove model', props<{ subscription_id: string }>());

export const setActiveSubscriptionId = createAction('[Interests] setActiveSubscriptionId', props<{ subscription_id: string }>());
export const setInactive = createAction('[Interests] setInactive', props<{ subscription_id: string }>());
export const setViewMode = createAction('[Interests] Set View Mode', props<{ view_mode: 'standalone' | 'bundle' }>());

export const addApiCall = createAction('[Interests] Add hangning', props<{ name: string }>());
export const removeApiCall = createAction('[Interests] Remove hangning', props<{ name: string }>());

export const updateStep = createAction('[Interests] updateStep', props<{ step: string }>());

export const stepNext = createAction('[Interests] stepNext');
export const stepBack = createAction('[Interests] stepBack');

export const dealSelect = createAction('[Interests] dealSelect', props<{ deal_id: string | number }>());
export const fetchDeals = createAction('[Interests] fetchDeals',  props<{ caller : string }>());
export const fetchEstimation = createAction('[Interests] fetchEstimation');



export const updateOrderResult = createAction('[Interests] updateOrderResult', props<{ result: string }>());
export const setHandset = createAction('[Interests] setHandset', props<{ new_value: boolean }>());
export const updateClaimed = createAction('[Interests] updateClaimed', props<{ offer_id: string }>());
export const setSelectedDeal = createAction('[Interests] setSelectedDeal', props<{ id: string }>());
export const setSelectedOfferId = createAction('[Interests] setSelectedOfferId', props<{ offer_id: string }>());
export const setDismissed = createAction('[Interests] setDismissed', props<{ offer_id: number }>());
export const fetchDismissed = createAction('[Interests] fetchDismissed', props<{ offer_id: number }>());

export const setDisplayMode = createAction('[Interests] setDisplayMode', props<{ mode: string }>());




export const mobileSwitched = createAction('[Interests] switched');

export const noop = createAction('[Interests] noop');

const steps = ['select-deal', 'deal-view'];
const subSteps = [
    [],
    [],
    [],
    []
];

// Create reducer
export const interestsReducer = createReducer(
    initialState,
    on(setViewMode, (state, { view_mode }) => ({
        ...state,
        view_mode: view_mode
    })),
    on(updateStep, (state, { step }) => ({
        ...state,
        step: step
    })),
    on(updateModel, (state, { model }) => ({
      ...state,
      model: model
  })),    
  on(addApiCall, (state, { name }) => ({
        ...state,
        api_calls: state.api_calls.includes(name) ? state.api_calls : [...state.api_calls, name]
    })),
    on(removeApiCall, (state, { name }) => ({
        ...state,
        api_calls: state.api_calls.filter(n => n != name)
    })),
    on(stepNext, (state) => {
        const currentStepIndex = steps.indexOf(state.step);
        const currentSubStepIndex = subSteps[currentStepIndex].indexOf(state.sub_step);

        if (currentSubStepIndex < subSteps[currentStepIndex].length - 1) {
            return {
                ...state,
                sub_step: subSteps[currentStepIndex][currentSubStepIndex + 1]
            };
        } else if (currentStepIndex < steps.length - 1) {
            return {
                ...state,
                step: steps[currentStepIndex + 1],
                sub_step: subSteps[currentStepIndex + 1][0] || ''
            };
        }
        return state;
    }),
    on(stepBack, (state) => {
        const currentStepIndex = steps.indexOf(state.step);
        const currentSubStepIndex = subSteps[currentStepIndex].indexOf(state.sub_step);

        if (currentSubStepIndex > 0) {
            return {
                ...state,
                sub_step: subSteps[currentStepIndex][currentSubStepIndex - 1]
            };
        } else if (currentStepIndex > 0) {
            const prevStepIndex = currentStepIndex - 1;
            return {
                ...state,
                step: steps[prevStepIndex],
                sub_step: subSteps[prevStepIndex][subSteps[prevStepIndex].length - 1] || ''
            };
        }
        return state;
    }),
   on(updateClaimed, (state, { offer_id }) => ({
    ...state,
    model: {
        ...state.model,
        claimed: [...state.model.claimed, offer_id.toString()]
    }
    })),
    on(setSelectedDeal, (state, { id }) => ({
        ...state,
        model: {
            ...state.model,
            selected_deal_id: id
        }
    })),
    on(setSelectedOfferId, (state, { offer_id }) => ({
        ...state,
        model: {
            ...state.model,
            selected_offer_id: offer_id
        }
    })),
    on(setDismissed, (state, { offer_id }) => ({
        ...state,
        model: {
            ...state.model,
            dismissed: [
                ...state.model.dismissed, offer_id
            ]
        }
    })),

    on(setDisplayMode, (state, { mode }) => {
        let dis = mode;

        if (!dis) {
            switch (state.display_mode) {
                case 'active':
                    dis = 'dismissed';
                    break;
                case 'dismissed':
                    dis = 'active';
                    break;
                default:
                    dis = 'active';
            }
        }
        return { 
            ...state,
                display_mode: dis
        
        };
    }),
);


@Injectable()
export class InterestsSwitchEffects {
    constructor(
        private actions$: Actions,
        private api: ProductApiService,
        private product: ProductService,
        private store: Store<{ interests_switch: InterestsSwitchState }>,
        private attribute_store: Store<{ user_attributes: UserAttributesState }>
    ) { }

    startJorney$ = createEffect(() =>
      this.actions$.pipe(
        ofType(initiateInterests),
        withLatestFrom(this.store.select(state => state.interests_switch)),
        tap(() => this.store.dispatch(addApiCall({ name: 'state' }))),
        concatMap(([action, state]) =>
          this.api.getInterests().pipe(
            concatMap(res => {
              return [
                updateModel({ name: 'startJorney', model: res }),
                updateStep({ step: 'select-deal' })
              ];
            }),
            catchError(() => of({ type: '[Benefit] Error' })),
            finalize(() => {
              this.store.dispatch(removeApiCall({ name: 'state' }));
            })
          )
        )
      )
    );


    fetchClaimed$ = createEffect(() =>
        this.actions$.pipe(
          ofType(fetchClaimed),
          withLatestFrom(this.store.select(state => state.interests_switch)),
          tap(([action,state]) => this.store.dispatch( addApiCall({name: `_claim#${action.id}`}) )),
          concatMap(([action, state]) =>
            this.api.claimInterest(action.id).pipe(
              map(res => updateClaimed({ offer_id: action.id })), 
              catchError(() => of({ type: '[interests] Error' })),
              finalize(() => {
                this.store.dispatch(removeApiCall({name: `_claim#${action.id}`}));
              })
            )
          )
        )
      );

      fetchDismissed$ = createEffect(() =>
        this.actions$.pipe(
          ofType(fetchDismissed),
          withLatestFrom(this.store.select(state => state.interests_switch)),
          tap(([action,state]) => this.store.dispatch( addApiCall({name: `_claim#${action.offer_id}`}) )),
          concatMap(([action, state]) =>
            this.api.dismissInterest(action.offer_id.toString()).pipe(
              map(res => setDismissed({ offer_id: action.offer_id })), 
              catchError(() => of({ type: '[interests] Error' })),
              finalize(() => {
                this.store.dispatch(removeApiCall({name: `_claim#${action.offer_id}`}));
              })
            )
          )
        )
      );

}