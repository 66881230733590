import { Component, OnInit, Input } from '@angular/core';
import { ProductService } from 'src/app/services/product-service/product-service.service';
import { ProductState, removeToast, updateActionPopup, updateMsgPopup } from '../../state/product-state.state';
import { Store } from '@ngrx/store';


export class MsgPopuopModel {
  loading : boolean = false;
  loading_msg? : string;
  loading_subtitle? : string;
  error? : string;
}

export interface MessagePopup {
  loading : boolean;
  loading_msg? : string;
  loading_subtitle? : string;
  error? : string;
}

export interface ActionPopup {
  title? : string;
  subtitle? : string;
  actions? : {
    text : string, 
    light : boolean,
    callback : Function;
  }[]
}

export class ActionPopupModel {
  title : string;
  subtitle : string;
  actions : {
    text : string, 
    light : boolean,
    callback : Function;
  }[]

  reset() {
    this.title = '';
    this.subtitle = '';
    this.actions = [];
  }
}

@Component({
  selector: 'app-msg-popup',
  templateUrl: './msg-popup.component.html',
  styleUrls: ['./msg-popup.component.scss']
})
export class MsgPopupComponent implements OnInit {
  @Input() msgModel : MsgPopuopModel;
  @Input() actionPopup : ActionPopup;
  toasts ;

  constructor(
    protected product : ProductService,
    protected store: Store<{ product : ProductState }>
  ) { }


  ngOnInit(): void {
    this.toasts = this.store.select(state => state.product.toasts);
  }

  hideToast(toast) {
    this.store.dispatch(removeToast({id: toast.id}));
  }

  applyCallback(a) {
    a.callback();
    this.store.dispatch(updateActionPopup({msg:null}));
  }

}
