<div class="modal-content">

  <button class="modal-close-btn" autofocus="false" (click)="_activeModal.dismiss()" appIsInsideModal>
    <img alt="close panel button" src="assets/icons/thick-x.svg">
  </button>

  <div class="d-flex flex-column text-center gap-16 align-items-center">

    <div class="d-flex flex-column gap-8">
      <div class="d-flex flex-row gap-8 justify-content-center align-items-center">
        <img src="assets/partners/perkbox_thumb_sq.png" style="width: 55px; border-radius: 6px;" class="img-left-slide">
        <span class="subtitle3">X</span>
        <img src="assets/paylow-thumbnail.png" style="width: 55px;" class="img-right-slide">
      </div>

      <div class="loader" *ngIf="this.stepper.equals(null)">
        <i class="fa fa-spinner fa-spin"> </i>
    </div>

      <ng-container *ngIf="this.stepper.equals(1)" @[FadeAnimation]>
        <span class="subtitle1">Perkbox integration is almost here!</span>
        <span class="body2">Enjoy perks delivered right onto your dashboard, and
          <span class="bold">instantly save</span> on your existing subscriptions! </span>
      </ng-container>

      <ng-container *ngIf="this.stepper.equals(2)" @[FadeAnimation]>
        <span class="subtitle1">You're in!</span>
        <span class="body2">We will notify you as soon as it is live.</span>
      </ng-container>

      <ng-container *ngIf="this.stepper.equals(3)">
        <span class="subtitle1">No worries!</span>
        <span class="body2">You can always change your choice if you feel like saving.</span>
      </ng-container>

    </div>

    <div class="d-flex flex-row gap-16" *ngIf="this.stepper.equals(1)">
      <button class="btn btn-primary" (click)="cta_click(true)">
        Count me in!
      </button>

      <button class="btn btn-outline-secondary" (click)="cta_click(false)">
        Maybe later
      </button>
    </div>

  </div>

</div>