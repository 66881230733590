export interface CommonBankProvider {
    providerId: string;
    displayName: string;
    country: string;
    logoUrl: string;
    transactionHistory?: {
      monthsInitialConsent?: number;
      daysInitialConsent?: number;
      description?: string; // Optional description for additional details
    };
  }
  
  export interface TLBankProvider {
    provider_id: string,
    display_name: string,
    country: string,
    logo_url: string,
    scopes: string[]
  }
  
  export interface MHBankProvider {
    id: string;
    name: string;
    friendlyName: string;
    parentRef: string;
    accountTypes: {
      name: string;
    }[];
    userTypes: string[];
    type: string;
    country: string;
    iconUrl: string;
    transactionHistory?: {
      monthsInitialConsent?: number;
      daysInitialConsent?: number;
      description?: string; // Optional field for extra details
    };
  }
  
  
  export function mapTLBankProviderToCommon(tlProvider: TLBankProvider): CommonBankProvider {
    return {
      providerId: tlProvider.provider_id,
      displayName: tlProvider.display_name,
      country: tlProvider.country,
      logoUrl: tlProvider.logo_url.replace("/logos/", "/icons/")
    };
  }
  
  export function mapMHBankProviderToCommon(mhProvider: MHBankProvider): CommonBankProvider {
    return {
      providerId: mhProvider.id,
      displayName: mhProvider.friendlyName,
      country: mhProvider.country,
      logoUrl: mhProvider.iconUrl,
      transactionHistory: {
        monthsInitialConsent: mhProvider.transactionHistory?.monthsInitialConsent,
        daysInitialConsent: mhProvider.transactionHistory?.daysInitialConsent,
      }
    };
  }
   
  