import { Action, Store, createAction, createFeatureSelector, createSelector, props, select } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merge, of } from 'rxjs';
import { map, mergeMap, catchError, withLatestFrom, tap, filter, concatMap, delay, take, first, finalize, switchMap } from 'rxjs/operators';
import { ProductApiService, addMinimumWaitTime } from 'src/app/services/product-api/product-api.service';
import { BroadbandDealDetails, BroadbandSwitchObject, MobileAdvancedSearchModel, MobileCoverage, MobileDeal, MobileFilterModel, MobileSwitchObject, UserAttribute } from 'src/app/types/beta-optimize-models.model';
import { ProductService } from 'src/app/services/product-service/product-service.service';
import { Draft, produce } from 'immer';
import { state } from '@angular/animations';
import { attributeUpdated } from '../switch-panel-broadband/state';
import { UserAttributesState, setAttributes, setNewAttributes } from '../../../common/modals/personalization-modal/state';
import { PersonalizationModel, Provider } from 'src/app/types';
import { ProductState, addGeneralErrorToast, addToast, setIsJourneyCompleted } from '../../../state/product-state.state';
import { reset } from '@amplitude/analytics-browser';


export interface MobileSwitchState {
    view_mode: 'standalone' | 'bundle';
    api_calls: string[],
    sub_step: string;
    step: string;
    current_sub_id: string | null;
    models: Record<string, MobileSwitchObject>;
    coverage_data: MobileCoverage;
    handset_brands: Provider[];
    advanced_search: MobileAdvancedSearchModel;
    is_advanced_search: boolean
    current_filters: MobileFilterModel
    page_num: number
    is_loading_more: boolean
    show_load_more: boolean
    show_advanced_search_modal: boolean
    sort_by_name: string

}

// Initial state
const initialState: MobileSwitchState = {
    view_mode: 'standalone',
    api_calls: [],
    sub_step: '',
    step: '',
    current_sub_id: null,
    models: {},
    coverage_data: null,
    handset_brands: [],
    is_advanced_search: false,
    advanced_search: {
        filter_model : {
            filters: {
            contract_lengths_new: null,
            upfront_prices: null,
            effective_line_rentals: null,
            brands: null,
            families: null,
            networks: null,
            data: null,
            colours: null,
            }
        },
        deals: null,
    },
    current_filters : {
        filters: {
            contract_lengths_new: null,
            upfront_prices: null,
            effective_line_rentals: null,
            brands: null,
            families: null,
            networks: null,
            data: null,
            colours: null,
        }
    },
    page_num: 1,
    is_loading_more: false,
    show_load_more: false,
    show_advanced_search_modal: false,
    sort_by_name: "Sort: Featured Deals"

};
export const selectMobileState = createFeatureSelector<MobileSwitchState>('mobile_switch');

export const selectSubscriptionById = createSelector(
    selectMobileState,
    (state: MobileSwitchState, props: { id: string }) => state.models[props.id]
);




// Define actions
export const addSubscription = createAction('[Mobile] addSubscription', props<{ subscription_id: string; model: MobileSwitchObject }>());
export const updateModel = createAction('[Mobile] Update model', props<{ name: string, model: MobileSwitchObject }>());
export const removeSubscription = createAction('[Mobile] Remove model', props<{ subscription_id: string }>());

export const setActiveSubscriptionId = createAction('[Mobile] setActiveSubscriptionId', props<{ subscription_id: string }>());
export const setInactive = createAction('[Mobile] setInactive', props<{ subscription_id: string }>());
export const setViewMode = createAction('[Mobile] Set View Mode', props<{ view_mode: 'standalone' | 'bundle' }>());

export const addApiCall = createAction('[Mobile] Add hangning', props<{ name: string }>());
export const removeApiCall = createAction('[Mobile] Remove hangning', props<{ name: string }>());

export const updateStep = createAction('[Mobile] updateStep', props<{ step: string }>());
export const dealSelect = createAction('[Mobile] dealSelect', props<{ deal_id: string | number }>());

export const stepNext = createAction('[Mobile] stepNext');
export const stepBack = createAction('[Mobile] stepBack');

export const fetchDeals = createAction('[Mobile] fetchDeals', props<{ caller: string }>());
export const fetchBrands = createAction('[Mobile] fetchBrands');
export const setBrands = createAction('[Mobile] setBrands', props<{ handsetBrands: Provider[] }>());

export const fetchOrder = createAction('[Mobile] postCheckout');
export const postCheckout = createAction('[Mobile] postCheckout', props<{ model: MobileSwitchObject }>());
export const updateOrderResult = createAction('[Mobile] updateOrderResult', props<{ result: string }>());
export const setHandset = createAction('[Mobile] setHandset', props<{ new_value: boolean }>());
export const setCoverageData = createAction('[Mobile] setCoverageData', props<{ coverage: MobileCoverage }>());

export const setAdvancedSearch = createAction('[Mobile] setAdvancedSearch', props<{  is_advanced_search: boolean }>()); 
export const showAdvancedSearchModal = createAction('[Mobile] showAdvancedSearchModal', props<{  show: boolean }>()); 
export const setAdvancedSearchModel = createAction('[Mobile] setAdvancedSearchModel', props<{ field?: string, advanced_search: MobileAdvancedSearchModel }>()); 
export const fetchFilterModel = createAction('[Mobile] fetchFilterModel' , props<{ is_handset?: boolean, page_num: number }>()); 
export const setNewFilter = createAction('[Mobile] setNewFilter' , props<{ field?: string, value?: any, reset_model?: boolean, sort_by?: "monthly_price" | "data" | "total_cost", reverse?: boolean, sort_by_name?: string }>()); 
export const setLoadMore = createAction('[Mobile] setLoadMore' , props<{ mode: boolean}>()); 
export const resetAdvancedSearch = createAction('[Mobile] resetAdvancedSearch' , props<{  reset_hard?: boolean }>()); 



export const mobileSwitched = createAction('[Mobile] switched');

export const noop = createAction('[Mobile] noop');

const steps = ['personalisation', 'deals', 'checkout', 'post-checkout'];
const subSteps = [
    [],
    [],
    [],
    []
];

// Create reducer
export const mobileReducer = createReducer(
    initialState,
    on(setViewMode, (state, { view_mode }) => ({
        ...state,
        view_mode: view_mode
    })),
    on(addSubscription, (state, { subscription_id, model }) => {
        if (state.models[subscription_id]) {
            return state;
        }
        return {
            ...state,
            current_sub_id: subscription_id,
            models: {
                ...state.models,
                [subscription_id]: model
            }
        }
    }),
    on(setActiveSubscriptionId, (state, { subscription_id }) => ({
        ...state,
        current_sub_id: subscription_id
    })),
    on(setInactive, (state, { subscription_id }) => {
        if (state.current_sub_id == subscription_id) {
            return {
                ...state,
                current_sub_id: null
            };
        }
        return state;
    }),
    on(updateModel, (state, { model }) => {
        if (state.current_sub_id) {
            return {
                ...state,
                models: {
                    ...state.models,
                    [state.current_sub_id]: model
                }
            };
        }
        return state;
    }),
    on(removeSubscription, (state, { subscription_id }) => {
        const { [subscription_id]: _, ...rest } = state.models;
        return {
            ...state,
            step: '',
            sub_step: '',
            models: rest
        };
    }),
    on(dealSelect, (state, { deal_id }) => {
        return produce(state, draft => {
            draft.show_advanced_search_modal = false;

            draft.models[draft.current_sub_id].selected_deal_id = deal_id;
            draft.step = 'checkout';
        })
    }),
    on(updateStep, (state, { step }) => ({
        ...state,
        step: step
    })),
    on(addApiCall, (state, { name }) => ({
        ...state,
        api_calls: state.api_calls.includes(name) ? state.api_calls : [...state.api_calls, name]
    })),
    on(removeApiCall, (state, { name }) => ({
        ...state,
        api_calls: state.api_calls.filter(n => n != name)
    })),
    on(stepNext, (state) => {
        const currentStepIndex = steps.indexOf(state.step);
        const currentSubStepIndex = subSteps[currentStepIndex].indexOf(state.sub_step);

        if (currentSubStepIndex < subSteps[currentStepIndex].length - 1) {
            return {
                ...state,
                sub_step: subSteps[currentStepIndex][currentSubStepIndex + 1]
            };
        } else if (currentStepIndex < steps.length - 1) {
            return {
                ...state,
                step: steps[currentStepIndex + 1],
                sub_step: subSteps[currentStepIndex + 1][0] || ''
            };
        }
        return state;
    }),
    on(stepBack, (state) => {
        const currentStepIndex = steps.indexOf(state.step);
        const currentSubStepIndex = subSteps[currentStepIndex].indexOf(state.sub_step);

        if (currentSubStepIndex > 0) {
            return {
                ...state,
                sub_step: subSteps[currentStepIndex][currentSubStepIndex - 1],
                // show_advanced_search_modal: state.is_advanced_search && state.step == 'checkout' ? true : false
            };
        } else if (currentStepIndex > 0) {
            const prevStepIndex = currentStepIndex - 1;
            return {
                ...state,
                step: steps[prevStepIndex],
                sub_step: subSteps[prevStepIndex][subSteps[prevStepIndex].length - 1] || '',
                // show_advanced_search_modal: state.is_advanced_search && state.step == 'checkout' ? true : false

            };
        }
        return state;
    }),

    on(updateOrderResult, (state, { result }) => {
        return produce(state, draft => {
            draft.models[draft.current_sub_id].order_result = result;
        })
    }),
    on(setHandset, (state, { new_value }) => {
        return produce(state, draft => {
            draft.models[draft.current_sub_id].is_handset = new_value == null ? !draft.models[draft.current_sub_id].is_handset : new_value;
            draft.models[draft.current_sub_id].deals = null;
            draft.handset_brands = null;

        })
    }),
    on(setCoverageData, (state, { coverage }) => ({
        ...state,
        coverage_data: coverage
    })
    ),
    on(setBrands, (state, { handsetBrands }) => {
        return {
            ...state,
            handset_brands: handsetBrands

        };

    }),
    on(setAdvancedSearch, (state, { is_advanced_search }) => {
        return produce(state, draft => {
            draft.is_advanced_search = is_advanced_search;
            const is_handset = draft.models[draft.current_sub_id].is_handset ? 'handset' : 'simOnly';
            const cachedFilters = localStorage.getItem('paylow.advanced.search.' + draft.current_sub_id + '.' + is_handset);
            if (cachedFilters) {
                draft.current_filters = JSON.parse(cachedFilters);
            }
        })
    }),
    on(setAdvancedSearchModel, (state, { field, advanced_search }) => {
        return produce(state, draft => {
            draft.show_load_more = true;
            if (advanced_search?.deals) {
                draft.models[draft.current_sub_id].deals = advanced_search.deals;
                draft.advanced_search.filter_model = advanced_search.filter_model;

                draft.step = 'deals';
            }


        })
    }),
    on(setNewFilter, (state, { field, value, sort_by, reverse, sort_by_name }) => {
        return produce(state, draft => {
          if (field) {
            draft.current_filters.filters[field] = value;
          }
          if (sort_by || sort_by_name) {
            draft.current_filters.sort_by = sort_by;
            draft.sort_by_name = sort_by_name;
            draft.current_filters.reverse = reverse;
          }
        });
      }),
      
      on(fetchFilterModel, (state, { page_num }) => {
        return produce(state, draft => {
          if (page_num) {
            draft.page_num = page_num;

          }
        });
      }),
      on(setLoadMore, (state, { mode }) => { // mode: true/false to show/hide loading spinner
        return produce(state, draft => {
            draft.is_loading_more = mode;
        });
      }),

      on(resetAdvancedSearch, (state, { reset_hard }) => {
        return produce(state, draft => {
            if (reset_hard) {
            draft.show_advanced_search_modal = false;
            }
            draft.show_load_more = false;
            draft.current_filters = initialState.current_filters;
        });
      }),
      on(showAdvancedSearchModal, (state, { show }) => { 
        return produce(state, draft => {
            draft.show_advanced_search_modal = show;
        });
    }),
      
);


function initialStepSelection(state: MobileSwitchState, personalizationAttributes: PersonalizationModel) {
    if (state.models[state.current_sub_id].is_handset) {
        if (personalizationAttributes?.handset_brand_preference.value.length > 0) {
            if (state.models[state.current_sub_id].deals != null) {
                return [updateStep({ step: 'deals' })];
            } else {
                return [fetchDeals({ 'caller': 'initialStateStep' })];
            }
        } else {
            return [fetchBrands()];
        }
    } else {
        if (state.models[state.current_sub_id].deals != null) {
            return [updateStep({ step: 'deals' })];
        } else {
            return [fetchDeals({ 'caller': 'initialStateStep' })];
        }
    }
}

export const selectMobileDealById = createSelector(

    selectSubscriptionById,
    (subscription: MobileSwitchObject | undefined): MobileDeal | null => {
        if (!subscription || !subscription.deals) {
            return null;
        }

        return subscription.deals.find(deal => deal.id === subscription.selected_deal_id) || null;
    }
);

@Injectable()
export class MobileSwitchEffects {
    constructor(
        private actions$: Actions,
        private api: ProductApiService,
        private product: ProductService,
        private store: Store<{ mobile_switch: MobileSwitchState }>,
        private attribute_store: Store<{ user_attributes: UserAttributesState }>,
        protected store_product : Store<{product:ProductState}>,

    ) { }

    lastestHandsetValue = null;
    latestPostcode = null;

    initialStateStep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setActiveSubscriptionId),
            withLatestFrom(
                this.store.select(state => state.mobile_switch),
                this.attribute_store.select(state => state.user_attributes.user_attributes)
            ),
            filter(([action, state, personalizationAttributes]) => state.view_mode == 'standalone' || state.step == ''),
            // filter(([action, state, personalizationAttributes]) => state.models[action.subscription_id].deals == null),
            filter(([action, state, personalizationAttributes]) => { return state.current_sub_id != null; }),
            concatMap(([action, state, personalizationAttributes]) => initialStepSelection(state, personalizationAttributes)),
            catchError((e) => {
                console.log(e);
                return of(addGeneralErrorToast())
            }) // Handle errors appropriately
        )
    );

    handsetToggle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setHandset),
            withLatestFrom(
                this.store.select(state => state.mobile_switch),
                this.attribute_store.select(state => state.user_attributes.user_attributes)
            ),
            filter(([action, state, personalizationAttributes]) => {
                return state.current_sub_id != null;
            }),
            concatMap(([action, state, personalizationAttributes]) => initialStepSelection(state, personalizationAttributes)),
            catchError(() => of(updateStep({ step: 'error' }))) // Handle errors appropriately
        )
    );

    getCoverage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchDeals, setAttributes, setNewAttributes),
            // Attribute is relevant
            filter((action: any) => {
                return action.type === fetchDeals.type ||
                    action.type === setAttributes.type ||
                    (action.type === setNewAttributes.type && action.newAttributes.find(a => a.key === 'postcode'));
            }),
            withLatestFrom(
                this.store.select(state => state.mobile_switch),
                this.attribute_store.select(state => state.user_attributes.user_attributes)
            ),
            // Mobile opt is active
            filter(([action, state, personalizationAttributes]) => {
                return state.current_sub_id != null;
            }),
            // Postcode indeed changed since last fetch
            filter(([action, state, personalizationAttributes]) => {
                const postcode = personalizationAttributes?.postcode?.value;
                return postcode && postcode !== this.latestPostcode;
            }),
            tap(([action, state, personalizationAttributes]) => {
                this.store.dispatch(addApiCall({ name: '_coverage' }));
            }),
            mergeMap(([action, state, personalizationAttributes]) => {
                this.latestPostcode = personalizationAttributes?.postcode?.value;
                return this.api.getMobileCoverage().pipe(
                    mergeMap(response => [
                        setCoverageData({ coverage: response }),
                        removeApiCall({ name: '_coverage' })
                    ]),
                    catchError(() => of(addToast({ title: 'Ops..', text: 'We couldn\'t find coverage data at your postcode.', toast_type: 'general' })))
                );
            })
        )
    );

    mobileAttributeUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setNewAttributes),
            withLatestFrom(this.store.pipe(select(state => state.mobile_switch))),
            mergeMap(([action, state]) => {
                if (state && state.current_sub_id &&
                    (action.newAttributes.find(a => a.key.includes('mob')) || (state.models[state.current_sub_id].is_handset && action.newAttributes.find(a => a.key.includes('handset')))

                    )) {
                    return of(
                        fetchDeals({ 'caller': 'mobileAttributeUpdate' }),
                        addApiCall({ name: 'deals' })
                    );
                }
                return of(noop());
            })
        )
    );

    getDealsStep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchDeals),
            withLatestFrom(this.store.select(state => state.mobile_switch)),
            tap(([action, state]) => {
                this.store.dispatch(addApiCall({ name: 'deals' }));
            }),
            mergeMap(([action, state]) =>
                addMinimumWaitTime(this.api.optimizeMobile({
                    subscription_id: state.current_sub_id,
                    is_handset: state.models[state.current_sub_id].is_handset,
                }), 9000, 'paylow.switch.mobile.lastCall').pipe(
                    concatMap(ret => [
                        updateModel({ name: action.type, model: ret }),
                        removeApiCall({ name: 'deals' }),
                        updateStep({ step: 'deals' }),
                    ]),
                    catchError(() => of(updateStep({ step: 'error' }))),
                    finalize(() => {
                        this.store.dispatch(removeApiCall({ name: 'deals' }));
                    })
                )
            )
        )
    );

    fetchBrands$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchBrands),
            withLatestFrom(this.store.select(state => state.mobile_switch.handset_brands)),
            filter(([action, state]) => !state || !state.length),
            tap(a => this.store.dispatch(addApiCall({ name: 'fetchingBrands' }))),
            concatMap((action) =>
                this.api.getMobileBrands().pipe(
                    concatMap(brands => [
                        setBrands({ handsetBrands: brands }),
                        updateStep({ step: 'brands_select' }),
                    ]),
                    catchError(() => of(updateStep({ step: 'error' }))),
                    finalize(() => {
                        this.store.dispatch(removeApiCall({ name: 'fetchingBrands' }));
                    })
                )
            )
        )
    );

    fetchFilterModel$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchFilterModel),
            withLatestFrom(this.store.select(state => state.mobile_switch)),
            filter(([action, state]) => !state.advanced_search || !state.advanced_search.deals),
            tap(([action, state]) => {
                if (!state.is_loading_more) {
                  this.store.dispatch(addApiCall({ name: 'fetchingAdvancedSearchDeals' }));
                }
              }),
            tap(([action, state]) => {
                const is_handset = state.models[state.current_sub_id].is_handset ? 'handset' : 'simOnly';
                localStorage.setItem('paylow.advanced.search.' + state.current_sub_id + '.' + is_handset, JSON.stringify(state.current_filters));
            }),
            mergeMap(([action, state]) =>
                this.api.optimizeMobileAdvancedSearch(state.current_filters, state.current_sub_id, action.is_handset?.toString(), action.page_num?.toString()).pipe(
                    concatMap(filterModel => [
                        setAdvancedSearchModel({ advanced_search: filterModel }),
                    ]),
                    catchError(() => of(updateStep({ step: 'error' }))),
                    finalize(() => {
                        this.store.dispatch(removeApiCall({ name: 'fetchingAdvancedSearchDeals' }));
                        this.store.dispatch(setLoadMore({mode: false}))
                    })
                )
            )
        )
    );


    fetchOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchOrder),
            withLatestFrom(this.store.select(state => state.mobile_switch)),
            tap(([action, state]) => this.store.dispatch(addApiCall({ name: 'postCheckout' }))),
            concatMap(([action,state]) =>
                this.api.optimizeMobileWriteOrder(state.models[state.current_sub_id]).pipe(
                    concatMap(brands => [
                        updateOrderResult({ result: 'success' }),
                        updateStep({ step: 'post-checkout' }),
                    ]),
                    catchError(() => of(updateStep({ step: 'error' }))),
                    finalize(() => {
                        this.store.dispatch(addApiCall({ name: 'postCheckout' }));
                        this.store_product.dispatch(setIsJourneyCompleted({completed: true}))
                    })
                )
            )
        )
    );

    postCheckoutRefresh$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateOrderResult),
            withLatestFrom(this.store.select(state => state.mobile_switch)),
            mergeMap(([action, state]) =>
                this.product.refreshCurrentSubscription(false).pipe(
                    map(() => noop()),
                    catchError(() => of(noop())) // Handle errors appropriately
                )
            )
        )
    );


}
