import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, createReducer, on, props, Store } from '@ngrx/store';
import { filter, map, tap, withLatestFrom } from 'rxjs';
import { AppConfig } from 'src/app/services/app_config/app-config.service';
import produce from 'immer';

export const updateAppConfig = createAction('[Session] updateAppConfig', props<{ appConfig : AppConfig }>());
export const updateModuleInitialized = createAction('[Session] updateModuleInitialized', props<{ module : string }>());
export const announceSessionIntialized = createAction('[Session] announceSessionIntialized');

export interface SessionState {
    appConfig : AppConfig;
    modulesInitialized : {
        auth : boolean;
    }
}


export const initialState: SessionState = {
    appConfig : null,
    modulesInitialized : {
        auth : false,
    }
};


export const SessionReducer = createReducer(
  initialState,
  on(updateAppConfig, (state, { appConfig }) => produce(state, draft => {
    draft.appConfig = appConfig;
  })),
  on(updateModuleInitialized, (state, { module }) => produce(state, draft => {
    if (module === 'auth') {
      draft.modulesInitialized.auth = true;
    }
  })),
);

@Injectable()
export class SessionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<{ session: SessionState }>
  ) { }

  onModuleInitialized$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateModuleInitialized),
      withLatestFrom(this.store.select(state => state.session)),
      tap(([action, state]) => console.log('Session State:', state)),
      filter(([action, state]) => state.modulesInitialized.auth),
      map(() => announceSessionIntialized())
    )
  );
}

