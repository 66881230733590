import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductApiService } from 'src/app/services/product-api/product-api.service';
import { ProductService } from 'src/app/services/product-service/product-service.service';
import { ContractLockRequest } from 'src/app/types';
import broadbandContractLockGuide from '../../switch-panel-broadband/broadband.contract-lock-data';
import { ContractLockDetails } from 'src/app/types/beta-optimize-models.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/services/modal-service/modal-service.service';
import { UserAttributesState } from 'src/app/components/product/common/modals/personalization-modal/state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-contract-lock',
  templateUrl: './contract-lock.component.html',
  styleUrls: ['./contract-lock.component.scss']
})
export class ContractLockComponent implements OnInit {
  @Output('done') done = new EventEmitter<ContractLockDetails>();

  contractEndForm: FormGroup;
  all_months: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  months: string[];
  years: number[] = [];
  hanging = false;

  @Input() lock_model: ContractLockDetails;
  @Input() templateType: string;
  @Input() state: string = 'input';
  templateData: any;

  constructor(
    private fb: FormBuilder,
    public product: ProductService,
    protected modalService : ModalService,
    private attribute_store: Store<{ user_attributes: UserAttributesState }>,
    private _product_api: ProductApiService
  ) { }

  ngOnInit() {
    this._initForm();
    if (this.lock_model?.date) {
      this.setContractEndDate(this.lock_model.date);
    }

    this.templateData = this.getTemplateData();
  }

  _initForm() {
    const currentYear = new Date().getFullYear();
    for (let i = 0; i <= 3; i++) {
      this.years.push(currentYear + i);
    }

    this.contractEndForm = this.fb.group({
      month: ['', Validators.required],
      year: ['', Validators.required],
      notUnderContract: [false] // new form control for the checkbox
    });

    this.contractEndForm.get('notUnderContract').valueChanges.subscribe((checked) => {
      if (checked) {
        this.contractEndForm.get('month').disable();
        this.contractEndForm.get('year').disable();
      } else {
        this.contractEndForm.get('month').enable();
        this.contractEndForm.get('year').enable();
      }
    });

    this.contractEndForm.get('year').valueChanges.subscribe((year) => {
      const currentMonth = new Date().getMonth() + 1;
      if (year == currentYear) {
        this.months = this.all_months.slice(currentMonth - 1);
      } else {
        this.months = this.all_months;
      }
    });
  }

  onSubmit() {
    if (!this.contractEndForm.get('notUnderContract').value && !this.contractEndForm.valid) {
      return;
    }
    var date = "";

    if (this.contractEndForm.get('notUnderContract').value) {
      date = "not_under_contract";
    } else {
      const monthName = this.contractEndForm.get('month').value;
      const monthNumber = new Date(Date.parse(monthName + ' 1, 2000')).getMonth() + 1;
      const year = this.contractEndForm.get('year').value;
      date = `${year}-${monthNumber}-01`;
    }

    const sub = this.product.getSelectedSubscriptionValue();
    const lock = {
      subscription_id: sub.id,
      date: date,
      is_locked: null
    } as ContractLockDetails;

    this.hanging = true
    
    this.product.contractLock(lock).subscribe((result) => {
      this.hanging = false;
      const currect_lock = result.value?.find(c => c.subscription_id == sub.id) || result;
      this.done.emit(currect_lock);
      this.modalService.getModalRef()?.close(currect_lock);
    });

  }

  setContractEndDate(date: string) {
    if (date === 'not_under_contract') {
      this.contractEndForm.get('notUnderContract').setValue(true);
    } else {
      const parsedDate = new Date(date);
      const month = this.all_months[parsedDate.getMonth()];
      const year = parsedDate.getFullYear();
      this.contractEndForm.get('notUnderContract').setValue(false);
      this.contractEndForm.get('month').setValue(month);
      this.contractEndForm.get('year').setValue(year);
    }
  }

  public getTemplateData() {
    const provider_id = this.product.getSelectedSubscriptionValue().activePlan.id;
    return broadbandContractLockGuide.find(x => x.provider === provider_id);
  }

  public getNumberLockedMonths() { 
    return ContractLockDetails.getNumberLockedMonths(this.lock_model);
  }
}
