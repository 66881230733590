import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PersonalizationModel } from 'src/app/types/beta-dashboard.model';
import { ProductApiService } from 'src/app/services/product-api/product-api.service';
import { ProductService } from 'src/app/services/product-service/product-service.service';
import { delay, filter, map, take } from 'rxjs/operators';
import { BroadbandDeal, BroadbandOrder, BroadbandPaymentDetails, BroadbandSwitchObject, ContractLockDetails, DealsInteractiveFilter, GeneralAddress } from 'src/app/types/beta-optimize-models.model';
import { BroadbandService } from 'src/app/services/optimizers/broadband/broadband.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-personalization-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {
  public postcodeForm: FormGroup;
  public _formNameFocused: boolean = false;
  public map_url: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
  public model: PersonalizationModel;
  public map_loading = true;

  public addresses: GeneralAddress[];
  public hanging = this._product.getPersonalizationAttributeHangning('postcode');
  public is_hanging = false


  constructor(
    protected _router: Router,
    private _activatedRoute: ActivatedRoute,
    public _activeModal: NgbActiveModal,
    public _api : ProductApiService,
    public _product : ProductService,
    private sanitizer: DomSanitizer,
    protected product_api : ProductApiService,
    protected broadband_service : BroadbandService,
  ) { }

  ngOnInit(): void {
    this.postcodeForm = new FormGroup({
      postcode: new FormControl(null, [Validators.required, Validators.pattern('^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$')]),
      address: new FormControl(null, [Validators.required]), 
    });

    this._product.getPersonalizatioAttributes().pipe(take(1)).subscribe(res => {
      this.model = res;
      this.postcodeForm?.get('postcode').setValue(this.model?.postcode.value);
      this.onPostcodeEnter(null);
    });


    this.postcodeForm?.get('address').valueChanges.subscribe( v => {
      // this.hanging = '';
    });

  }

  public get postcode(): FormControl {
    return this.postcodeForm?.get('postcode') as FormControl;
  }

  public get address(): FormControl {
    return this.postcodeForm?.get('address') as FormControl;
  }

  submitPostcodeForm() {
    if (this.address.value == this.model?.address_id.value && this.postcode.value == this.model?.postcode.value) {
      return;
    }

    const post = this.postcode.value.replace(/\s/g, '');

    this._product.updatePersonalizationAttributes({attributes : [
      { key: 'postcode', value: post, source: 'user' },
      { key: 'address_id', value: this.address.value, source: 'user' }
    ]});
  }

  public onPostcodeEnter(event: any) {
    this.is_hanging = true
    if (!this.postcode) {
      this.is_hanging = true
      return;
    }
    if (this.postcode?.invalid) {
      this.is_hanging = false
      return;
    }


    this.map_url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.google.com/maps/embed/v1/place?q=${this.postcode?.value}&key=AIzaSyCtUKugHwOlFXA-Pu8FC9a2efCiXdGykoU`);
    // this.map_loading = ;
    this.addresses = [];

    this.product_api.getAddresses(this.postcode.value, 'broadband').subscribe( res=> {
      this.addresses = res;
      this.is_hanging = false

      const addressValue = this.model?.address_id?.value || null;
      if (this.postcodeForm.get('address').value == null) {
        this.postcodeForm.get('address').setValue(addressValue);        
      }
    });
  }

  showMap() {
    this.map_loading = false;
  }
}
