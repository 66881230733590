import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, take } from 'rxjs';
import { ProductService } from '../product-service/product-service.service';
import { BaseConsentService } from '../consent/base-consent.service';
import { CONSENT_SERVICE_TOKEN } from '../consent/consent-token.di';

@Injectable()
export class BaseRouterService {

  constructor(
    protected _router: Router,
    protected _product: ProductService,
    @Inject(CONSENT_SERVICE_TOKEN) public _consentService: BaseConsentService,
  ) { }

  public welcomePage(_activatedRoute: ActivatedRoute) {
    this._router.navigate(['../'], { relativeTo: _activatedRoute });
  }

  public signIn(_activatedRoute: ActivatedRoute) {
    _activatedRoute.url.pipe(take(1)).subscribe(r => {
      const back = r.length > 0;
      this._router.navigate(back ? ['../', 'login'] : ['login'], { relativeTo: _activatedRoute, queryParams: { step: null }, queryParamsHandling: 'merge' })
    }
    )
  }

  public restrictedAccess(_activatedRoute: ActivatedRoute) {
    this._product.msgModel.loading = false;
    this._product.msgModel.loading_msg = "";
    this._router.navigate(['../', 'login'], { relativeTo: _activatedRoute, queryParams: { 'step': 'restricted' }, queryParamsHandling: 'merge' })
  }

  public consentPage(_activatedRoute: ActivatedRoute) {
    this._router.navigate(['../', 'consent'], { relativeTo: _activatedRoute, queryParams: { step: null }, queryParamsHandling: 'merge' });
  }

  public consentFetch(_activatedRoute: ActivatedRoute) {
    this._router.navigate(['../', 'consent'], { relativeTo: _activatedRoute, queryParams: { step: 'fetch' }, queryParamsHandling: 'merge' });
  }

  public goToDashboard(_activatedRoute: ActivatedRoute) {
    this._router.navigate(['../', 'dashboard'], { relativeTo: _activatedRoute, queryParams: { step: null }, queryParamsHandling: 'merge' })
  }

  public postSignIn(_activatedRoute: ActivatedRoute) {
    this._product.msgModel.loading_msg = "Welcome!";
    this._product.msgModel.loading = true;

    this._consentService.getOfflineConsentCompletePipe().pipe(take(1)).subscribe(
      r => {
        if (r) {
          this._product.msgModel.loading = false;
          this.goToDashboard(_activatedRoute);
        } else {
          this._product.isDashboardExists().subscribe({
            next: dashboardExists => {
              this._product.msgModel.loading = false;
              if (dashboardExists) {
                this.goToDashboard(_activatedRoute);
              } else {
                this.consentPage(_activatedRoute);
              }
            },
            error: error => {
              this._product.msgModel.loading = false;
              this._product.msgModel.loading_msg = "";
            }
          });
        }
      },
    );
  }
}
