import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { SignUpParams } from '@aws-amplify/auth/src/types/Auth';
import { ICredentials } from '@aws-amplify/core';
import { Store } from '@ngrx/store';
import { CognitoUser, ISignUpResult } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { logout } from 'src/app/app-actions';
import { AppConfigService } from "../app_config/app-config.service";
import { BaseAuthService } from "./base-auth.service";
import { TokenRedeemService } from '../redeem-token.service';

@Injectable()
export class ProductAuthenticationService extends BaseAuthService {
    protected cognitoUser: CognitoUser;

    constructor(
        public override appConfig: AppConfigService,
        public override store: Store,
        public override _router: Router,
    ) {
        super(appConfig, store, _router);
    }

    override initiateLoginFlow(login_args: any): Promise<boolean> {
        return this.OTPSignInDefine(login_args.username);
    }

    private generatePassword() {
        var generatePassword = (length = 20, wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-?#$') => Array.from(crypto.getRandomValues(new Uint32Array(length)))
            .map((x) => wishlist[x % wishlist.length])
            .join('')
        var pass = generatePassword();
        const pass_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        while (!pass_regex.test(pass)) {
            pass = generatePassword();
        }
        return pass;
    }

    public async OTPSignUpIfNeeded(username: string): Promise<boolean> {
        let pass = this.generatePassword();
        return await this.signUp({ username: username, password: pass })
            .then(res => { console.log('signedup'); return true; })
            .catch(error => {
                const code = error.code;
                console.log(error.code);

                switch (code) {
                    case 'UsernameExistsException':
                        return true;
                    case 'UserLambdaValidationException':
                        // this._error_signup_user = username;
                        throw error;
                    default:
                        return false;
                }
            });
    }

    public async OTPSignInDefine(username: string): Promise<any> {
        const signup_res = await this.OTPSignUpIfNeeded(username);

        return Auth.signIn(username).then(
            res => { return res; }
        ).catch(
            res => {
                console.log(res);
                throw res;
            }
        )
    }

    public OTPSignInVerify(username: string, code: string): Promise<any> {
        return Auth.sendCustomChallengeAnswer(username, code).then(
            res => {
                if (res.signInUserSession == null) {
                    throw { code: "WrongCode" };
                }
                else {
                    console.log('SUBMIT CHALLENGE PASSED', res);
                    return true;
                }
            }).catch(e => {
                switch (e.code) {
                    case 'UserLambdaValidationException':
                        throw { code: 'AuthSessionFailed' };
                    default:
                        console.log(e);
                        throw e;
                }
            })
    }

    public googleSocialSignIn(): Promise<ICredentials> {
        return Auth.federatedSignIn({
            'provider': CognitoHostedUIIdentityProvider.Google,
        });
    }

    public signUp(params: SignUpParams): Promise<ISignUpResult> {
        const atts = {};

        const redeemToken = localStorage.getItem('redeem_token');
        if (redeemToken !== null) {
            atts['custom:redeem_token'] = redeemToken;
            localStorage.removeItem('redeem_token');
            console.log(atts);
        }

        return Auth.signUp({
            username: params.username,
            password: params.password,
            attributes: atts
        }).catch(() => {
            return Promise.resolve(null);
        });
    }

}