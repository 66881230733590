<div class=" gap-16 h-100">
  <button class="modal-close-btn" (click)="_activeModal.dismiss()" id="close-modal-btn">
    <img alt="close panel button" src="assets/icons/thick-x.svg">
  </button>
  <span class="subtitle2-md-heading2 w-100" *ngIf="this.stepper.stepWithTransition == 'submit'">{{this.subscription_id ? 'Edit subscription' : ' Add subscription'}}</span>
  
  <div  [@FadeAnimation]>
    <form [formGroup]="addSubscrptionForm" (ngSubmit)="addSubscription()" *ngIf="this.stepper.stepWithTransition == 'submit'">
      <div class="d-flex flex-column flex-grow-1 gap-16">
        <div>
          <label for="category" class="info-item-label mb-1 paylow-dark-purple-text">Category</label>
          <div class="input-group">
            <select 
              id="category" 
              formControlName="category" 
              class="form-select editable-onclick" 
              autocomplete="off">
              
              <option value="" disabled>Select category</option>
              <option 
                *ngFor="let option of options" 
                [value]="option"
                [selected]="option === manual_category">{{ option }}
              </option>
            </select>
          </div>
          <div *ngIf="addSubscrptionForm.get('category')?.invalid && addSubscrptionForm.get('category')?.touched" class="text-danger">
            Category is required.
          </div>
        </div>
        <div>
          <div class="d-flex gap-8">
            <label for="provider" class="info-item-label mb-1 paylow-dark-purple-text">Provider Name</label>
            <div *ngIf="(hanger$ | async).includes('fetchingProviders')" class="spinner-container">
              <i class="fa fa-spinner fa-spin spinner-icon"></i> 
            </div>
          </div>
  
          <div class="input-group" *ngIf="addSubscrptionForm.get('category')?.value === 'Mobile' || 
          addSubscrptionForm.get('category')?.value === 'Broadband' || 
          addSubscrptionForm.get('category')?.value === 'Energy' || addSubscrptionForm.get('category')?.value === 'Telecom (bundle)'">

          <select id="provider" formControlName="provider" class="form-select editable-onclick" [disabled]="(hanger$ | async).includes('fetchingProviders')">
          <option value="" disabled>Select provider</option>
          <option *ngFor="let provider of providerOptions" [value]="provider.display_name" [selected]="provider.display_name === manual_name">
      {{ provider.display_name }}
    </option>
          </select>
          </div>

  
          <div class="input-group" *ngIf="addSubscrptionForm.get('category')?.value !== 'Mobile' && 
                                          addSubscrptionForm.get('category')?.value !== 'Broadband' && 
                                          addSubscrptionForm.get('category')?.value !== 'Energy' && addSubscrptionForm.get('category')?.value !== 'Telecom (bundle)'">
            <input 
                   formControlName="provider"
                   type="text"
                   placeholder="Enter Provider Name"
                   class="form-control editable-onclick"
                   id="custom-provider-name"
                   autocomplete="off">
          </div>
          <div *ngIf="(addSubscrptionForm.get('provider')?.invalid && addSubscrptionForm.get('provider')?.touched) || 
                      (addSubscrptionForm.get('provider')?.value === null && addSubscrptionForm.get('provider')?.touched)"
               class="text-danger">
          </div>
        </div>
        <div>
          <label for="amount" class="info-item-label mb-1 paylow-dark-purple-text">Amount</label>
          <div class="input-group w-50">
            <input id="amount" formControlName="amount" type="number" placeholder="Enter Amount" class="form-control editable-onclick" autocomplete="off" min="1">

          </div>
          <div *ngIf="addSubscrptionForm.get('amount')?.invalid && addSubscrptionForm.get('amount')?.touched" class="text-danger">
            <span *ngIf="addSubscrptionForm.get('amount')?.errors['required']">Amount is required.</span>
          </div>
        </div>
      </div>
      <div class="d-flex w-100 justify-content-between gap-8 pt-4">


        <button class="btn btn-primary delete-button" id="delete-subscription-btn" type="button" *ngIf="this.subscription_id" (click)="cancelSubscription()">
          <i class="fa fa-spinner fa-spin spinner-icon" *ngIf="(hanger$ | async).includes('updateCustomization/' + subscription_id)"></i>
          <span  *ngIf="!(hanger$ | async).includes('updateCustomization/' + subscription_id)">Delete</span> </button>

        <button type="submit" class="btn btn-primary add-button" id="add-subscription-btn" [disabled]="addSubscrptionForm.invalid">
          <i class="fa fa-spinner fa-spin spinner-icon" *ngIf="(hanger$ | async).includes('updateCustomizationSubs')"></i>
          <span *ngIf="!(hanger$ | async).includes('updateCustomizationSubs')">{{this.subscription_id ? 'Edit' : 'Add'}}</span>
        </button>

      </div>
    </form>
  </div>


  <div class="d-flex flex-column align-items-center gap-16 p-16" *ngIf="this.stepper.equals('post-submit')" [@FadeAnimation]>
    <div class="d-flex flex-column align-items-center text-center">
        <i class="bi bi-check-circle icon-badge " ></i>
        <span class="subtitle1 paylow-dark-purple-text">{{this.subscription_id ? 'Your subscription has been edited.' : 'Your subscription has been added.'}}</span>
    </div>
</div>

<div class="d-flex flex-column align-items-center gap-16 p-16" *ngIf="this.stepper.equals('post-delete')" [@FadeAnimation]>
  <div class="d-flex flex-column align-items-center text-center">
      <i class="bi bi-check-circle icon-badge " ></i>
      <span class="subtitle1 paylow-dark-purple-text">Subscription has been deleted.</span>
  </div>
</div>
  
</div>
