<ng-container *ngIf="this.state == 'input'">
    <form [formGroup]="contractEndForm" class="w-100" (ngSubmit)="onSubmit()" novalidate>
        <div class="d-flex flex-column gap-16 flex-grow-1 justify-content-between h-100">

            <div class="d-flex flex-column gap-8">
                <span class="subtitle3-md-subtitle1">When does your current contract end?</span>
                <span class="body2-md-subtitle3">This helps us find the best time for you to switch and save</span>
            </div>

            <div class="d-flex flex-column gap-24">

                <div class="d-flex flex-column gap-16">
                    <div class="d-flex justify-content-center">
                        <div class="d-flex flex-column gap-8 custom-div">
                            <div class="d-flex flex-row gap-8">

                                <select class="form-select" id="year" formControlName="year" required tabindex="-1"
                                    style="height: 64px; width: 185px;" id="contract-lock-year">
                                    <option value="" disabled>Select Year</option>
                                    <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                                </select>

                                <select class="form-select input-select" id="month" formControlName="month" required tabindex="-1"
                                    style="height: 64px; width: 185px;" id="contract-lock-month">
                                    <option value="" disabled>Select Month</option>
                                    <option *ngFor="let month of months" [value]="month">{{ month }}</option>
                                </select>

                            </div>

                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="notUnderContract"
                                    formControlName="notUnderContract">
                                <label class="form-check-label" for="notUnderContract">Currently not under a contract</label>
                            </div>
                        </div>
                    </div>


                </div>


            </div>

            <div class="d-flex justify-content-center flex-column w-100 align-items-center gap-8">

                <div class="d-flex flex-row gap-8 clickable" *ngIf="templateType == 'mobile'"
                    [ngbPopover]="mobileExplained">
                    <div>
                        <i class="bi bi-patch-question-fill" role="button" data-toggle="popover" data-trigger="focus"
                            placement="bottom"></i>
                    </div>
                    <div> <span class="subtitle3">Don't know when your contract ends?</span> </div>
                </div>

                <div class="d-flex flex-row gap-8 clickable" *ngIf="templateType == 'broadband' && templateData"
                    [ngbPopover]="broadbandExplained">
                    <div>
                        <i class="bi bi-patch-question-fill" role="button" data-toggle="popover" data-trigger="focus"
                            placement="bottom"></i>
                    </div>
                    <div> <span class="subtitle3">Don't know when your contract ends?</span> </div>
                </div>

                <button type="submit" class="btn btn-primary btn-submit w-100" style="max-width: 274px"
                    id="submit-contract-lock"
                    [disabled]="contractEndForm.invalid && !contractEndForm.get('notUnderContract').value">{{ hanging ?
                    '' : 'Confirm' }}<i *ngIf="hanging" class="fa fa-spinner fa-spin"></i></button>

            </div>
        </div>

    </form>
</ng-container>


<ng-container *ngIf="this.state == 'locked'">

    <div class="d-flex flex-column justify-content-between text-center align-items-center ">
        <div class="d-flex flex-column">
            <span class="subtitle1">Optimisation isn't recommended for now.</span>

        </div>
        <div class="d-flex flex-column">
            <span class="body1">No worries, we've got your back and will let you know when you should consider
                optimising, approximately {{getNumberLockedMonths()}} months from now.</span>
            <i class="bi bi-shield-shaded" style="color:  var(--paylow-primary-text-color); font-size: 64px"></i>
            <span class="body1">Meanwhile, take a look at what subscriptions you might be able to optimise.</span>
        </div>
        <div class="d-flex flex-column">
            <button class="btn-dark" (click)="this.product.setSelectedSubscription(null)" id="contract-lock-back-to-dashboard"><span>Back to dashboard</span></button>
        </div>
    </div>

</ng-container>

<ng-template #mobileExplained>
    <div class="d-flex flex-column dark-text">
        <span class="body2">1. <span class="bold">SMS:</span> Start a new text message.</span>
        <span class="body2">2. <span class="bold">Code:</span> Text "INFO" to 85075.</span>
        <span class="body2">3. <span class="bold">Wait:</span> You'll get the info shortly.</span>
    </div>
</ng-template>

<ng-template #broadbandExplained>
    <div class="d-flex flex-column dark-text">
        <span class="body2">1. {{ templateData.instruction1 }}</span>
        <span class="body2">2. {{ templateData.instruction2 }}</span>
        <span class="body2">3. {{ templateData.instruction3 }}</span>

        <div class="d-flex justify-content-between mb-2">
            <a [href]="templateData.link" target="_blank"><span class="body2 bold">Log in</span></a>
            <span class="body2 bold">{{ templateData.phone }}</span>
        </div>
    </div>
</ng-template>