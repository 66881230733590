import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { BenefitQuestionObject, BenefitsSwitchState, dealSelect, fetchClaimBenefit, fetchDeals, fetchEstimation, InbestDeal, initiate, LightQuestions, setLightQuestions, setSelectedDeal, stepBack, stepNext, updateClaimed, updateModel, updateStep } from './state';
import { Stepper } from 'src/app/helpers/stepper';
import { delay, distinctUntilChanged, filter, map, take } from 'rxjs';
import { ProductTrackerService } from 'src/app/services/product-tracker/product-tracker.service';
import { FadeAnimation } from 'src/app/animations/simple_animations';
import { ProductService } from 'src/app/services/product-service/product-service.service';
import { UserActivityDetection } from 'src/app/helpers/activityDetection';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';  
import { RedirectModalComponent } from '../../../common/modals/redirect-modal/redirect-modal.component';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-switch-panel-benefits',
  templateUrl: './switch-panel-benefits.component.html',
  styleUrls: ['./switch-panel-benefits.component.scss'],
  animations: [FadeAnimation]
})
export class SwitchPanelBenefitsComponent implements OnInit, OnDestroy {

  public stepper = new Stepper();
  public step_names = ['Basic questions', 'Initial estimation', 'Detailed questions', 'Claimable benefits'];
  @Input() mode = 'standalone' as 'standalone' | 'bundle';
  public request_feedback: boolean;
  public switch_hanging = false;
  public activityDetection: UserActivityDetection;
  public selected_deal_id: string;
  public selected_deal_url: string;
  public step: string;
  public hanging_step: string;

  public steps = [
    { display_name: 'light questions', name: 'light-questions', index: 0 },
    { display_name: 'estimation', name: 'estimation', index: 1 },
    { display_name: 'full questions', name: 'full-questions', index: 2 },
    { display_name: 'deals', name: 'deals', index: 3 },
  ]

  icons = {
    'childTaxCredit': 'fa-solid fa-child-reaching',
    'discretionaryHousingPayment': 'bi bi-house',
    'charitableGrants': 'fa-solid fa-hand-holding-heart',
    'mobile': 'fa-solid fa-mobile',
    'universalCredits': 'bi-credit-card',
    'localWelfareAssistance': 'fa-solid fa-hand-holding-heart',
    'childBenefits': 'fa-solid fa-child-reaching',
    'councilTaxDiscretionaryReliefScheme': 'bi bi-bank2',
    'householdSupportFund': 'bi bi-house',
    'freeSchoolMeals': 'fa-solid fa-child-reaching',
    'councilTaxReduction': 'bi-credit-card',
  }


  get step_index() {
    const item = this.steps.find(s => s.name == this.step);
    if (!item) return 0;
    return item.index + 1;
  }

  public light_questions = this.store.select(state => state.benefits_switch.model?.light_questions);
  public deals = this.store.select(state => state.benefits_switch.model?.deals);
  public estimation = this.store.select(state => state.benefits_switch.model?.light_estimation);
  public calculated_light_estimation$ = this.store.select(state => state.benefits_switch.model?.calculated_light_estimation)
  public calculated_benefits$ = this.store.select(state => state.benefits_switch.model?.calculated_benefits)
  public calculated_benefits: boolean 
  public calculated_light_estimation: boolean 



  constructor(
    protected store: Store<{ benefits_switch: BenefitsSwitchState }>,
    private product_tracker: ProductTrackerService,
    protected product: ProductService,
    private renderer: Renderer2,
    protected _activeModal : NgbActiveModal,
    private  ngbModal: NgbModal,
  ) {
    this.store.select(state => state.benefits_switch.step).subscribe(r => { this.step = r;  });
    this.store.select(state => state.benefits_switch.api_calls).pipe(delay(500), map(calls => calls.filter(c=>!c?.startsWith('_')))).subscribe(r => { this.hanging_step = r[0] || null;  });

    this.store.select(state => state.benefits_switch.step)
      .pipe(distinctUntilChanged())
      .subscribe(r => {
        if (r != this.stepper.stepWithNext) {
          this.stepper.setStep(r);
        }
      });

    this.store.dispatch(initiate());


    this.calculated_benefits$.subscribe(r => 
      {
        this.calculated_benefits = r
      }
    )

    this.calculated_light_estimation$.subscribe(r => 
      {
        this.calculated_light_estimation = r
      }
    )

  }
  ngOnDestroy(): void {
    this.store.dispatch(updateStep({ step: '' }));
  }

  ngOnInit(): void {
    this.activityDetection = new UserActivityDetection(this.renderer);
  }

  public dealBackClicked(source) {
    this.store.dispatch(stepBack());
  }

  public nextClicked(source) {
    this.store.dispatch(stepNext());
  }

  public lightQuestionsNext(questions: LightQuestions) {
    this.store.dispatch(setLightQuestions({ light_questions: questions }));
    this.store.dispatch(fetchEstimation());
  }

  public estimationNext() {
    this.store.dispatch(updateStep({ step: 'full-questions' }));
  }

  public fullQuestionsNext() {
    this.store.dispatch(fetchDeals({ caller: 'questions' }));
  }

  public dealClicked(deal) {
    this.store.dispatch(dealSelect({ deal_id: deal.id }));
    this.product_tracker.setTag("switch.benefits.step", { step: this.step_names[this.stepper.stepWithTransition] });
  }

  public stepClicked(step) {
    const step_name = this.steps.find(s => s.index == step - 1)?.name;

    if (!step_name) return;
    const current_step_nunber = this.step_index;

    if (this.calculated_light_estimation && step_name == 'estimation') {
      this.store.dispatch(updateStep({ step: step_name }));
      return
    } 
    if (this.calculated_benefits) {
      this.store.dispatch(updateStep({ step: step_name }));
    } else {
      if (current_step_nunber > step) {
        this.store.dispatch(updateStep({ step: step_name }));
      }
    }

  }

  public switchFeedback(feedback: boolean) {
    //get the selected deal id and add to the dispatch

    this.request_feedback = false;
    if (feedback) {
      this.store.select(state => state.benefits_switch.model).pipe(take(1)).subscribe(m => {
        const deal = m.deals.month.find(d => d.id == m.selected_deal_id);
        if (deal) {
          this.store.dispatch(fetchClaimBenefit({ benefit: deal }));
        }
      });

    }
  }

  public dealCheckout(deal: InbestDeal) {
    this.switch_hanging = true;

    this.store.dispatch(setSelectedDeal({ id: deal.id }));

    this.activityDetection.setupActivityDetection(() => { this.request_feedback = true; });
    this.product_tracker.setTag("switch.benefits.checkout", { id: this.selected_deal_id });

    const modalRef = this.ngbModal.open(RedirectModalComponent, {
      centered: true,
      backdrop: 'static'
    });

    modalRef.componentInstance.logo_url = this.icons[deal.id] || 'bi-hand-thumbs-up-fill'; 
    modalRef.componentInstance.name = deal.title;
    modalRef.componentInstance.amount = deal.amount;
    modalRef.componentInstance.url = deal.cta[0].url;
    modalRef.componentInstance.category = 'benefits';
  }

}
