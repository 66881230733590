import { Dashboard } from "src/app/types";
import { ProductState } from "./product-state.state";
import { local_copy } from "src/app/helpers/utils";



export function overrideBenefitsSubscriptions(state : ProductState, dashboard : Dashboard) {
    const subs = local_copy(dashboard.subscriptions);
    if (state.environment?.benefits_provider != 'heka') {
        return dashboard.subscriptions;
    }

    const links = {
      "Runna":"https://app.hekahappy.com/brand/f6739c95-1aa7-400e-bfd5-fb919c619e76",
      "Heights":"https://app.hekahappy.com/brand/809e37ff-1063-4e13-8e42-3ddf1aefa18f",
      "Huel":"https://app.hekahappy.com/brand/5a29ceda-b24c-4e65-a1ce-02335617c9af",
      "Mob":"https://app.hekahappy.com/brand/95efe40b-504e-40a7-85db-dc9ca0dd2cc7",
      "HelloFresh":"https://app.hekahappy.com/brand/17602629-6320-4bb2-b0f5-fbe3b14eada8",
      "Hello Fresh":"https://app.hekahappy.com/brand/17602629-6320-4bb2-b0f5-fbe3b14eada8",
      "Blinkist":"https://ap p.hekahappy.com/brand/7b4dd15d-1808-4543-8c16-17f5439f757b",
      "Borrow My Doggy":"https://app.hekahappy.com/brand/a7f40ec8-f149-4855-9c1c-8f21ab6e6ed8",
      "Skillshare":"https://app.hekahappy.com/brand/f03ebaaa-5d7c-4f0e-b63e-62daca16ec0d",
    }

    const benefits_categories = [
      ];
      const benefits_providers = Object.keys(links);
  
      return subs.map(s => {
        if( benefits_categories.includes(s.category.name) ) {
          s.status.type = 'partner-improvable';
          s.activePlan.logoUrl = links[s.name];
        } else if (benefits_providers.includes(s.name)) {
          s.status.type = 'partner-improvable';
          s.activePlan.logoUrl = links[s.name];
        }
  
        return s;
      });
}