import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FadeAnimation } from 'src/app/animations/simple_animations';
import { Stepper } from 'src/app/helpers/stepper';
import { ProductTrackerService } from 'src/app/services/product-tracker/product-tracker.service';

@Component({
  selector: 'app-benefits-claim',
  templateUrl: './benefits-claim.component.html',
  styleUrls: ['./benefits-claim.component.scss'],
  animations: [FadeAnimation]
})
export class BenefitsClaimComponent implements OnInit {

  stepper : Stepper;

  constructor(
    public _activeModal : NgbActiveModal,
    public _productTracker : ProductTrackerService
  ) { }

  cta_click(claimed : boolean) {
    this._productTracker.setTag('benefits_claim_result', { result: claimed ? 'claimed' : 'passed', benefits_provider: 'perkbox' });
    this.stepper.setStep(claimed ? 2 : 3);

    setTimeout(() => {
      this._activeModal.close();
    }, 2500);
  }

  ngOnInit(): void {
    this.stepper = new Stepper();
    this.stepper.setStep(1);
  }

}
