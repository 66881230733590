import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, Router, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../components/circlet/circlet.module').then(m => m.CircletModule),
  },
  {
    path: '**',
    redirectTo:''
  },
]


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: false }),
  ],
  exports: [RouterModule]
})
export class RoutingModule { 

}
