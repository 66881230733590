import { Store, createAction, createFeatureSelector, props, select } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, act, createEffect, ofType } from '@ngrx/effects';
import { interval, of } from 'rxjs';
import { map, mergeMap, catchError, withLatestFrom, tap, filter, concatMap, switchMap, take, takeWhile } from 'rxjs/operators';
import { ProductApiService } from 'src/app/services/product-api/product-api.service';
import { BaseNotificationItem, NotificationsList, OptimizationNotificationItem } from 'src/app/types';
import { FeedEntry } from '../feed-item/feed-list-item.component';
import { Draft, produce } from 'immer';
import { local_copy } from 'src/app/helpers/utils';

export const selectFeedState = createFeatureSelector<FeedState>('feed');


export const changeDisplayMode = createAction('[Feed] Change Display Mode', props<{ mode: string }>());
export const fetchNotifications = createAction('[Feed] Fetch Notifications');
export const fetchAsyncNotifications = createAction('[Feed] Fetch async Notifications');
export const fetchUpdateNotificationResult = createAction('[Feed] Fetch fetchUpdateNotificationResult', props<{ notification_id : string, result : string }>());
export const setNotifications = createAction('[Feed] Set Notifications', props<{ notifications: NotificationsList }>());
export const addFeedItem = createAction('[Feed] Add Item', props<{ item: FeedEntry }>());
export const selectFeedItem = createAction('[Feed] Select Item', props<{ id: string }>());


export const updateFeedState = createAction('[Feed] State update', props<{ name,  immerFunction: (draft: Draft<any>) => void }>());

export interface FeedState {
    all_notifications: NotificationsList;
    disply_notifications: NotificationsList;
    selected_feed_item: string;
    percentage : number;
    display_mode : string;
    feed: FeedEntry[];
    toHandle: number;

}

export const initialState: FeedState = {
    all_notifications: null,
    selected_feed_item: null,
    display_mode: 'active',
    percentage: 0,
    disply_notifications: null,
    feed: [ ],
    toHandle: 0
};

const deprecated_types = [
    'sign_up_energy'
]

export const feedReducer = createReducer(
    initialState,
    on(updateFeedState, (state, { immerFunction }) => {
        return produce(state, immerFunction);
    }),
    on(changeDisplayMode, (state, { mode }) => {
        let dis = mode;

        if (!dis) {
            switch (state.display_mode) {
                case 'active':
                    dis = 'dismissed';
                    break;
                case 'dismissed':
                    dis = 'active';
                    break;
                default:
                    dis = 'active';
            }
        }

        return { 
            ...state, 
            feed: [], 
            disply_notifications: getDisplayNotification(state.all_notifications, dis),
            display_mode: dis
        };
    }),

    on(setNotifications, (state, { notifications }) => {
        const non_deprecated = notifications.filter(n => !deprecated_types.includes(n.type));
        return { 
            ...state, 
            feed: [], 
            all_notifications: non_deprecated,
            disply_notifications: getDisplayNotification(non_deprecated, state.display_mode),
            percentage: notifications.length === 0 ? 0 : Math.round(100 * non_deprecated.filter(n => !getFilterNotifications(non_deprecated).includes(n)).length / non_deprecated.length),
            toHandle: getFilterNotifications(non_deprecated).length
        };
    }),
    on(addFeedItem, (state, { item }) => {
        const items = state.feed.filter(i => i.type !== item.type);
        items.unshift(item);
        return { ...state, feed: items }
    }),
    on(addFeedItem, (state, { item }) => {
        const items = state.feed.filter(i => i.type !== item.type);
        items.unshift(item);
        return { ...state, feed: items }
    }),
    on(selectFeedItem, (state, { id }) => {
        return { ...state, selected_feed_item: id }
    }),

);

function getFilterNotifications(notifications) {
    const pending_results = ['missed-subscription'];
    return notifications?.filter(n => !n.dismissed && !pending_results.includes(n.result));
}

function getDisplayNotification(notifications, mode) {
    let filtered = local_copy(notifications);

    if (mode === 'active') {
        filtered =  getFilterNotifications(notifications);
    } else if (mode === 'dismissed') {
        filtered = notifications.filter(n => !n.dismissed);
    }

    const priorityTypes = ['welcome', 'solve_ambiguity', 'add_address', 'broadband_add_address', 'missing_broadband', 'missing_mobile', 'missing_energy', 'mobile_optimization','improvable_invest', 'broadband_optimization', 'benefits', 'sign_up_energy', 'energy_optimization' ];
    const sortedNotifications = filtered
        .sort((a, b) => {
            const aPriorityIndex = priorityTypes.indexOf(a.type);
            const bPriorityIndex = priorityTypes.indexOf(b.type);

            if (a.result === 'Dismiss' && b.result === 'Dismiss') {
                return 0; // Both are 'Dismiss', keep their relative order
            }
            if (a.result === 'Dismiss') {
                return 1; // 'Dismiss' should be at the end
            }
            if (b.result === 'Dismiss') {
                return -1; // 'Dismiss' should be at the end
            }

            if (aPriorityIndex !== -1 && bPriorityIndex !== -1) {
                return aPriorityIndex - bPriorityIndex;
            } else if (aPriorityIndex !== -1) {
                return -1;
            } else if (bPriorityIndex !== -1) {
                return 1;
            } else {
                return a.type.localeCompare(b.type);
            }
        });

    return sortedNotifications;
}

@Injectable()
export class FeedEffects {
    constructor(
        private actions$: Actions,
        private api: ProductApiService,
        private store: Store<{feed: FeedState}>
    ) { }

    fetchNotificationAPI$ = createEffect(() => this.actions$.pipe(
        ofType(fetchNotifications),
        mergeMap(() => this.api.getNotifications()
            .pipe(
                map(notifications => setNotifications({notifications: notifications})),
                catchError(() => of({ type: '[Feed] Error loading notifications' }))
            ))
    ));


    fetchAsyncNotificationAPI$ = createEffect(() => this.actions$.pipe(
        ofType(fetchAsyncNotifications),
        switchMap(() => interval(5000).pipe(
          take(7),
          withLatestFrom(this.store.pipe(select(state => state.feed.all_notifications))),
          switchMap(([_, currentNotifications]) => this.api.getNotifications().pipe(
            map(apiNotifications => {
              if (JSON.stringify(apiNotifications) !== JSON.stringify(currentNotifications)) {
                return setNotifications({ notifications: apiNotifications });
              } else {
                return { type: '[Feed] Async - no new notifications'};
              }
            }),
            catchError(() => of({ type: '[Feed] Error loading notifications' }))
          ))
        ))
      ));


    fetchUpdateNotificationResult$ = createEffect(() => this.actions$.pipe(
        ofType(fetchUpdateNotificationResult),
        withLatestFrom(this.store.pipe(select(selectFeedState))),
        mergeMap(([action, state]) => 
            this.api.updateNotification(action.notification_id, action.result)
            .pipe(map(res=>fetchNotifications())))
    ));


}
