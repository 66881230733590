import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';

import { Component } from '@angular/core';

@Component({
  selector: 'app-tabbed-base',
  template: ''
})
export abstract class TabbedBaseComponent implements OnInit, OnDestroy {
  protected _tab_name = 'tab';
  protected _tab_item_default = null;

  currentTab: string | null = null;
  private routeSub: Subscription | null = null;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected router: Router
) {}

  get tabName() {
    return `${this._tab_name}`;
  }

  ngOnInit(): void {
    this.currentTab = this._tab_item_default;
    this.onTabChange(this.currentTab);
    
    this.routeSub = this.activatedRoute.queryParamMap.pipe(
      filter((params) => params.get(this.tabName) !== this.currentTab)
    ).subscribe((params) => {
      this.currentTab = params.get(this.tabName) || this._tab_item_default;
      this.onTabChange(this.currentTab);
    });
  }

  ngOnDestroy(): void {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  changeTab(tab: string): void {
    this.router.navigate([], {
      queryParams: { [this.tabName]: tab },
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Abstract method to handle tab changes.
   * Must be implemented by the derived components.
   */
  onTabChange(tab: string | null): void {
    
  }
}
