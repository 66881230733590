import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {filter, map, Subscription} from "rxjs";
import { ProductTrackerService } from 'src/app/services/product-tracker/product-tracker.service';
import { TabbedBaseComponent } from 'src/app/helpers/tabbed.component';

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss']
})
export class ProfileModalComponent extends TabbedBaseComponent implements OnInit, OnDestroy {
  protected override _tab_name: string = 'settings_tab';
  protected override _tab_item_default: string = 'My Account';
  
  public tabNames: string[] = ['My Account', 'Contact Us', 'Logout'];
  public subTabs = {
    'Disconnect': ['Logout', 'Delete my account'],
    'FAQ': ['About Paylow', 'Using Paylow', 'Account and Data', 'Security and Compliance', 'General'],
    'Personal Account': null,
  } 

  // public selectedSubTab: string = 'My Account';
  public faq_tags: string[] = null;

  public FAQ_terminate: boolean = false;

  private _subs = new Subscription();

  constructor(public _activeModal: NgbActiveModal,
              private _activatedRoute: ActivatedRoute,
              private _router: Router,
              private product_tracker : ProductTrackerService) {
      super(_activatedRoute, _router);
  }

  override ngOnInit(): void {
    super.ngOnInit();

  }

  override changeTab(tab: string | null): void {
    this.router.navigate([], {
      queryParams: { [this.tabName]: tab },
      queryParamsHandling: 'merge',
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subs.unsubscribe();
  }

  public faqTags() {
    // switch(this.selectedSubTab) {
    //   case 'About Paylow':
    //     return ['about'];
    //   case 'Using Paylow':
    //     return ['usage'];
    //   case 'Account and Data':
    //     return ['account'];
    //   case 'Security and Compliance':
    //     return ['secure'];
    //   case 'General':
    //     return ['general'];
    //   default:
    //     return null;
    // }
  }

  public onCancelClick() {
    this._activeModal.dismiss('Cancel');
  }

  public onSaveClick() {
    this._activeModal.close('SAVE');
  }
}
