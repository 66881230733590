import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductApiService } from 'src/app/services/product-api/product-api.service';

@Component({
  selector: 'app-leave-feedback',
  templateUrl: './leave-feedback.component.html',
  styleUrls: ['./leave-feedback.component.scss']
})
export class LeaveFeedbackComponent implements OnInit {

  feedback_value: string = "";

  hanging = false;

  constructor(
    private _product_api : ProductApiService,
    public _activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }
  public confirm() {
    this.hanging = true;
    this._product_api.eventFeedback({ feedback_value: this.feedback_value, feedback_pointer: '' }).subscribe(
      r => {
        console.log(r);
      }
    )

    setTimeout(() => {
      this._activeModal.close();
    }, 1000);
  }
  
}
