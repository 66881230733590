import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductState, deleteManualSub, fetchProviders, setCustomizationProviders, updateCustomization, updateCustomizationSubs } from '../../../state/product-state.state';
import { Store } from '@ngrx/store';
import { PaylowSubscription, Provider, SubscriptionPlan } from 'src/app/types';
import { Observable, filter, map, pairwise, startWith, tap } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Stepper } from 'src/app/helpers/stepper';
import { FadeAnimation } from 'src/app/animations/simple_animations';
import { CONSENT_SERVICE_TOKEN } from 'src/app/services/consent/consent-token.di';
import { BaseConsentService } from 'src/app/services/consent/base-consent.service';

@Component({
  selector: 'app-add-subscription',
  templateUrl: './add-subscription.component.html',
  styleUrls: ['./add-subscription.component.scss'],
  animations: [FadeAnimation]
})
export class AddSubscriptionComponent implements OnInit, OnDestroy {
  public options = ['Music', 'Tax', 'Pet Insurance', 'Mortgage', 'Donations', 'Health', 
    'Literature', 'Pets', 'Other', 'Software', 'Personal Care', 'Transport', 'Gaming', 
    'Education', 'Telecom (bundle)', 'Streaming', 'Broadband', 'Gambling', 'VPN', 'Fitness', 
    'Water', 'Children', 'Energy', 'Financials', 'Services', 'Food & Beverage', 'Investments', 
    'Insurance', 'Mobile', 'Leisure', 'Wellness', 'Household', 
    'Pension', 'Retail', 'Appliances'];

  @Input() manual_name?: string;
  @Input() manual_category?: string;
  @Input() manual_plan?: SubscriptionPlan;
  @Input() subscription_id?: string;


  public providerOptions: Provider[] = [];
  public selectedOption: string = '';
  public selectedCategory: string = '';
  public selectedProvider: string = '';
  public providerName: string = '';
  public amount: number;
  public name: string = ''
  public addSubscrptionForm: FormGroup;
  stepper : Stepper;

  hanger$ : Observable<string[]> = this.store.select(state=>state.product.active_api_calls);

  constructor(
    @Inject(CONSENT_SERVICE_TOKEN) public _consentService: BaseConsentService,
    public _activeModal: NgbActiveModal,
    protected store: Store<{ product: ProductState }>,
    private fb: FormBuilder) {
      this.addSubscrptionForm = this.fb.group({
        category: [this.manual_category || '', Validators.required],
        provider: [this.manual_name || null, Validators.required],
        amount: [this.manual_plan?.price || null, [Validators.required, Validators.max(9999)]],
      }, { validator: this.providerValidator });
    }

    ngOnInit(): void {
      console.log(this.subscription_id); 
      

      this.addSubscrptionForm.patchValue({
        category: this.manual_category || '',  
        provider: this.manual_name || '',      
        amount: this.manual_plan?.price || '' 
      });
    
      this.options = this.options.sort();
    
      this.stepper = new Stepper();
      this.stepper.setStep('submit');
    
      if (this.manual_category === 'Mobile' || this.manual_category === 'Broadband' || this.manual_category === 'Energy' || this.manual_category === 'Telecom (bundle)') {
        this.store.dispatch(fetchProviders({ category: this.manual_category }));
      }
    

      this.store.select(state => state.product.dashboard.customization_providers).subscribe(providers => {
        this.providerOptions = providers;
        if (this.manual_name && providers.some(provider => provider.display_name === this.manual_name)) {
          this.addSubscrptionForm.patchValue({ provider: this.manual_name });


        }
      });
    
      this.addSubscrptionForm.get('category').valueChanges.subscribe(this.onOptionSelected.bind(this));

      this.addSubscrptionForm.get('provider')?.valueChanges.subscribe(provider => {
      });
    }
    
 
  ngOnDestroy(): void {
    this.store.dispatch(setCustomizationProviders({ customizationProviders: [] }));  
  }

providerValidator(group: FormGroup): { [key: string]: any } | null {
  const category = group.get('category')?.value;
  const provider = group.get('provider')?.value;

  // When category requires providers from dropdown
  if (['Mobile', 'Broadband', 'Energy', 'Telecom (bundle)'].includes(category)) {
    if (!provider || typeof provider !== 'string' || provider.trim() === '') {
      return { invalidProvider: true }; // Ensure a provider is selected
    }
  } else {
    // For other categories, validate alphanumeric input
    if (!provider || /^\d+$/.test(provider)) {
      return { invalidProvider: true }; // Disallow only digits for custom input
    }
  }

  return null;
}


  onOptionSelected(category: string): void {
    if (category === 'Mobile' || category === 'Broadband' || category === 'Energy' || category === 'Telecom (bundle)') {
      this.store.dispatch(fetchProviders({ category: category }));
    } else {
      this.providerOptions = [];
    }
  }

  addSubscription() {
    if (this.addSubscrptionForm.valid) {
      const category = this.addSubscrptionForm.get('category')?.value;
      const provider = this.addSubscrptionForm.get('provider')?.value;
      const amount = this.addSubscrptionForm.get('amount')?.value;

      this.store.dispatch(updateCustomizationSubs({
        manual_subscription: {
          category,
          provider,
          amount,
          subscription_id: this.subscription_id
        }
      }));

      // this.store.select(product => product.product.active_api_calls).pipe(
      //   startWith([]),
      //   pairwise(),
      //   map(([previous, current]) => previous.filter(call => !current.includes(call))),
      //   filter(removedCalls => removedCalls.length > 0),
      //   tap(removedCalls => {
      //     if ( removedCalls.includes('updateCustomizationSubs') ) {
      //       this.stepper.setStep('post-step')
      //       // this._activeModal.close();
      //     }
      //   })
      // );

      setTimeout(() => {
        this.stepper.setStep('post-submit');
      }, 2000);
    } 

    if (this.subscription_id) { 
      this.store.dispatch(deleteManualSub({ subscription_id: this.subscription_id }));
    }

  }

  public cancelSubscription() {
    this.store.dispatch(updateCustomization({ sub_id: this.subscription_id, action: 'hide', delete: true }));

    setTimeout(() => {
      this.stepper.setStep('post-delete');
    }, 500);
  }

  
}
