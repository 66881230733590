import { Action, Store, createAction, createFeatureSelector, createSelector, props, select } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merge, of } from 'rxjs';
import { map, mergeMap, catchError, withLatestFrom, tap, filter, concatMap, delay, take, finalize } from 'rxjs/operators';
import { ProductApiService } from 'src/app/services/product-api/product-api.service';
import { BroadbandAdvancedSearchModel, BroadbandDeal, BroadbandDealDetails, BroadbandFilterModel, BroadbandOrder, BroadbandOrderGeneralDetails, BroadbandPaymentDetails, BroadbandSwitchObject, UserAttribute } from 'src/app/types/beta-optimize-models.model';
import { ProductService } from 'src/app/services/product-service/product-service.service';
import { Draft, produce } from 'immer';
import { setNewAttributes, UserAttributesState } from '../../../common/modals/personalization-modal/state';
import { PersonalizationModel } from 'src/app/types';
import { noop } from '../switch-panel-mobile/state';
import { addGeneralErrorToast } from '../../../state/product-state.state';


export interface BroadbandState {
    view_mode: 'standalone' | 'bundle';
    api_calls: string[],
    sub_step: string;
    step: string;
    current_sub_id: string | null;
    models: Record<string, BroadbandSwitchObject>;
    order_errors: string[] // maps current_sub_id to subscription object -> theres many broadband subscriptions
    advanced_search: BroadbandAdvancedSearchModel;
    is_advanced_search: boolean;
    current_filters: BroadbandFilterModel;
    page_num: number;
    is_loading_more: boolean;
    show_load_more: boolean;
    show_advanced_search_modal: boolean;
    sort_by_name: string;
}

// Initial state
const initialState: BroadbandState = {
    view_mode: 'standalone',
    api_calls: [],
    sub_step: '',
    step: '',
    current_sub_id: null,
    models: {},
    order_errors: [],
    is_advanced_search: false,
    advanced_search: {
        filter_model : {
            filters: {
            contract_lengths: null,
            integrations: null,
            providers: null,
            download_speeds: null,
            upload_speeds: null,
            }
        },
        deals: null,
    },
    current_filters : {
        filters: {
            contract_lengths: null,
            integrations: null,
            providers: null,
            download_speeds: null,
            upload_speeds: null,
        }

    },
    page_num: 1,
    is_loading_more: false,
    show_load_more: false,
    show_advanced_search_modal: false,
    sort_by_name: "Recommended Deals" 
};

export const selectBroadbandState = createFeatureSelector<BroadbandState>('broadband_switch');


// Define actions
export const addSubscription = createAction('[Broadband] Add model', props<{ subscription_id: string; model: BroadbandSwitchObject }>());
export const updateModel = createAction('[Broadband] Update model', props<{ name: string, model: BroadbandSwitchObject }>());
export const removeSubscription = createAction('[Broadband] Remove model', props<{ subscription_id: string }>());

export const setCurrentSubId = createAction('[Broadband] Set Current Subscription ID', props<{ subscription_id: string }>());
export const setViewMode = createAction('[Broadband] Set View Mode', props<{ view_mode: 'standalone' | 'bundle' }>());

export const attributeUpdated = createAction('[Personalization] Attribute updated', props<{ attribute: UserAttribute }>());

export const addApiCall = createAction('[Broadband] Add hangning', props<{ name: string }>());
export const removeApiCall = createAction('[Broadband] Remove hangning', props<{ name: string }>());

export const updateStep = createAction('[Broadband] Update step', props<{ step: string }>());

export const stepNext = createAction('[Broadband] Next Step');
export const stepBack = createAction('[Broadband] Back Step');
export const setDeals = createAction('[Broadband] setDeals', props<{ deals: any }>());

export const fetchDeals = createAction('[Broadband] fetch deals', props<{ caller: string, address_id: string }>());
export const fetchDetails = createAction('[Broadband] fetch deal details');
export const updateDealDetails = createAction('[Broadband] updateDealDetails', props<{ deal_details: BroadbandDealDetails }>());
export const setDealClicked = createAction('[Broadband] setDealClicked', props<{ deal : BroadbandDeal }>());

export const submitOrder = createAction('[Broadband] fetch submit order');
export const postCheckout = createAction('[Broadband] postCheckout',  props<{ model: BroadbandSwitchObject }>());

export const setOrderPayment = createAction('[Broadband] setOrderPayment',  props<{ payment: string }>());
export const setCheckoutStep = createAction('[Broadband] setCheckoutStep',  props<{ steps: string[], index: number }>());
export const setGeneralDetails = createAction('[Broadband] setGeneralDetails',  props<{ details: BroadbandOrderGeneralDetails }>());
export const setAddressHistory = createAction('[Broadband] setAddressHistory',  props<{ details: any }>());
export const setInstallationAndLandline = createAction('[Broadband] setInstallationAndLandline',  props<{ details: any }>());
export const setDemoCheckoutClick = createAction('[Broadband] setDemoCheckoutClick');
export const setServiceModel = createAction('[Broadband] setServiceModel',  props<{ model:  BroadbandSwitchObject}>());
export const setOrderDetails = createAction('[Broadband] setOrderDetails',  props<{ orderDetails:  BroadbandOrder}>());
export const setOrderErrors = createAction('[Broadband] setOrderErros',  props<{ errors:  any}>());

// advanced search 
export const setAdvancedSearch = createAction('[Broadband] setAdvancedSearch', props<{  is_advanced_search: boolean }>()); 
export const showAdvancedSearchModal = createAction('[Broadband] showAdvancedSearchModal', props<{  show: boolean }>()); 
export const setAdvancedSearchModel = createAction('[Broadband] setAdvancedSearchModel', props<{ field?: string, advanced_search: BroadbandAdvancedSearchModel }>()); 
export const fetchFilterModel = createAction('[Broadband] fetchFilterModel' , props<{ filter_model?: BroadbandFilterModel, is_handset?: boolean, page_num: number }>()); 
export const setNewFilter = createAction('[Broadband] setNewFilter' , props<{ field?: string, value?: any, reset_model?: boolean, sort_by?: 'recommended' | 'monthly_price' | 'num_months_contract' | 'download_speed' | 'upload_speed', reverse?: boolean, sort_by_name?: string }>()); 
export const setLoadMore = createAction('[Broadband] setLoadMore' , props<{ mode: boolean}>()); 
export const resetAdvancedSearch = createAction('[Broadband] resetAdvancedSearch' , props<{  reset_hard?: boolean }>()); 


export const mobileSwitched = createAction('[Broadband] switched');

const steps = ['address', 'deals', 'deal_view', 'collect_details', 'review_and_checkout', 'post_checkout', 'error'];
const subSteps = [
    [],
    [],
    [],
    ['general-details', 'address-history', 'installation-and-landline'],
    ['summary', 'collect-sortcode'], 
    []
];


export const selectSubscriptionById = createSelector(
    selectBroadbandState,
    (state: BroadbandState, props: { id: string }) => state.models[props.id]
);

// Create reducer
export const broadbandReducer = createReducer(
    initialState,
    on(setViewMode, (state, { view_mode }) => ({
        ...state,
        view_mode: view_mode
    })),
    on(addSubscription, (state, { subscription_id }) => {
        if (state.models[subscription_id]) {
            return state;
        }
        return {
            ...state,
            current_sub_id: subscription_id,
            models: {
                ...state.models,
                [subscription_id]: { subscription_id: subscription_id }
            }
        }
    }),
    on(updateModel, (state, { model }) => {
        if (state.current_sub_id) {
            return {
                ...state,
                models: {
                    ...state.models,
                    [state.current_sub_id]: model
                }
            };
        }
        return state;
    }),
    on(updateDealDetails, (state, { deal_details }) => {
        return produce(state, draft => {
            const model = draft.models[draft.current_sub_id];
            if (!model) return;
            draft.models[draft.current_sub_id].deal_details = deal_details;
        });
    }),
    on(removeSubscription, (state, { subscription_id }) => {
        const { [subscription_id]: _, ...rest } = state.models;
        return {
            ...state,
            step: '',
            sub_step: '',
            models: rest
        };
    }),
    on(setOrderPayment, (state, { payment }) => {
        return produce(state, draft => {
            draft.models[draft.current_sub_id].payment = payment;
        });
    }),
    on(setDealClicked, (state, {  deal }) => {
        return produce(state, draft => {
            draft.show_advanced_search_modal = false;
            draft.models[draft.current_sub_id].selected_deal_id = deal.id;
            draft.models[draft.current_sub_id].deal_details = null;
            draft.step = 'deal_view';
        });
    }),
    on(setGeneralDetails, (state, {  details }) => {
        return produce(state, draft => {
            draft.models[draft.current_sub_id].order = {};
            draft.models[draft.current_sub_id].order.generalDetails = details;
        });
    }),

    on(setAddressHistory, (state, {  details }) => {
        return produce(state, draft => {
            draft.models[draft.current_sub_id].order.credit_check = details;
        });
    }),
    on(setInstallationAndLandline, (state, {  details }) => {
        return produce(state, draft => {
            draft.models[draft.current_sub_id].order.installationAndLandline = details;
        });
    }),
    on(setOrderDetails, (state, {  orderDetails }) => {
        return produce(state, draft => {
            draft.models[draft.current_sub_id].order = orderDetails;
        });
    }),

    on(setDeals, (state, {  deals }) => {
        return produce(state, draft => {
            draft.models[draft.current_sub_id].deals = deals;
        });
    }),
    on(setDemoCheckoutClick, (state) => {
        return produce(state, draft => {
            draft.step = 'collect_details';
            draft.sub_step = 'address-history';
            draft.models[draft.current_sub_id].order = {};
            draft.models[draft.current_sub_id].order.generalDetails = {
              "title": "Mr",
              "firstName": "Steve",
              "lastName": "Saver",
              "email": "steveTheSaver@gmail.com",
              "contactNumber": "+447123456789",
              "dob": "1990-07-04",
            }
        });
    }),

    on(setServiceModel, (state, {  model }) => {
        return produce(state, draft => {
          draft.models[draft.current_sub_id] = model;
            
        });
    }),

    on(setCheckoutStep, (state, { steps, index }) => {
        return produce(state, draft => {
            draft.sub_step = steps[index];
        });
    }),
    on(updateStep, (state, { step }) => {
        if (step == 'order-error') {
            return {
                ...state,
                step: 'order-error',
                sub_step: '' 
            };
        }
        let newStep = '';
        let newSubStep = '';
        
        const stepIndex = steps.indexOf(step);
        if (stepIndex !== -1) {
            return {
                ...state,
                step: step,
                sub_step: '' 
            };
        } else {
            for (let i = 0; i < subSteps.length; i++) {
                const subStepIndex = subSteps[i].indexOf(step);
                if (subStepIndex !== -1) {
                    newStep = steps[i];
                    newSubStep = subSteps[i][subStepIndex];
                    return {
                        ...state,
                        step: newStep,
                        sub_step: newSubStep
                    };
                }
            }
        }
    
        // If the step does not match any main steps or substeps, return the state unchanged or handle it accordingly
        return {
            ...state,
            // Optionally, you can log an error or handle the invalid step scenario
        };
    }),
    
    on(addApiCall, (state, { name }) => ({
        ...state,
        api_calls: [...state.api_calls, state.api_calls.includes(name) ? null : name]
    })),

    on(removeApiCall, (state, { name }) => ({
        ...state,
        api_calls: state.api_calls.filter(n => n != name)
    })),
    on(stepNext, (state) => {
        const currentStepIndex = steps.indexOf(state.step);
        const currentSubStepIndex = subSteps[currentStepIndex].indexOf(state.sub_step);


        if (currentSubStepIndex < subSteps[currentStepIndex].length - 1 ) { 
            if (subSteps[currentStepIndex][currentSubStepIndex + 1] == 'installation-and-landline' && (state.models[state.current_sub_id].deal_details?.installation_details.hasInstallationInfo == null || !state.models[state.current_sub_id].deal_details?.installation_details.hasInstallationInfo)) {
                return {
                    ...state,
                    step: steps[currentStepIndex + 1],
                    sub_step: subSteps[currentStepIndex + 1][0] || ''
                };
            }
            return {
                ...state,
                sub_step: subSteps[currentStepIndex][currentSubStepIndex + 1]
            };
        } else if (currentStepIndex < steps.length - 1) {
            return {
                ...state,
                step: steps[currentStepIndex + 1],
                sub_step: subSteps[currentStepIndex + 1][0] || ''
            };
        }
        return state;
    }),
    on(stepBack, (state) => {
        const currentStepIndex = steps.indexOf(state.step);
        const currentSubStepIndex = subSteps[currentStepIndex].indexOf(state.sub_step);

        if (currentSubStepIndex > 0) {
            return {
                ...state,
                sub_step: subSteps[currentStepIndex][currentSubStepIndex - 1]
            };
        } else if (currentStepIndex > 0) {
            const prevStepIndex = currentStepIndex - 1;
            return {
                ...state,
                step: steps[prevStepIndex],
                sub_step: subSteps[prevStepIndex][subSteps[prevStepIndex].length - 1] || ''
            };
        }
        return state;
    }),

    on(setOrderErrors, (state, { errors }) => ({
        ...state,
        order_errors: errors
    })),


    on(setAdvancedSearch, (state, { is_advanced_search }) => {
        return produce(state, draft => {
            draft.is_advanced_search = is_advanced_search;
            const cachedFilters = localStorage.getItem('paylow.advanced.search.' + draft.current_sub_id);
            if (cachedFilters) {
                draft.current_filters = JSON.parse(cachedFilters);
            }
        })
    }),
    on(setAdvancedSearchModel, (state, { field, advanced_search }) => {
        return produce(state, draft => {
            draft.show_load_more = true;
            if (advanced_search?.deals) {
                draft.models[draft.current_sub_id].all_deals = advanced_search.deals;
                draft.models[draft.current_sub_id].deals = advanced_search.deals;

                draft.advanced_search.filter_model = advanced_search.filter_model;
                draft.step = 'deals';
            }


        })
    }),
    on(setNewFilter, (state, { field, value, sort_by, reverse, sort_by_name }) => {
        return produce(state, draft => {
        if ((!value || value?.length == 0) && field) {
            draft.current_filters.filters[field] = null;
            }
        else {
            if (field) {
                draft.current_filters.filters[field] = { values: value };
              }
        }  
        if (sort_by || sort_by_name) {
        draft.current_filters.sort_by = sort_by;
        draft.sort_by_name = sort_by_name;
        draft.current_filters.reverse = reverse;
        }
        });
      }),
      
      on(fetchFilterModel, (state, { page_num }) => {
        return produce(state, draft => {
          if (page_num) {
            draft.page_num = page_num;

          }
        });
      }),
      on(setLoadMore, (state, { mode }) => { // mode: true/false to show/hide loading spinner
        return produce(state, draft => {
            draft.is_loading_more = mode;
        });
      }),

      on(resetAdvancedSearch, (state, { reset_hard }) => {
        return produce(state, draft => {
            if (reset_hard) {
            draft.show_advanced_search_modal = false;
            }
            draft.show_load_more = false;
            draft.current_filters = initialState.current_filters;
        });
      }),
      on(showAdvancedSearchModal, (state, { show }) => { 
        return produce(state, draft => {
            draft.show_advanced_search_modal = show;
        });
    }),
);


function initialStepSelection(state: BroadbandState, personalizationAttributes : PersonalizationModel) {
    if (personalizationAttributes.address_id?.value) {
        return [
            addApiCall({ name: 'deals' }),
            fetchDeals({ caller: 'initialStep', address_id: personalizationAttributes.address_id.value })
        ];
    } else {
        return [updateStep({ step: 'address' })];
    }
    
}

export const selectBroadbandDealById = createSelector(

    selectSubscriptionById,
    (subscription: BroadbandSwitchObject | undefined): BroadbandDeal | null => {
        if (!subscription || !subscription.deals) {
            return null;
        }

        return subscription.deals.find(deal => deal.id === subscription.selected_deal_id) || null;
    }
);



@Injectable()
export class BroadbandEffects {
    constructor(
        private actions$: Actions,
        private api: ProductApiService,
        private product: ProductService,
        private store: Store<{ braoadband_switch: BroadbandState }>,
        private attribute_store: Store<{ user_attributes: UserAttributesState }>
    ) { }

    lastestAddressId = '';

    updateDealsOnAddressChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setNewAttributes),
            withLatestFrom(
                this.store.select(selectBroadbandState),
            ),
            filter(([action, state]) => {
                return action.newAttributes.some(a => a.key == 'address_id') && state?.current_sub_id != null
            }),
            mergeMap(([action, state]) => {
                return of(fetchDeals(
                    {caller: 'addressChange', address_id: action.newAttributes.find(a=>a.key == 'address_id').value}
                ), addApiCall({name:'deals'}));
            })
        )
    );

    initialStateStep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addSubscription),
            withLatestFrom(
                this.store.select(selectBroadbandState),
                this.attribute_store.select(state => state.user_attributes.user_attributes)
            ),
            filter(([action, state, personalizationAttributes]) => state.view_mode == 'standalone' || state.step == ''),
            filter(([action, state, personalizationAttributes]) => state.models[action.subscription_id].deals == null),
            filter(([action, state, personalizationAttributes]) => {
                return state.current_sub_id != null;
            }),
            concatMap(([action, state, personalizationAttributes]) => initialStepSelection(state, personalizationAttributes)),
            catchError((e) => {
                console.log(e);
                return of(addGeneralErrorToast())
            }) // Handle errors appropriately
        )
    );

    broadbandAttributeUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setNewAttributes),
            withLatestFrom(
                this.store.select(selectBroadbandState),
                this.attribute_store.select(state => state.user_attributes.user_attributes)
            ),
            mergeMap(([action, state]) => {
                if ( state && state.current_sub_id &&
                        (action.newAttributes.find(a => a.key.includes('bb_'))) ) {
                    return of(
                        fetchDeals({'caller':'broadbandAttributeUpdate', 'address_id': this.lastestAddressId}), 
                        addApiCall({ name: 'deals' })
                    );
                }
                return of(noop());
            })
        )
    );

    getDealsStep$ = createEffect(() =>
        //create an error component
        this.actions$.pipe(
            ofType(fetchDeals),
            withLatestFrom(this.store.pipe(select(selectBroadbandState))),
            mergeMap(([action, state]) =>
                this.api.optimizeBroadband({ ...state.models[state.current_sub_id], selected_address_id: action.address_id }).pipe(
                    tap(ret => this.lastestAddressId = action.address_id),
                    concatMap(ret => [
                        updateModel({ name: action.type, model: ret }),
                        removeApiCall({ name: 'deals' }),
                        updateStep({ step: 'deals' }),
                    ]),
                    catchError((e) => { console.log(e); return of(updateStep({ step: 'error' })) })
                )
            )
        )
    );

    getDealDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchDetails),
            withLatestFrom(this.store.pipe(select(selectBroadbandState))),
            mergeMap(([action, state]) =>
                this.api.optimizeBroadbandGetDealDetails(state.models[state.current_sub_id]).pipe(
                    map(ret => updateDealDetails({ deal_details: ret.deal_details })),
                    catchError(() => of(addGeneralErrorToast())) // Handle errors appropriately
                )
            )
        )
    );

    submitOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(submitOrder),
            withLatestFrom(this.store.pipe(select(selectBroadbandState))),
            mergeMap(([action, state]) =>
                this.api.optimizeBroadbandSubmitOrder(state.models[state.current_sub_id]).pipe(
                    concatMap((ret) => {
                        let actions = [
                            updateModel({ name: action.type, model: ret }),
                            removeApiCall({ name: 'broadband-order' }),
                            postCheckout({ model: ret }),
                            updateStep({ step: ret.order_result === 'success' ? 'post_checkout' : 'error' })
                        ];
    
                        if (state.view_mode !== 'standalone') {
                            actions.pop();
                        }
    
                        return actions;
                    }),
                    catchError((error) => {
                        const errorActions = [
                            setOrderErrors({ errors: error.details }), 
                            updateStep({ step: 'order-error' }) 
                        ];
                        return of(...errorActions);
                    })
                )
            )
        )
    );
    
    postCheckoutRefresh$ = createEffect(() =>
        this.actions$.pipe(
            ofType(postCheckout),
            withLatestFrom(this.store.pipe(select(selectBroadbandState))),
            mergeMap(([action, state]) =>
                this.product.refreshCurrentSubscription(false).pipe(
                    map(() => noop()),
                    catchError(() => of(noop())) // Handle errors appropriately
                )
            )
        )
    );

    fetchFilterModel$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchFilterModel),
            withLatestFrom(
                this.store.select(selectBroadbandState),
            ),
            filter(([action, state]) => !state?.advanced_search || !state?.advanced_search.deals),
            tap(([action, state]) => {
                if (!state?.is_loading_more) {
                  this.store.dispatch(addApiCall({ name: 'fetchingAdvancedSearchDeals' }));
                }
            }),
            tap(([action, state]) => {
                localStorage.setItem('paylow.advanced.search.' + state.current_sub_id, JSON.stringify(state.current_filters));
              }),
            mergeMap(([action, state]) =>
                this.api.optimizeBroadbandAdvancedSearch(state.current_filters, state.current_sub_id, action.page_num?.toString()).pipe(
                    concatMap(filterModel => [
                        setAdvancedSearchModel({ advanced_search: filterModel }),
                    ]),
                    catchError(() => of(updateStep({ step: 'error' }))),
                    finalize(() => {
                        this.store.dispatch(removeApiCall({ name: 'fetchingAdvancedSearchDeals' }));
                        this.store.dispatch(setLoadMore({mode: false}))
                    })
                )
            )
        )
    );

}