import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BRANDING_MAP } from './brands.data';
import { environment } from 'src/environments/environment';
import chroma from 'chroma-js';

@Injectable()
export class BrandingService {
  private brandName: string;
  private brandingMap = BRANDING_MAP;

  constructor(
    private route: ActivatedRoute
  ) {
    this.initialize();
  }

  initialize(brandFromPath?: string) {
    if (brandFromPath) {
      this.brandName = this.getBrand(brandFromPath);
    } else {
      this.brandName = environment.branding;
      // console.log('brandName', this.brandName);
      
      if (!this.brandName) {
        const subdomain = window.location.hostname.split('.')[0];
        this.brandName = this.getBrand(subdomain);
      }
    }
    if (!this.brandName) {
      this.brandName = 'no_brand';
    }
  }

  public updateBrand(customization : any) {
    this.brandName = 'dynamic_brand';
    this.brandingMap[this.brandName] = {
      ...this.brandingMap[this.brandName] || {},
      ...customization
    };
  }

  private getBrand(subdomain: string): string {
    const knownSubdomains = BRANDING_MAP ? Object.keys(BRANDING_MAP) : [];
    if (knownSubdomains.includes(subdomain.toLowerCase())) {
      return subdomain.toLowerCase();
    }
    return null;
  }

  public getBrandName(): string {
    return this.brandName;
  }

  getBrandingProperty(location: string): string {
    const brandConfig = this.brandingMap[this.brandName];
    const sub_location = location.split('|');

    for (const prop of sub_location) {
      if (brandConfig && brandConfig[prop]) {
      return brandConfig[prop];
      }
    }
    
    return null;
  }

  getBrandingPropertyOfName(brand_name : string, location: string): string {
    const brandConfig = this.brandingMap[brand_name];
    const sub_location = location.split('|');

    for (const prop of sub_location) {
      if (brandConfig && brandConfig[prop]) {
      return brandConfig[prop];
      }
    }
    
    return null;
  }

  primaryToOrderColor(baseColor: string, order: string): string {
    const diffs = {
      'secondary': 13.290757621307414,
      'tertiary': 35.87321270358303,
    }
    const lch = chroma(baseColor).lch();
    const newLightness = Math.max(0, Math.min(100, lch[0] + diffs[order])); // Ensure the lightness stays within [0, 100]
    return chroma.lch(newLightness, lch[1], lch[2]).hex();
  }

  calcDiff() {
    const color1 = '#393778';
    const color2 = '#9492C7';

    // Calculate lightness difference in LCH color space
    const lightnessDiff = chroma(color2).lch()[0] - chroma(color1).lch()[0];
    console.log(lightnessDiff);
  }

  listenToCircletCustomization() {
    this.route.queryParams.subscribe(params => {
      if (params['circletPrimaryColor']) {
        const primary = params['circletPrimaryColor'];
        document.documentElement.style.setProperty('--paylow-primary-color', primary);
        document.documentElement.style.setProperty('--paylow-primary-text-color', primary);
        document.documentElement.style.setProperty('--paylow-secondary-color', this.primaryToOrderColor(primary, 'secondary'));
        document.documentElement.style.setProperty('--paylow-trinary-color', this.primaryToOrderColor(primary, 'tertiary'));
      }
      if (params['circletSecondaryColor']) {
        const secondary = params['circletSecondaryColor'];
        document.documentElement.style.setProperty('--paylow-secondary-color', secondary || 'var(--default-secondary-color)');
      }
    });
  }

}
