
const insightsData = {
      investments: [
        'InvestEngine charges just a 0.25% management fee, making them one of the cheapest digital wealth platforms.'
      ],
      telecom: [
        'When nearing the end of your current plan, switching plans is usually a cheaper option.',
        'In the past year, 24% of households changed at least one telecom service.'
      ],
      broadband: [
        'Fiber broadband is the most popular type of connection, accounting for more than 70% of all UK fixed broadband lines.',
        'Fiber broadband that provides lightning-fast internet is perfect for streaming and gaming without interruptions.',
        'The average internet speed in the UK is about 145 Mb/s (Ranked #45 in the world)',
        'People who have given up landlines, tend to find standalone broadband services better.',
      ],
      mobile: [
        'The rollout of 5G is rapid and it now accounts for almost 10% of all mobile traffic.',
        'eSIM technology could enable hassle-free switches between carriers without needing a physical SIM card.',
        'By choosing an unlimited data plan, you can prevent unexpected extra charges and enjoy worry-free.',
        'The price premium for 5G compared to 4G may be only a few pence per month',
        'Despite performance improvements, mobile prices tend to decrease a few percentage points each year.',
        'Enabling automatic updates on your apps could reduce your data usage by up to 10% compared to manual updates, keeping your costs lower and your apps up-to-date effortlessly.',

      ],
      mortgage: [
        'It is recommended to start looking around 3-6 months before your current mortgage rate ends, to avoid overpaying',
        'The most popular type of mortgage in the UK is likely a fixed-rate mortgage, especially for first-time home buyers.',
        'The longest mortgage term available in the UK is 40 years, while the average  mortgage is now 30 years.',
        'There are Green Mortgage providers in the UK, which offers lower interest rates for energy-efficient homes.'
      ],
      energy: [
        'The UK increased its renewable energy share from less than 10% to more than 40% in a decade',
        'People who use smart thermostats can enjoy signifcant savings on their energy bills, claimed at up to £300 per year!',
        'Energy usage peaks in the evening between 6-9pm.'
      ],
      'pet insurance' : [
        'Comprehensive pet health insurance isn\'t just for cats and dogs - it is available for a range of animals, from ferrets and exotic birds to reptiles and even potbelly pigs.',
        'Most vets treat only small animals every day, with just a quarter dealing with mixed or wild animals.'
      ],
      netflix: [
        'Netflix consumes about 15% of the global internet traffic.',
        'You can access hidden content in a wide variety of genres on Netflix by using secret codes.'
      ],
      donating: [
        'Focusing on a few organizations can enhance donors\' connection and impact, contributing to greater happiness and satisfaction with their giving.',
        'December remains the largest giving month of the year followed by June.',
        'December is the peak month for charitable giving, with a surge in generosity that can enhance your happiness and contribute to a "helper\'s high".'
      ],
      gym: [
        'Listening to music while exercising can improve workout performance by 15%.',
        'Exercising increases the production of cells that are responsible for learning and memory.',
        'Exercising regularly helps boost your immune system. This means you\'ll get sick less often than people who don\'t exercise.'
      ]
}


export default insightsData;