<div *ngIf="msgModel?.loading">
  <div class="loading-overlay"></div>

  <!-- <div class="loading-message w-100 text-center" id="overlay-loading"> 
    <span><i class="fa fa-spinner fa-spin "> </i> {{msgModel.loading_msg }}</span>
    </div> -->

  <div class="message d-flex flex-column text-center gap-24" id="loading-popup">

    <div class="d-flex flex-column">
      <span class="subtitle2-md-heading3">{{ msgModel.loading_msg }}</span>
      <span class="subtitle1-md-heading1" *ngIf="msgModel.loading_subtitle">{{ msgModel.loading_subtitle }} </span>
    </div>
    <div class="loader-gradient"></div>
  </div>

</div>

<div *ngIf="actionPopup && actionPopup.title">
  <div class="error-overlay"></div>
  <div class="message p-32 d-flex flex-column text-center gap-24" id="overlay-error" style="width: 650px">

    <div class="d-flex flex-column gap-16">

      <div class="d-flex flex-column gap-16">
        <span class="body2-md-heading3">{{ actionPopup.title }}</span>
        <span class="subtitle2" [innerHTML]="actionPopup.subtitle"></span>
      </div>

    </div>

    <div class="d-flex flex-column gap-8 justify-content-center w-100 align-items-center">
      <button class="btn w-100" style="max-width: 412px" [class.btn-dark]="!a.light" [class.btn-clear]="a.light"
        (click)="applyCallback(a)" *ngFor="let a of actionPopup.actions">
        <span>{{a.text}}</span>
      </button>
    </div>

  </div>
</div>


<div class="toast-container position-fixed bottom-0 end-0 p-3">

  <ngb-toast *ngFor="let t of (this.toasts | async)" [autohide]="false" [delay]="4500" (hidden)="this.hideToast(t)" class="toast-body">
    <ng-template ngbToastHeader>
      <div class="me-auto">

        <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.2902 9.08749C12.5332 9.08749 11.865 9.6112 11.6497 10.3735L11.2635 11.9123L10.7423 7.97728C10.3647 6.25548 9.35473 5.89498 8.57549 5.89498C7.79626 5.89498 6.78632 6.25727 6.40867 7.98087L5.76101 11.2308L3.40449 1.87565C3.2131 1.06855 2.52102 0.5 1.72811 0.5C0.595136 0.5 -0.231949 1.62634 0.058557 2.77601L3.46602 16.2868C3.92228 18.1144 4.84848 18.4982 5.54569 18.4982C6.20702 18.4982 7.381 18.2005 7.82359 16.2061L8.5772 12.5311L9.3479 16.2043C9.46068 16.7728 9.80587 18.5 11.6275 18.5C12.3128 18.5 13.2236 18.1162 13.6798 16.2904L14.941 11.3474C15.2315 10.2067 14.4129 9.08749 13.2885 9.08749H13.2902Z"
            fill="white" />
        </svg>

        <span class="body1" style="margin-left: 8px">
          <i class="fa fa-spinner fa-spin " *ngIf="t.toast_type == 'hanging'"> </i>
          {{t.title}}
        </span>
      </div>
    </ng-template>
    <span class="body1" >{{ t.text }}</span>
  </ngb-toast>

</div>