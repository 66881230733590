<div class="d-flex flex-column align-items-center gap-16">
    <button class="modal-close-btn" autofocus="false" (click)="_activeModal.dismiss()" appIsInsideModal>
        <img alt="close panel button" src="assets/icons/thick-x.svg">
    </button>

    <div class="d-flex flex-column gap-16 text-center p-16 pb-0">
        <span class="subtitle1">Book your meeting with an expert</span>
        <span class="body2-md-body1">Get personalized assistance from our dedicated experts, committed to helping you save money.</span>
    </div>

    <ng-container *ngIf="!demo_mode">
        <div id="calendly-div" class="h-100 d-flex flex-grow-1 w-100 p-16"></div>
    </ng-container>
</div>