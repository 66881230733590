
<div class="section-left d-none d-lg-flex" *ngIf="mode == 'standalone'">
    <button class="modal-close-btn" autofocus="false" (click)="_activeModal.dismiss()">
        <img alt="close panel button" id="close-modal" src="assets/icons/thick-x.svg">
    </button>
    
<div class="p-40 d-flex flex-column gap-32" >
        <div class="d-flex flex-row  align-items-center gap-16  ">
            <div class="bg-primary p-16 rounded text-white logo d-flex justify-content-center align-items-center">
                <i class="fa-solid fa-magnifying-glass fs-2"></i>
            </div>
            <div class="d-flex flex-column">
            <span class="body2-md-subtitle1 gradient-text">Interests</span>
            <span class="body2-md-subtitle2">Your perfect match </span>
        </div>

        </div>
        <div class="d-flex flex-column gap-8">
        <span class="body1-md-subtitle1"> Welcome to  <span class="body1-md-subtitle1 gradient-text">Interests</span>:  </span>
        <ul>
        <li>
             <span class="body1">We analyse your spending to reveal offers that align with your lifestyle.</span>
        </li>
        <li> 
            <span class="body1">Explore exclusive offers from our trusted partners, tailored to your habits.</span>
        </li>
        <li> 
            <span class="body1">Tags are based on your provided info. This may not be perfect, so we welcome your feedback!</span>
        </li>
        </ul>
        
        
    </div>
</div>

</div>

<div class="flex-grow-1 h-100 section-right p-md-40 overflow-auto">
    <div class="loader" [@FadeAnimation] *ngIf="this.stepper.is_hanging() && !this.stepper.is_hanging_type('deals') ">
        <i class="fa fa-spinner fa-spin"> </i>
    </div>

    <div *ngIf="this.stepper.is_hanging_type('deals')" [@FadeAnimation] style="height: calc(100% - 5px);">
        <div class="d-flex justify-content-center align-items-center" style="height: calc(100% - 5px);">
            <app-long-loader
                [show_animation]="true"
                [show_loader]="false"
                [texts_cycle]="[
                            'Scanning benefits...', 
                            'Personalising...']">
            </app-long-loader>
        </div>
    </div>
    
    <ng-container *ngIf="stepper.equals('select-deal')">
        <div [@FadeAnimation] class="d-flex h-100 flex-column gap-lg-16 gap-8">
            <div class="d-none d-lg-flex flex-column">
                <span class="subtitle1">
                    Get the best options for your interests  <span *ngIf="(dissmised_offers$ | async) != null" class="body1 pl-2 soft-link" id="see-all" (click)="this.changeDisplayMode()">{{(interests_display_mode$ | async) == 'active' ? 'See all' : 'See active'}}</span>
                </span>
                <span class="body1">
                    Save time and money by turning one-offs into cost-effective subscriptions
                </span>
            </div>
            
            <div class="d-lg-none d-flex flex-column gap-8">
                <button class="modal-close-btn" autofocus="false" (click)="_activeModal.dismiss()">
                    <img alt="close panel button" src="assets/icons/thick-x.svg">
                </button>
                <span class="subtitle1 text-center">  Welcome to  <span class="subtitle1 gradient-text">Interests</span>  </span>
                <span class="body1 text-center">
                    Get the best deals for your <b>Interests!</b> 
                </span>
                <span class="body2 text-center">
                    Save time and money by turning one-offs into cost-effective subscriptions
                </span>
                <hr class="mobile-layout" style="margin: 2px;">
            </div>                
            <span *ngIf="(dissmised_offers$ | async) != null" class="d-flex d-lg-none body1 pl-2 soft-link" id="see-all"
 style="padding-right: 15px;" 
            (click)="this.changeDisplayMode()">{{(interests_display_mode$ | async) == 'active' ? 'See all' : 'See active'}}</span>
            
            <app-interests-deals-list
            [displayDeals]="this.display_offers"
            [deals]="(deals$ | async)"
            (dealCheckout)="dealCheckout($event)" 
            [showFeedback]="this.request_feedback"
            (switchFeedback)="switchFeedback($event)"

            ></app-interests-deals-list>
        </div>

    </ng-container>


</div>
