

// Use "property|sub_property" for conditional or (take subproperty if available else take property)
// Example: 'logo|logo:footer' will take 'logo:footer' if available else 'logo'

export const BRANDING_MAP = {
  'no_brand': {
    'card:thumbnail': 'assets/icons/paylow-w.svg',
    'logo:topBar': 'assets/brand2/logo-topBar.png',
    'logo:footer': 'assets/brand2/logo-footer.png',
    'card:text': 'Discover',
  },

  'creation': {
    'logo': 'assets/brandings/creation/logo-light.svg',
    'logo:light': 'assets/brandings/creation/logo-light.svg',
    'logo:dark': 'assets/brandings/creation/logo-dark.svg',

    'logo:onboarding:light': 'assets/brandings/creation/logo-light.svg',
    'logo:onboarding:dark': 'assets/brandings/creation/logo-dark.svg',

    'logo:onboarding:width': 100,
    'logo:width': 100,
  },

  'tandem': {
    'logo': 'assets/brandings/tandem/logo-light.svg',
    'logo:light': 'assets/brandings/tandem/logo-light.svg',
    'logo:dark': 'assets/brandings/tandem/logo-dark.svg',

    'logo:onboarding:light': 'assets/brandings/tandem/logo-light.svg',
    'logo:onboarding:dark': 'assets/brandings/tandem/logo-dark.svg',

    'logo:onboarding:width': 28,
    'logo:width': 17,
  },

  'heka': {
    'card:thumbnail': 'assets/brandings/heka/thumbnail-white.svg',
    'card:color': '#114445',
    
    'card:paylow': 'assets/icons/paylow-w.svg',
    'card:paylow:color': '#393778',
    'card:thumbnail:width': 20,
    'card:text': 'Discover',
    'card:style': 'background: #114445; padding: 4px 5px; font-size: 15px;',

    'panel:thumbnail:width': 30,
    'panel:style': 'background: #114445; padding: 4px 5px;',
  },

  'paylow': {
    'paylow:logo': 'assets/icons/paylow-w-dark.png',
    'paylow:color': '#9492C7',

  },


  'acacium': {
    'logo': 'assets/brandings/acacium/logo-light.png',
    'logo:light': 'assets/brandings/acacium/logo-light.png',
    'logo:dark': 'assets/brandings/acacium/logo-dark.png',

    'logo:onboarding:light': 'assets/brandings/acacium/logo-light.png',
    'logo:onboarding:dark': 'assets/brandings/acacium/logo-dark.png',

    'logo:onboarding:width': 61,
    'logo:width': 61,
  },
  'ben': {
    'logo': 'assets/brandings/ben/logo-light.png',
    'logo:light': 'assets/brandings/ben/logo-light.png',
    'logo:dark': 'assets/brandings/ben/logo-dark.png',

    'logo:onboarding:light': 'assets/brandings/ben/logo-light.png',
    'logo:onboarding:dark': 'assets/brandings/ben/logo-dark.png',

    'logo:onboarding:width': 61,
    'logo:width': 61,
  },
  'barnett-waddingham': {
    'logo': 'assets/brandings/barnett-waddingham/logo-light.png',
    'logo:light': 'assets/brandings/barnett-waddingham/logo-light.png',
    'logo:dark': 'assets/brandings/barnett-waddingham/logo-dark.png',

    'logo:onboarding:light': 'assets/brandings/barnett-waddingham/logo-light.png',
    'logo:onboarding:dark': 'assets/brandings/barnett-waddingham/logo-dark.png',

    'logo:onboarding:width': 70,
    'logo:width': 70,
  },

  'aslan': {
    'logo': 'assets/brandings/aslan/logo-light.png',
    'logo:light': 'assets/brandings/aslan/logo-light.png',
    'logo:dark': 'assets/brandings/aslan/logo-dark.png',

    'logo:onboarding:light': 'assets/brandings/aslan/logo-light.png',
    'logo:onboarding:dark': 'assets/brandings/aslan/logo-dark.png',

    'logo:onboarding:width': 51,
    'logo:width': 51,
  },

};