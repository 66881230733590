<div class="d-flex flex-column flex-lg-row content w-100 d-flex">
  <button class="close-btn" autofocus="false" (click)="_activeModal.dismiss()" appIsInsideModal>
    <img alt="close panel button" src="assets/icons/thick-x.svg">
  </button>

  <div class="profile-modal-tabs">

    <div class="d-none d-lg-flex profile-modal-title">
      <span class="paylow-dark-purple-text" id="personalisation-active">Personalisation</span>
    </div>

    <div
      class="d-flex d-none d-lg-flex flex-lg-column gap-lg-0 justify-content-between justify-content-lg-center w-100">

      <div *ngFor="let tab of this.tabsNames;" class="profile-modal-tab">
        <div (click)="changeTab(tab)" class="py-16 d-flex flex-column flex-lg-row justify-content-between">
          <span class="name paylow-dark-purple-text" [class.bold]="tab === currentTab"
            [id]="'tab-'+ (tab | idSanitize)">{{tab}}</span>

        </div>
      </div>
    </div>

    <nav class="nav d-flex d-lg-none nav-pills nav-fill flex-row gap-lg-8 gap-1  px-8" >
      <a appPointerFeedback class="nav-link d-flex align-items-center justify-content-center" [class.active]="currentTab == tab"
        *ngFor="let tab of tabsNames" [id]="'tab-'+tab" (click)="changeTab(tab)">
        {{ tab }}
      </a>
    </nav>

  </div>

  <div class="cards d-flex flex-column w-100" #cards>

    <div class="d-lg-flex d-none flex-column gap-8">
      <span class="d-flex d-none d-lg-flex subtitle3-md-subtitle2">{{currentTab}}</span>
      <span class="body2-md-body1">Supercharge the offers you get by providing richer context to Paylow's engine.</span>
      <hr>
    </div>

    <div class="text-card d-flex flex-column"
      *ngIf="(is_mobile_missing && (currentTab == 'Mobile' || currentTab == 'Mobile (Handset)')) || (is_broadband_missing && currentTab == 'Broadband')">
      <span class="body1">Your <b>{{currentTab}}</b> subscription is missing, please
        <a class="link-text add-bank" (click)="openBankModal()" target="_blank">
          <span class="body1 clickable">connect another bank.</span>
        </a>
      </span>
    </div>

    <div class="d-flex flex-column tabs-content" style="overflow-y: auto">



      <ng-container *ngIf="currentTab === 'General'">
        <app-personalization-general></app-personalization-general>
      </ng-container>

      <ng-container *ngIf="currentTab === 'Mobile (Sim)'">
        <app-personalization-mobile></app-personalization-mobile>
      </ng-container>

      <ng-container *ngIf="currentTab === 'Broadband'">
        <app-personalization-broadband style="width: 100%; gap: 16px;"></app-personalization-broadband>
      </ng-container>

      <ng-container *ngIf="currentTab === 'Mobile (Handset)'">
        <app-mobile-handset style="width: 100%; gap: 16px;"></app-mobile-handset>
      </ng-container>

      <ng-container *ngIf="currentTab === 'Energy'">
        <app-personalisation-energy style="width: 100%; gap: 16px;"></app-personalisation-energy>
      </ng-container>
    </div>


    <div class="d-none d-lg-flex w-100 justify-content-end pt-2 align-items-center gap-8">

      <button class="btn btn-primary" id="personalization-done" (click)="onDone()">Done</button>
    </div>

  </div>
</div>