import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Stepper } from 'src/app/helpers/stepper';
import { ProductApiService } from 'src/app/services/product-api/product-api.service';
import { ProductService } from 'src/app/services/product-service/product-service.service';
import { ProductState, updateSubscription, updateSubscription2 } from '../../../state/product-state.state';
import { Store } from '@ngrx/store';
import { FadeAnimation } from 'src/app/animations/simple_animations';

@Component({
  selector: 'app-change-category-modal',
  templateUrl: './change-category-modal.component.html',
  styleUrls: ['./change-category-modal.component.scss'],
  animations: [FadeAnimation]

})
export class ChangeCategoryModalComponent implements OnInit {

  category_value: string = "";
  input_category_value: string = "";
  hanging: boolean = false;
  checkingValidation: boolean = false;
  stepper: Stepper
  @Output() categoryChanged = new EventEmitter<string>();
  current_category: string;
  category_tabs: string[];

  intialIsBundle: boolean;
  newIsBundle: boolean;


  categories = ['Music', 'Tax', 'Pet Insurance', 'Mortgage', 'Donations', 'Health', 
    'Literature', 'Pets', 'Other', 'Software', 'Personal Care', 'Transport', 'Gaming', 
    'Education', 'Streaming', 'Broadband', 'Gambling', 'VPN', 'Fitness', 
    'Water', 'Children', 'Energy', 'Financials', 'Services', 'Food & Beverage', 'Investments', 
    'Insurance', 'Mobile', 'Leisure', 'Wellness', 'Household', 
    'Pension', 'Retail', 'Appliances']

  constructor(
    public _activeModal: NgbActiveModal,
    private _product_api: ProductApiService,
    private _activated_route: ActivatedRoute,
    private _product: ProductService,
    protected store: Store<{ product: ProductState }>,
    
    


  ) { }



  ngOnInit(): void {
    this.stepper = new Stepper();
    this.stepper.setStep('submit');

    const subscription = this._product.getSelectedSubscriptionValue();

    if (subscription) {
      this.current_category = subscription.category.name;
    }

    this.categories = this.categories.sort();
    this.category_value = this.categories.find(c => c == this.current_category);
    this.categories.push('Other');
    this.intialIsBundle = this.current_category == 'Telecom (bundle)';
    this.newIsBundle = this.current_category == 'Telecom (bundle)';

    this.category_tabs = [this.current_category];
  }

  public changeCategory() {
    this.hanging = true;
    if (this.category_value == 'Other') {
      this.category_value = this.input_category_value;
    }
    let change_to = this.category_value;
    const pointer = this._activated_route.snapshot.queryParamMap.get('sm_pointer');

    if (this.newIsBundle && this.newIsBundle != this.intialIsBundle ) {
      change_to = 'Telecom (bundle)';
    }

    if(this.newIsBundle && this.intialIsBundle) {
      this._activeModal.close();
      return
    }


    const s = this._product.getSelectedSubscriptionValue();


    if (s) {
      this._product.updateCategory(s.id, change_to).subscribe(
        r => {
          this._product.refreshCurrentSubscription(true).subscribe(r => {
            this._activeModal.close();
            this.categoryChanged.emit(change_to);
          });
        }
      )
    }
  }

  public showBundleOption() {
    return ['Mobile', 'Broadband', 'Telecom', 'Telecom (bundle)'].includes(this.current_category);
  }

  public removeCategory(category: string) {
    this.category_tabs = this.category_tabs.filter(item => item !== category);
  }
}
