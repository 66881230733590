
<div class="d-flex flex-column align-items-center gap-16">
    <button class="modal-close-btn" autofocus="false" (click)="_activeModal.dismiss()" appIsInsideModal> >
        <img alt="close panel button" src="assets/icons/thick-x.svg">
      </button>

      <div class="d-flex flex-column gap-16 p-32" *ngIf="this.stepper.equals('submit')" [@FadeAnimation]>           
        <div class="d-flex flex-column">
          <span class="subtitle3-md-subtitle1 paylow-dark-purple-text">Please select the new category:</span>
        </div>
        <div class="d-flex flex-column gap-16 align-items-center">
          <select class="p-8 select-scroll" id="category" [(ngModel)]="this.category_value" style="width: 120px;" [disabled]="newIsBundle">
            <option *ngFor="let c of this.categories; let i = index" [value]="c">{{c}}</option>
          </select>

          <div *ngIf="category_value == 'Other'">
            <input type="text" class="input-category" placeholder="Category" [(ngModel)]="input_category_value">
          </div>
          
          <div class="d-flex align-items-center" *ngIf="showBundleOption()">
            <input type="checkbox" id="isBundle" [(ngModel)]="newIsBundle">
            <label for="isBundle" class="ms-2">This is a bundle</label>
          </div>
          
          <button id="btn-submit-category" class="btn purple-badge" [disabled]="!newIsBundle && !category_value" (click)="changeCategory()">
            <i class="fa fa-spinner fa-spin" *ngIf="hanging"></i> {{ !hanging ? 'Submit' : '' }}
          </button>
        </div>
      </div>
      
      
    
    <div class="d-flex flex-column align-items-center gap-16 p-32" *ngIf="this.stepper.equals('post-submit')" [@FadeAnimation]>
        <div class="d-flex flex-column align-items-center text-center">
            <i class="bi bi-check-circle icon-badge " ></i>
            <span class="subtitle1 paylow-dark-purple-text">Your request has been submitted.</span>
        </div>
    </div>

</div>
