<div class="loading-connection" [@FadeAnimation]>
    <div class="loading-overlay"></div>
    <button class="modal-close-btn" autofocus="false" (click)="closeModal()">
      <img alt="close panel button"  src="assets/icons/thick-x.svg">
    </button>
    
    <div class="loading-message d-flex flex-column gap-16 align-items-center text-center" id="overlay-loading">
  
      <div class="d-flex gap-8">
  
        <div class="connection-item">
          <img src="assets/icons/paylow-square.svg" alt="paylow logo">
          <span class="body3-md-body2">Paylow</span>
        </div>
  
        <div class="d-flex flex-column"><img class="mt-4" src="assets/icons/connecting-dots-left-to-right.svg"
            alt="connecting"></div>
  
        <div class="connection-item" [class.beat]="true">
          <img [src]="this.connectingIcon ? 'assets/product/lock.svg' : 'assets/product/lock.svg'">
        </div>
  
        <div class="d-flex flex-column"><img class="mt-4" src="assets/icons/connecting-dots-right-to-left.svg"
            alt="connecting"></div>
  
        <div class="connection-item" >
          <img  *ngIf="category != 'benefits'" src={{logo_url}} style="border-radius: 8px;">
          <i *ngIf="category == 'benefits'" [ngClass]="logo_url + ' fs-3' || 'bi-hand-thumbs-up-fill' + 'fs-3'" class="benefit-logo"></i>
          <span class="body3-md-body2">{{name}}</span>
        </div>

      </div>

      <span class="subtitle2" *ngIf="this.connectingIcon">Establishing connection... </span>
  
      <span class="body1" *ngIf="name == 'InvestEngine'">
        <b>Earn up to £50</b> to kickstart your portfolio when you sign up and invest £100 with InvestEngine.
      </span>

      <span class="body1" *ngIf="category == 'benefits' && this.amount > 0" >
        Save up to <b>£{{this.amount}}</b> by claiming: <b>{{this.name}}</b>.
      </span>

      <span class="body1" *ngIf="category == 'interests' && this.amount > 0" >
        You’ll be redirected to claim your <b>{{this.name}}</b> subscription at <b>£{{this.amount}}/m.</b>
      </span>

      <span class="body1" *ngIf="category == 'broadband'" >
        We’re redirecting you to checkout on <b>{{this.name}}</b>, you might need to re-enter your postcode.
      </span>

      <div class="loader-gradient"  *ngIf="this.connectingIcon"></div>

      <button class="btn btn-primary" id="submit-rediraction" *ngIf="!this.connectingIcon" (click)="redirect()">
        <div class="d-flex gap-8 align-items-center">
          <span class="body1">Continue</span>
          <i class="fa-solid fa-angle-right"></i>


        </div>
      </button>
      <span class="body3" *ngIf="name == 'InvestEngine'">
        <i>Terms & Conditions Apply. Capital at Risk.</i>
      </span>
    </div>

  </div>
