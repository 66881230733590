

<div class="d-flex flex-column align-items-center gap-16 p-32">
    <button class="modal-close-btn" autofocus="false" (click)="_activeModal.dismiss()" appIsInsideModal>
        <img alt="close panel button" src="assets/icons/thick-x.svg">
      </button>
      
    <div class="d-flex flex-column align-items-center text-center">
        <span class="subtitle1">We'd love to get your feedback!</span>
        <span class="body1">(Product’s accuracy, satisfaction or any thoughts)</span>
    </div>
    
    <textarea rows="3" class="form-control input-group w-100" [(ngModel)]="feedback_value"></textarea>
    <button class="mt-3 btn confirm-btn" (click)="confirm()" [disabled]="!feedback_value">
        <i class="fa fa-spinner fa-spin " *ngIf="hanging"> </i> Confirm
    </button>
</div>
