import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, createReducer, on, props, Store } from '@ngrx/store';
import { filter, map, tap, withLatestFrom } from 'rxjs';
import { AppConfig } from 'src/app/services/app_config/app-config.service';
import produce from 'immer';
import { BrandingService } from 'src/app/services/branding/branding.service';

export const updateAppConfig = createAction('[Session] updateAppConfig', props<{ appConfig : AppConfig }>());
export const noOp = createAction('[Session] noOp');
export const updateModuleInitialized = createAction('[Session] updateModuleInitialized', props<{ module : string }>());
export const announceSessionIntialized = createAction('[Session] announceSessionIntialized');

export const updateAppCustomizations = createAction('[Session] updateAppCustomizations', props<{ appCustomizations : any }>());

export interface AppCustomizations {
    customization_present_mode : string;
    customization_callback_url : string;
    customization_callback_text: string;
    customization_logo_url: string;
}

export interface SessionState {
    appConfig : AppConfig;
    appCustomizations: AppCustomizations;
    modulesInitialized : {
        auth : boolean;
    }
}


export const initialState: SessionState = {
    appConfig : null,
    appCustomizations: {
      customization_present_mode : null,
      customization_callback_url : null,
      customization_logo_url: null,
      customization_callback_text: null,
    },
    modulesInitialized : {
        auth : false,
    }
};


export const SessionReducer = createReducer(
  initialState,
  on(updateAppConfig, (state, { appConfig }) => produce(state, draft => {
    draft.appConfig = appConfig;
  })),
  on(updateModuleInitialized, (state, { module }) => produce(state, draft => {
    if (module === 'auth') {
      draft.modulesInitialized.auth = true;
    }
  })),

  on(updateAppCustomizations, (state, { appCustomizations }) => produce(state, draft => {
    draft.appCustomizations = {
      ...draft.appCustomizations,
      ...appCustomizations
    };
  })),
);

@Injectable()
export class SessionEffects {
  constructor(
    private actions$: Actions,
    private brandingService: BrandingService,
    private store: Store<{ session: SessionState }>
  ) { }

  onModuleInitialized$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateModuleInitialized),
      withLatestFrom(this.store.select(state => state.session)),
      tap(([action, state]) => console.log('Session State:', state)),
      filter(([action, state]) => state.modulesInitialized.auth),
      map(() => announceSessionIntialized())
    )
  );

  onAppCustomizationsChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAppCustomizations),
      filter(action => {
        const triggerAttributes = ['customization_logo_url', 'customization_callback_text'];
        return triggerAttributes.some(attr => action.appCustomizations[attr] !== null);
      }),
      tap(action => {
        let brandingMapping = {};

        if (action.appCustomizations.customization_logo_url) {
            brandingMapping['logo'] = decodeURIComponent(action.appCustomizations.customization_logo_url);
        }
        if (action.appCustomizations.customization_callback_text) {
          brandingMapping['callback:text'] = decodeURIComponent(action.appCustomizations.customization_callback_text);
        }

        this.brandingService.updateBrand(brandingMapping);
      }),
      map((action) => noOp())
    )
  );
}

