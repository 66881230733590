import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, createAction, createReducer, on, props } from '@ngrx/store';
import { produce } from 'immer';
import { Observable, catchError, concatMap, filter, finalize, map, mergeMap, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { ProductApiService } from 'src/app/services/product-api/product-api.service';
import { PanelState, ProductService } from 'src/app/services/product-service/product-service.service';
import { CustomizationFile, Dashboard, DashboardOverview, DashboardStateModel, ManualSubscription, PaylowSubscription, Provider, UserDetails } from 'src/app/types';
import { UserDetailModel } from 'src/app/types/product-auth-models.model';
import { updateJourneyObject } from '../../global/journey/state';
import { ActionPopup, MessagePopup } from '../common/msg-popup/msg-popup.component';
import { FeedState, setNotifications } from '../dashboard/action-center/state/state';
import { fetchEstimation } from '../dashboard/subscription-panel/switch-panel-benefits/state';
import { overrideBenefitsSubscriptions } from './environment.reducers';
import { BaseAuthService } from 'src/app/services/authentication_services/base-auth.service';
import { AUTH_SERVICE_TOKEN } from 'src/app/services/authentication_services/auth-token.di';
import { local_copy } from 'src/app/helpers/utils';

export const notLoggedIn = createAction('[Auth] notLoggedIn');
export const initilaizeDashboard = createAction('[Product] initilaizeDashboard');

export const updateMsgPopup = createAction('[Popup] updateMsgPopup', props<{ msg: MessagePopup }>());
export const updateActionPopup = createAction('[Popup] updateActionPopup', props<{ msg: ActionPopup }>());
export const updateSubCategories = createAction('[product] updateSubCategories', props<{ category: string }>());
export const setSubCategory = createAction('[product] setSubCategory', props<{ category: string }>());

export const api_call = createAction('[Product] Add api call', props<{ status, api_name }>());
export const setInitialLoadComplete = createAction('[Product] setInitialLoadComplete');
export const resetInitialLoadComplete = createAction('[Product] resetInitialLoadComplete');

export const addToast = createAction('[Toast] addToast', props<{ title: string, text: string, toast_type: string }>());
export const addGeneralErrorToast = createAction('[Toast] addGeneralErrorToast');

export const removeToast = createAction('[Toast] removeToast', props<{ id: number }>());

export const setDashboard = createAction('[Product] setDashboard', props<{ name, dashboard: Dashboard }>());
export const setNewBankIds = createAction('[Product] setNewBankIds', props<{ bank_ids: string[] }>());

export const updateSubscription = createAction('[Product] updateSubscription', props<{ subscription: PaylowSubscription }>());
export const toggleEditMode = createAction('[Product] toggle Edit Mode', props<{ name }>());
export const updateHiddenSubscriptions = createAction('[Product] Update Hidden Subscriptions', props<{ hiddenSubIds: string[] }>());
export const setCostumization = createAction('[Product] setCostumization', props<{ CustomizationFile: CustomizationFile }>());
export const deleteManualSub = createAction('[Product] deleteManualSub', props<{ subscription_id: string }>());

export const fetchCustomization = createAction('[Product] fetchCustomization');
export const fetchDashboard = createAction('[Product] fetchDashboard');
export const updateCustomization = createAction('[Product] updateCustomization', props<{ sub_id: string, action: string, delete?: boolean }>());
export const fetchProviders = createAction('[Product] fetchProviders', props<{ category: string }>());
export const setCustomizationProviders = createAction('[Product] setCustomizationProviders', props<{ customizationProviders: Provider[] }>());
export const setCustomizedSubscription = createAction('[Product] setCustomizedSubscription', props<{ CustomizationFile: CustomizationFile }>());
export const updateCustomizationSubs = createAction('[Product] updateCustomizationSubs', props<{ manual_subscription: ManualSubscription }>());
export const setNewSubscription = createAction('[Product] Set New Subscription', props<{ subscription: PaylowSubscription }>());
export const updateSubCategoryTabs = createAction('[Product] updateCategoryTabs', props<{ subscription_id: string; category: string }>());
export const fetchCategoryTabs = createAction('[Product] fetchCategoryTabs', props<{ subscription_id: string; category: string }>());
export const updateSubscription2 = createAction('[Product] updateSubscription2', props<{ subscription_id: string; subscription_type: string }>());
export const setPanelState = createAction('[Product] setPanelState', props<{ panelState: PanelState }>());
export const setSelectedSubscription = createAction('[Product] setSelectedSubscription', props<{ subscription:  PaylowSubscription}>());
export const setMaxSavings = createAction('[Product] setMaxSavings', props<{ max_savings: number }>());
export const showSwitchModal = createAction('[Product] showSwitchModal', props<{ show: boolean }>());
export const setIsJourneyCompleted = createAction('[Product] setIsJourneyCompleted', props<{ completed: boolean }>());

export const fetchJourneyState = createAction('[Product] fetchJourneyState', props<{ is_initial? : boolean }>());
export const updateJourneyState = createAction('[Product] updateJourneyState', props<{ updated: DashboardStateModel }>());
export const setJourneyState = createAction('[Product] setJourneyState', props<{ journey_state: DashboardStateModel, is_initial? : boolean }>());


export const refreshPanelState = createAction('[Product] refreshPanelState');
export const setUserDetailsModel = createAction('[Product] setUserDetailsModel', props<{ model: UserDetailModel }>());
export const setUserName = createAction('[Product] setUserName', props<{ name: string }>());


export const fetchBrands = createAction('[Product] fetchBrands');
export const setBrands = createAction('[Product] setBrands', props<{ handsetBrands: Provider[] }>());

export const setEnvironment = createAction('[Product] setEnvironment', props<{ subdomain: string }>());
export const fetchUserDetails = createAction('[Product] fetchUserDetails');

// user details

export const fetchAccountDetails = createAction('[Product] fetchAccountDetails');
export const setBankDetails = createAction(
  '[User] setBankDetails',
  props<{ userDetails: UserDetails }>()
);


export interface Environement {
  subdomain: string;
  benefits_provider: string;
}

export interface DashboardState {
  dashboard: Dashboard;
  dashboard_overview: DashboardOverview;
  display_subscriptions: PaylowSubscription[];
  customizations: CustomizationFile;
  dashboard_mode: string;
  customization_providers: Provider[];
  category_tabs: Record<string, string[]>; // maps subscription_id to list of categories
  is_bundle: boolean;
  handset_brands: Provider[];
}

export interface ProductState {
  environment: Environement;
  toasts: { title: string, text: string, toast_type: string, id?: number }[];
  initial_load_complete: boolean;
  active_api_calls: string[];
  msgPopup: MessagePopup;
  actionPopup: ActionPopup;
  dashboard: DashboardState;
  journey_state: DashboardStateModel;
  feed: FeedState;
  panel: PanelStateObject;
  user_details: UserDetailModel;
  bank_details: UserDetails;
}

export interface PanelStateObject {
  state: PanelState;
  selected_subscription: PaylowSubscription;
  current_max_savings: number;
  show_switch_modal: boolean;
  is_journey_completed: boolean;
}

export const initialState: ProductState = {
  environment: null,
  msgPopup: null,
  actionPopup: null,
  initial_load_complete: false,

  active_api_calls: [],

  toasts: [
  ],
  dashboard: {
    dashboard: {
      subscriptions: [],
      new_bank_ids: [],
      total_monthly_payments: 0,
    },
    dashboard_overview: {
      dashboard_exists: false,
      total_monthly_pay: 0,
      subs_monthly_percentage: 0,
      optimization_count: 0,
    },
    display_subscriptions: null,
    customizations: {
      hidden_sub_ids: [],
      filename: '',
      manual_subs: [],
    },
    dashboard_mode: '',
    customization_providers: [],
    category_tabs: {},
    is_bundle: false,
    handset_brands: []
  },
  journey_state: null,
  feed: null,
  panel: { state: 'default',
    selected_subscription: null,
    current_max_savings: null,
    show_switch_modal: false,
    is_journey_completed: false
   },
  user_details: null,
  bank_details: {
    consents: null
  }
};

const good_list = ['improvable-auto', 'category-ambiguity', 'improvable-bundle'];
const telcom = ['Mobile', 'Broadband'];

export const productReducer = createReducer(
  initialState,
  on(updateMsgPopup, (state, { msg }) => ({ ...state, msgPopup: msg })),
  on(updateActionPopup, (state, { msg }) => ({ ...state, actionPopup: msg })),

  on(addToast, (state, { title, text }) => ({
    ...state,
    toasts: state.toasts.some(toast => toast.title === title && toast.text === text)
      ? state.toasts
      : [...state.toasts, { title, text, id: state.toasts.length + 1, toast_type: 'general' }]
  })),
  on(addGeneralErrorToast, (state, { }) => ({
    ...state, toasts: state.toasts.some(toast => toast.title === "Something didn\'t work" && toast.text === "We will invetigate this, please try again later.")
      ? state.toasts
      : [...state.toasts, { title: "Something didn\'t work", text: "We will invetigate this, please try again later.", toast_type: 'general', id: state.toasts.length + 1 }]
  })),

  on(removeToast, (state, { id }) => ({ ...state, toasts: state.toasts.filter(t => t.id !== id) })),

  on(toggleEditMode, (state, { name }) => {
    return produce(state, draft => {
      if (draft.dashboard.dashboard_mode === 'default') {
        draft.dashboard.dashboard_mode = 'edit';
        draft.dashboard.display_subscriptions = draft.dashboard.dashboard.subscriptions;
      } else if (draft.dashboard.dashboard_mode === 'edit') {
        draft.dashboard.dashboard_mode = 'default';
        draft.dashboard.display_subscriptions = draft.dashboard.dashboard.subscriptions.filter(s => !draft.dashboard.customizations.hidden_sub_ids.includes(s.id));
      } else {
        draft.dashboard.dashboard_mode = 'default';
        draft.dashboard.display_subscriptions = draft.dashboard.dashboard.subscriptions.filter(s => !draft.dashboard.customizations.hidden_sub_ids.includes(s.id));
      }
    });
  }),
  on(setDashboard, (state, { dashboard }) => {
    return produce(state, draft => {
      draft.dashboard.dashboard = { ...dashboard };
      draft.dashboard.dashboard.subscriptions = overrideBenefitsSubscriptions(state, dashboard);
      draft.dashboard.dashboard_mode = 'default';
      draft.dashboard.display_subscriptions = draft.dashboard.dashboard.subscriptions.filter(s => !draft.dashboard.customizations.hidden_sub_ids.includes(s.id));
      draft.dashboard.dashboard_overview.have_telecom_ambiguity = dashboard.subscriptions.filter(subscription => subscription.category.name === 'Telecom').length > 0 &&
        telcom.filter(category => dashboard.subscriptions.some(subscription => subscription.category.name === category)).length != 1;
      // draft.dashboard.dashboard.total_monthly_payments = dashboard?.total_monthly_payments;
      calcOverview(draft.dashboard)
    });
  }),
  
  on(updateSubscription, (state, { subscription }) => {
    return produce(state, draft => {
      const index = draft.dashboard.dashboard.subscriptions.findIndex(s => s.id === subscription.id);
      draft.dashboard.dashboard.subscriptions[index] = subscription;
      draft.dashboard.display_subscriptions = draft.dashboard.dashboard.subscriptions.filter(s => !draft.dashboard.customizations.hidden_sub_ids.includes(s.id));
      calcOverview(draft.dashboard)
    });
  }),
  on(updateHiddenSubscriptions, (state, { hiddenSubIds }) => {
    return produce(state, draft => {
      draft.dashboard.customizations.hidden_sub_ids = hiddenSubIds;
      calcOverview(draft.dashboard)
    });
  }),

  on(setCostumization, (state, { CustomizationFile }) => {
    return produce(state, draft => {
      draft.dashboard.customizations = CustomizationFile;
      calcOverview(draft.dashboard)
    });
  }),

  on(deleteManualSub, (state, { subscription_id }) => {
    return produce(state, draft => {
      draft.dashboard.dashboard.subscriptions = draft.dashboard.dashboard.subscriptions.filter(
        sub => sub.id !== subscription_id
      );
      draft.dashboard.display_subscriptions = draft.dashboard.display_subscriptions.filter(
        sub => sub.id !== subscription_id
      );
      calcOverview(draft.dashboard)
    });
  }),
  

  on(api_call, (state, { status, api_name }) => {
    return produce(state, draft => {
      if (status == 'remove') {
        draft.active_api_calls = draft.active_api_calls.filter(a => a != api_name);
      } else if (status == 'add') {
        draft.active_api_calls.push(api_name);
      }
    });
  }),
  on(setInitialLoadComplete, (state) => {
    return produce(state, draft => {
      draft.initial_load_complete = true;
    });
  }),
  on(resetInitialLoadComplete, (state) => {
    return produce(state, draft => {
      draft.initial_load_complete = false;
    });
  }),
  on(setCustomizationProviders, (state, { customizationProviders }) => {
    return produce(state, draft => {
      draft.dashboard.customization_providers = [...customizationProviders].sort();
    });
  }),

  on(setNewSubscription, (state, { subscription }) => {
    return produce(state, draft => {
      draft.dashboard.customizations.manual_subs?.push({
        category: subscription.category.name,
        provider: subscription.name,
        amount: subscription.activePlan?.price,
        subscription_id: subscription.id,
      }) 
      
      
      draft.dashboard.dashboard.subscriptions.unshift(subscription);
      draft.dashboard.display_subscriptions = draft.dashboard.dashboard.subscriptions;
      draft.dashboard.dashboard.total_monthly_payments += subscription.activePlan?.price
      draft.dashboard.dashboard_overview.have_telecom_ambiguity = draft.dashboard.dashboard.subscriptions.filter(subscription => subscription.category.name === 'Telecom').length > 0 &&
        telcom.filter(category => draft.dashboard.dashboard.subscriptions.some(subscription => subscription.category.name === category)).length != 1;
      calcOverview(draft.dashboard)
    });
  }),
  on(updateSubscription2, (state, { subscription_id, subscription_type }) => {
    return produce(state, draft => {
      const targetSubscription = draft.dashboard.dashboard.subscriptions.find(sub => sub.id === subscription_id);
      if (targetSubscription) {
        if (subscription_type == 'category-ambiguity') {
          targetSubscription.status.type = 'category-ambiguity';
        }
        else if (subscription_type == 'improvable-bundle') {
          targetSubscription.status.type = 'improvable-bundle';
        }
      }
    });
  }),
  on(setBrands, (state, { handsetBrands }) => {
    return produce(state, draft => {
      draft.dashboard.handset_brands = handsetBrands;
    });
  }),
  on(setEnvironment, (state, { subdomain }) => {
    return produce(state, draft => {
      draft.environment = { subdomain, benefits_provider: subdomain == 'heka' ? subdomain : '' };
    });
  }),
  on(setPanelState, (state, { panelState }) => {
    return produce(state, draft => {
      draft.panel.state = panelState
    });
  }),

  on(setMaxSavings, (state, { max_savings }) => {
    return produce(state, draft => {
      draft.panel.current_max_savings = max_savings
    });
  }),

  on(showSwitchModal, (state, { show }) => {
    return produce(state, draft => {
      draft.panel.show_switch_modal = show
    });
  }),

  on(setSelectedSubscription, (state, { subscription }) => {
    return produce(state, draft => {
      draft.panel.selected_subscription = subscription
    });
  }),

  on(setIsJourneyCompleted, (state, { completed }) => {
    return produce(state, draft => {
      draft.panel.is_journey_completed = completed
    });
  }),
  
  on(refreshPanelState, (state) => {
    return produce(state, draft => {
      draft.panel.state = 'default';
    });
  }),
  on(setUserDetailsModel, (state, { model }) => {
    return produce(state, draft => {
      draft.user_details = model
    });
  }),
  on(setUserName, (state, { name }) => {
    return produce(state, draft => {
      draft.user_details.name = name
    });
  }),
  on(setJourneyState, (state, { journey_state }) => {
    return produce(state, draft => {
      draft.journey_state = journey_state;
    });
  }),
  on(setBankDetails, (state, { userDetails }) => ({
    ...state,
    bank_details: userDetails,
  })),
  on(setNewBankIds, (state, { bank_ids }) => {
    return produce(state, draft => {
      draft.dashboard.dashboard.new_bank_ids = bank_ids;
    });
  }),
  on(setNewBankIds, (state, { bank_ids }) => {
    return produce(state, draft => {
      draft.dashboard.dashboard.new_bank_ids = bank_ids;
    });
  }),
);

function calcOverview(dashboard: DashboardState) {
  dashboard.dashboard_overview.total_monthly_pay = dashboard.dashboard.subscriptions.filter(item => !dashboard.customizations.hidden_sub_ids.includes(item.id)).map(item => item.activePlan?.price).filter(item => !isNaN(item)).reduce((a, b) => a + b, 0);
  dashboard.dashboard_overview.optimization_count = dashboard.dashboard.subscriptions.filter(subscription => good_list.includes(subscription.status.type)).length;

  dashboard.dashboard_overview.missing_optimizations = good_list.filter(status => !dashboard.dashboard.subscriptions.some(subscription => subscription.status.type === status));
  dashboard.dashboard_overview.available_optimizations = dashboard.dashboard.subscriptions.filter(subscription => good_list.includes(subscription.status.type)).map(subscription => subscription.category.name);
  dashboard.dashboard_overview.subs_monthly_percentage = dashboard.dashboard.total_monthly_payments ? Math.round((dashboard.dashboard_overview.total_monthly_pay / dashboard.dashboard.total_monthly_payments) * 100) : undefined;
}

@Injectable()
export class ProductEffects {
  constructor(
    private actions$: Actions,
    private api: ProductApiService,
    private product: ProductService,
    private router: Router,
    private activated_route: ActivatedRoute,
    private store: Store<{ product: ProductState }>,
    private feed_store: Store<{ feed: FeedState }>,
    @Inject(AUTH_SERVICE_TOKEN) private authService: BaseAuthService,
  ) { }

  fetchDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchDashboard),
      tap(a => this.store.dispatch(updateMsgPopup({ msg: {loading: true, loading_msg: 'Processing your subscriptions...'} }))),
      mergeMap(() => this.api.getDashboard()
        .pipe(
          map(res => setDashboard({ name:'fetchDashboard', dashboard: res })),
          catchError(err => {
            const actionPopup: ActionPopup = {
              title: 'Oh no!',
              subtitle: err.error_type === "FileMissingException" ? 'There seems to be no transaction to analyse,<br> please link another bank account.' :
                        err.error_type === "InsufficientUserInformation" ? 'We couldn\'t find any subscription in your account,<br> please link another bank account.' : '',
              actions: [{
                text: 'Link another bank',
                light: false,
                callback: () => this.router.navigate(['../', 'consent'], { relativeTo: this.activated_route, queryParams: { step: 'bank-selection' }, queryParamsHandling: 'merge' })
              }]
            };
            this.store.dispatch(updateMsgPopup({ msg: {loading: false, loading_msg: null} }));
            return of(updateActionPopup({ msg: actionPopup }));
          }),
        ))
    ));
  


  fetchBrands$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchBrands),
      withLatestFrom(this.store.select(state => state.product.dashboard.handset_brands)),
      filter(([action, state]) => !state || !state.length),
      tap(a => this.store.dispatch(api_call({ status: 'add', api_name: `fetchingBrands` }))),
      concatMap((action) =>
        this.api.getMobileBrands().pipe(
          map(brands => setBrands({ handsetBrands: brands })),
          catchError(() => of(addGeneralErrorToast())),
          finalize(() => {
            this.store.dispatch(api_call({ status: 'remove', api_name: `fetchingBrands` }));
          })
        )
      )
    )
  );

  fetchJourneyState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchJourneyState),
      tap(a => this.store.dispatch(api_call({ status: 'add', api_name: `fetchJourneyState` }))),
      concatMap((action) =>
        this.api.getJourneyState().pipe(
          map(ret => setJourneyState({ journey_state: ret, is_initial: action.is_initial })),
          catchError(() => of(addGeneralErrorToast())),
          finalize(() => {
            this.store.dispatch(api_call({ status: 'remove', api_name: `fetchJourneyState` }));
          })
        )
      )
    )
  );

  updateJourneyState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateJourneyState),
      withLatestFrom(this.store.select(state => state.product.journey_state)),
      tap(a => this.store.dispatch(api_call({ status: 'add', api_name: `updateJourneyState` }))),
      map(([action, state]) => {
        const updated = local_copy(action.updated);
        Object.keys(updated).forEach(key => {
          if (!updated[key].timestamp) {
            updated[key].timestamp = new Date().toISOString();
          }
        });
        return [updated, state];
      }),
      concatMap(([updated, state]) =>
        this.api.updateDashboardState({ ...state,  ...updated }).pipe(
          map(ret => setJourneyState({ journey_state: ret })),
          // catchError(() => of(addGeneralErrorToast())),
          finalize(() => {
            this.store.dispatch(api_call({ status: 'remove', api_name: `updateJourneyState` }));
          })
        )
      )
    )
  );

  initialLoadDone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDashboard, setNotifications, setJourneyState),
      withLatestFrom(
        this.store.select(state => state.product),
        this.feed_store.select(state => state.feed)
      ),
      filter(([action, product_state, feed_state]) => !product_state.initial_load_complete),
      filter(([action, product_state, feed_state]) => (
        product_state.dashboard.display_subscriptions != null &&
        feed_state.all_notifications != null &&
        product_state.journey_state != null
      )),
      tap(() => this.product.msgModel.loading = false),
      concatMap(() => [
        updateMsgPopup({ msg: {loading: false, loading_msg: null} }),
        setInitialLoadComplete(),
        updateJourneyObject({ updated_state: { dashboard_exists: true } }),
      ]
      )
    )
  );

  fetchUserDetails$ = createEffect(() => 
      this.actions$.pipe(
        ofType(fetchUserDetails),
        switchMap(() =>
          this.api.userAuthDetails().pipe(
            map((response: any) => setUserDetailsModel({model: response})),
            catchError((error) => {
              console.error('Error fetching user details', error);
              return of({type: 'NoOp'});
            })
          )
        )
      )
    );

  notLoggedIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notLoggedIn),
      tap((action) => this.authService.signOut()),
      map((action) => addToast({ title: 'Login session expired', text: 'Please login again to access your dashboard', toast_type: 'general' }))
    ));

  fetchAccountDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAccountDetails), // Trigger the effect when fetchUserDetails action is dispatched
      switchMap(() =>
        this.api.getAccountInfo().pipe(
          map((response: any) => {
            // Assuming the API response contains the accounts in a 'consents' property
            const userDetails: UserDetails = { consents: response.accounts };
            return setBankDetails({ userDetails });  // Dispatch setUserDetails action
          }),
          catchError((error) => {
            console.error('Error fetching user details', error);
            return of();  // Dispatch failure action if there's an error
          })
        )
      )
    )
  );



}

