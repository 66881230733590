<div class="d-flex flex-column py-3 px-16 py-lg-24 px-lg-64 w-100 h-100 gap-8 gap-lg-16" style="min-height: 65vh; overflow-y: auto;">

    <button class="modal-close-btn" autofocus="false" (click)="_activeModal.dismiss()">
        <img alt="close panel button" src="assets/icons/thick-x.svg">
      </button>
      
  <span class="subtitle2-md-heading1 w-100">Settings</span>

  <nav class="nav nav-pills nav-fill flex-row gap-8">
    <a class="nav-link" [class.active]="selectedTab == i" href="javascript:void(0);" *ngFor="let i of tabNames"
      (click)="onTabClick(i)" > {{ i }}</a>
  </nav>

  <div class="flex-grow-1">
    <ng-container [ngSwitch]="selectedTab">
      <ng-container *ngSwitchCase="'My Account'">
        <app-my-account></app-my-account>
      </ng-container>
    </ng-container>

    <ng-container [ngSwitch]="selectedTab">
      <ng-container *ngSwitchCase="'Contact Us'">
        <app-contact-us></app-contact-us>
      </ng-container>
    </ng-container>

    <ng-container [ngSwitch]="selectedTab">
      <ng-container *ngSwitchCase="'Logout'">
        <app-logout></app-logout>
      </ng-container>
    </ng-container>

  </div>



</div>