import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FadeAnimation } from 'src/app/animations/simple_animations';
import { Stepper } from 'src/app/helpers/stepper';
import { ProductApiService } from 'src/app/services/product-api/product-api.service';
import { ProductService } from 'src/app/services/product-service/product-service.service';

@Component({
  selector: 'app-update-category-modal',
  templateUrl: './update-category-modal.component.html',
  styleUrls: ['./update-category-modal.component.scss'],
  animations: [FadeAnimation]
})
export class UpdateCategoryModalComponent implements OnInit {
  category_value: string = "";
  input_category_value: string = "";
  hanging: boolean = false;
  checkingValidation: boolean = false;
  stepper : Stepper
  @Output() categoryChanged = new EventEmitter<string>();
  current_category: string;
  category_tabs: string[];

  categories = ['Music', 'Tax', 'Pet Insurance', 'Mortgage', 'Donations', 'Health', 
    'Literature', 'Pets', 'Other', 'Software', 'Personal Care', 'Transport', 'Gaming', 
    'Education', 'Streaming', 'Broadband', 'Gambling', 'VPN', 'Fitness', 
    'Water', 'Children', 'Energy', 'Financials', 'Services', 'Food & Beverage', 'Investments', 
    'Insurance', 'Mobile', 'Leisure', 'Wellness', 'Household', 
    'Pension', 'Retail', 'Appliances']

  constructor(
    public _activeModal: NgbActiveModal,
    private _product_api : ProductApiService,
    private _activated_route : ActivatedRoute,
    private _product : ProductService
  ) { }

  ngOnInit(): void {
    this.stepper = new Stepper();
    this.stepper.setStep('submit');
    this.categories = this.categories.filter(c => c != this._product.getSelectedSubscriptionValue().category.name).sort()
    this.categories.push('Other')
    this.category_value = this.categories[0];
    this.current_category = this._activated_route.snapshot.queryParamMap.get('category');
    this.category_tabs = [this.current_category]
  }

  public changeCategory() {
    this.hanging = true;

    const pointer = this._activated_route.snapshot.queryParamMap.get('sm_pointer');

    const s = this._product.getSelectedSubscriptionValue();
    if (s) {
      this._product.updateCategory(s.id, this.category_value).subscribe(
        r => {
          this._product.refreshCurrentSubscription(true).subscribe( r=> {
            this._activeModal.close(); 
            this.categoryChanged.emit(this.category_value);
          });
        }
      )
    }


  }

  public removeCategory(category: string) {
    this.category_tabs = this.category_tabs.filter(item => item !== category);
  }

}
