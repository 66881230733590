import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpClient, HttpErrorResponse } from "@angular/common/http";
import {Observable, catchError, map, mergeMap, of, retryWhen, switchMap, take, tap, throwError, timer} from "rxjs";
import {ApiServiceParams, HttpMethod} from "../../models/HttpRequest";
import { BaseAuthService } from '../authentication_services/base-auth.service';
import { AUTH_SERVICE_TOKEN } from '../authentication_services/auth-token.di';

export const AUTH_API_TOKEN = new InjectionToken<AuthApiService>('AuthApiService');


@Injectable()
export class AuthApiService {

  private maxRetryAttempts = 3;
  private initialRetryDelay = 1000;
  private exponentialBackoffFactor = 1.3;

  constructor(
    private _httpClient: HttpClient,
    @Inject(AUTH_SERVICE_TOKEN) private authService: BaseAuthService,
  ) {}

  public sendRequest<T>(method: HttpMethod, url: string, options?: ApiServiceParams): Observable<T> { 
    return this.authService.getUser().pipe(
      take(1),
      switchMap(cognitoUser => {
        console.log('Calling', method, url, options);        
        const jwtToken = cognitoUser.getSignInUserSession()?.getIdToken()?.getJwtToken();
        const headers = {
          'Authorization': jwtToken,
          'Content-Type': 'application/json',
          ...options?.headers
        };
        const obs = this._httpClient.request<T>(method, url, {
          ...options,
          headers: {...headers}
        }) as unknown as Observable<T>;
    
        return this._backoff_retries(obs.pipe(tap(console.log)));
      }),
      catchError(error => {
        if (error == "The user is not authenticated") {
          return of({'statusCode': -1, 'error': 'NotLoggedIn'});
        } else if (error.statusCode == 401) {
          throw error;
        } else {
          throw error;
        }
      }
      )
    );
  }

  public _nonAuthSendRequest<T>(method: HttpMethod, url: string, options?: ApiServiceParams): Observable<T> {
    console.log('Calling', url, options);

    const headers = {
      'Authorization': '',
      'Content-Type': 'application/json',
      ...options?.headers
    };
    return this._httpClient.request<T>(method, url, { ...options, headers: {...headers} }) as unknown as Observable<T>;
  }

  public httpPut(url, body) {
    return this._httpClient.put<any>(url, body) as any;
  } 

  private _backoff_retries(obs: Observable<any>): Observable<any> {
    return obs.pipe(
      map(this._cast_response),
      catchError(error => {
        console.error('_backoff_retries caught', error);
        if (error.status == 401) {
          throw {
            statusCode: 401,
            message: 'Unauthorized',
          }
        }
        if (!error.statusCode) {
          error = {
            statusCode: 0, // A default value for non-http errors
            message: error.message || 'Non-HTTP error',
          };
        }
        throw error;
      }),
      retryWhen(errors =>
        errors.pipe(
          mergeMap((error, retryAttempt: number) => {
            if (retryAttempt < this.maxRetryAttempts && this._should_retry(error.statusCode)) {
              const delay = this._calculateRetryDelay(retryAttempt);
              console.log(`retry ${retryAttempt} in ${delay}ms`);
              return timer(delay);
            } else {
              throw error;
            }
          })
        )
      )
    );
  }

private _cast_response(res: any) {
  const allowed_codes = [200, 420, 421, 401];
  if (res.statusCode && allowed_codes.includes(res.statusCode) ) {
    return res;
  } else {
    throw res;
  }
}

private _should_retry(error_code: number): boolean {
  // Retry on all error codes including 0 for non-http errors like network issues, CORS, timeout
  const allowed_codes = [420];
  return !allowed_codes.includes(error_code);
}

  private _calculateRetryDelay(retryAttempt: number): number {
    return this.initialRetryDelay * Math.pow(this.exponentialBackoffFactor, retryAttempt);
  }
}
