<div class="d-flex flex-column gap-16 color-primary p-16">

    <span class="body1-md-subtitle1 text-center">
        
        Do you know someone who would love using Paylow? Invite them!

    </span>

    <div >
        <form [formGroup]="referForm" class="d-flex flex-column gap-16 px-24">
            <div class="form-group">
                <label for="name" class="body">Name</label>
                <input type="text" class="form-control" id="name" formControlName="name" placeholder="Enter friend's name" name="name">
            </div>
            <div class="form-group">
                <label for="email">Email</label>
                <input type="email" class="form-control" id="email" formControlName="email" placeholder="Enter friend's email" name="email">
            </div>
        </form>
    </div>

    <div class="w-100 d-flex flex-column justify-content-end gap-8 align-items-center">
        <span class="ml-auto mb-0">{{this.allowedReferals}} invites left</span>
        <button type="button" class="btn btn-primary" [disabled]="this.referForm.invalid || this.allowedReferals == 0" (click)="onSubmit()">
            <i class="bi bi-envelope-open-heart" *ngIf="!hanging"></i>
            <i class="fa fa-spinner fa-spin spinner-icon" *ngIf="hanging"></i> 
            {{ hanging ? '' : 'Invite' }}
        </button>
        <span class="body1 text-success" *ngIf="sent">Invite sent</span>
    </div>

    
</div>