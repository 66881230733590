import { Injectable } from '@angular/core';
import { AuthApiService } from '../api-service/auth-api.service';
import { filter, map, Observable, of, tap } from 'rxjs';
import { AccountDetailsResponse, NewConsentModel, UnlistedBankModel } from 'src/app/types';
import { ProductApiService } from '../product-api/product-api.service';
import { CommonBankProvider, mapMHBankProviderToCommon, mapTLBankProviderToCommon, MHBankProvider, TLBankProvider } from 'src/app/types/product-consent.model';
import { Store } from '@ngrx/store';
import { addToast, fetchUserDetails } from 'src/app/components/product/state/product-state.state';

const banksOrder = ['HSBC', 'NatWest', 'Halifax', 'Barclays', 'Santander', 'Lloyds', 'Revolut', 'American Express', 'Chase', 'Starling', 'Monzo', 'TSB', 'First Direct', 'Bank of Scotland', 'Barclaycard', 'Ulster'];


@Injectable()
export class BaseConsentService {
  private readonly _ep_consent_new = 'consent/new';
  private readonly _ep_consent_fetch = 'consent/fetch';
  private readonly _ep_mh_consent_new = 'mh/consent/new';
  private readonly _ep_mh_consent_deep_new = 'mh/consent/deep/new';
  private readonly _ep_mh_consent_fetch = 'mh/consent/deep/complete';
  private readonly _ep_mh_consent_fetch_offline = 'mh/consent/deep/offline/complete';
  private readonly _tl_providers_full_url = 'https://auth.truelayer.com/api/providers';
  private readonly _mh_providers_full_url = 'https://identity.moneyhub.co.uk/oidc/.well-known/all-connections';
  private readonly _tl_providers_full_url_mock = 'assets/mock/tl_providers.json';
  private readonly _ep_event_unlisted_bank = 'event/unlisted-bank';

  public ob_provider: "tl" | "mh" = "mh";
  public link_type: "deep" | "basic" = "deep";

  mh_remove_bank_ids = [
    '825bb99b9ed8f9348292617630119156',
    'd1dbcd5e6efa731ba62660f0a066a177',
    'b61cc5042651ea41a9a301b11051804f',
    '6c2c6bcafe02ef87cf070fdcdecbb0e1'
  ]

  mh_remove_paraents = [
    'newday', 'johnLewis'
  ]

  offline_consent_complete_status: string = null;
  offline_consent_complete_sub: Observable<any> = null;

  constructor(
    private product_api: ProductApiService,
    private _apiService: AuthApiService,
    protected store: Store<{}>
  ) { }

  public bankConnection: string = null;
  public selectedProvider: CommonBankProvider = null;
  public consentModel: NewConsentModel;
  public consentFromUnauth: boolean = false;

  public getBankProviders(): Observable<CommonBankProvider[]> {
    if (this.ob_provider == 'tl') {
      return this._apiService._nonAuthSendRequest<TLBankProvider[]>('GET', 'assets/mock/tl_providers.json', {}).pipe(
        map(providers => providers.filter(p => p.country == 'uk')),
        map(providers => providers.map(p => mapTLBankProviderToCommon(p)))
      );
    } else if (this.ob_provider == 'mh') {
      return this._apiService._nonAuthSendRequest<MHBankProvider[]>('GET', this._mh_providers_full_url, {}).pipe(
        map(providers => {
          return providers.filter(p => {
            return p.country == 'GB' && p.accountTypes.some(a => ['cash', 'card'].includes(a.name)) && p.userTypes.includes('personal') &&
              !this.mh_remove_bank_ids.includes(p.id) && !this.mh_remove_paraents.includes(p.parentRef);
          }).map(p => mapMHBankProviderToCommon(p)).sort((a, b) => {
            const indexA = banksOrder.indexOf(a.displayName);
            const indexB = banksOrder.indexOf(b.displayName);
            return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
          });
        })
      )
    } else {
      return null;
    }
  }

  public initiateNewConsentFlow(provider?: CommonBankProvider): Observable<NewConsentModel> {
    if (this.link_type == 'deep') {
      const consent_obs = this.product_api._post<NewConsentModel>(this._ep_mh_consent_deep_new, { bank_provider_id: provider.providerId });
      return consent_obs;
    } else if (this.ob_provider == 'mh') {
      const consent_obs = this.product_api._post<NewConsentModel>(this._ep_mh_consent_new, { bank_provider_id: provider.providerId });
      return consent_obs;
    }
    return null;
  }

  public storeNewConsentFlow(consentModel: NewConsentModel, unauth: boolean = false) {
    this.consentModel = consentModel;
    this.consentFromUnauth = unauth;
  }

  public endNewConsentFlow(consentModel: NewConsentModel): Observable<any> {
    const result = this.product_api._post<boolean>(this._ep_mh_consent_fetch, consentModel).pipe(
      tap(res => {
        this.store.dispatch(fetchUserDetails());
      })
    )
    return result;
  }

  public endNewConsentFlowSessionLost() {
    this.offline_consent_complete_status = 'fetch';
    this.offline_consent_complete_sub = this.product_api._unauth_post<boolean>(this._ep_mh_consent_fetch_offline, this.consentModel).pipe(map(res => {
      this.offline_consent_complete_status = 'done';
      this.offline_consent_complete_sub = null;
    }));

    this.offline_consent_complete_sub.subscribe({
      error: err=> {
        this.store.dispatch(addToast({toast_type: 'general', title: 'Bank link failed', text: 'Something went wrong. Please try again.'}));
      }
    });
  }

  public getOfflineConsentCompletePipe() {
    if (this.offline_consent_complete_status === 'fetch') {
      return this.offline_consent_complete_sub.pipe(
        map(() => true)
      );
    }
    else if (this.offline_consent_complete_status === 'done') {
      return of(true);
    } else if (this.offline_consent_complete_status === null) {
      return of(false);
    }

    return of(false);
  }

  public notifyUnlistedBank(unlistedBankModel: UnlistedBankModel): Observable<any> {
    return this.product_api._post<any>(this._ep_event_unlisted_bank, unlistedBankModel);
  }

  public selectBankProvider(provider: CommonBankProvider) {
    this.selectedProvider = provider;
    this.bankConnection = 'connecting';

    let link_sub = this.initiateNewConsentFlow(provider).subscribe(
      res => {
        this.bankConnection = 'connected';

        setTimeout(() => {
          this.bankConnection = 'done';
          this.handleConsentLinkRedirect(res.ob_link);
          this.bankConnection = null;
        }, 2000);
      }
    )
  }

  public handleConsentLinkRedirect(url: string): void {
    window.location.href = url;
  }


}
