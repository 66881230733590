
      <div class="d-flex flex-column gap-16 gap-hsm-8">
  
        <div class="d-flex flex-column gap-32-lg gap-8 flex-grow-1">

          <div class="d-flex d-lg-none flex-column gap-8 gap-hsm-4">
            <span class="body2-md-body1">Supercharge the offers you get by providing richer context to Paylow's engine.</span>
            <hr>
          </div>
          <div class="d-flex w-100 flex-column gap-16 gap-hsm-8">
            
            <div class="d-flex flex-column w-100 gap-16 gap-hsm-8" [formGroup]="postcodeForm">
              <div class="form-group">

                <label for="postcode-input" class="info-item-label mb-1 paylow-dark-purple-text d-flex flex-row gap-1"> Property 
                  <div class="spinner-loader" *ngIf="this.is_hanging"  style="line-height: 23px">
                    <i class="fa fa-spinner fa-spin fs-6"> </i>
                </div>
                </label> 
                <div class="input-group" (blur)="_formNameFocused = false;">
                  <input type="text" (focus)="_formNameFocused = true;" (input)="null" placeholder="Postcode"
                    class="form-control editable-onclick" formControlName="postcode" id="postcode-input" (input)="onPostcodeEnter($event)">
                </div>

                <div class="input-group mt-8" (blur)="_formNameFocused = false;">
                  <div class="select-loading-overlay"
                        *ngIf="this.postcode.invalid || (!this.postcode.invalid && !this.addresses)">
                    </div>
                    <select id="property-info-address" formControlName="address" class="form-select editable-onclick" (change)="submitPostcodeForm()">
                      <!-- Placeholder option -->
                      <option value="" disabled>{{ addresses?.length ? 'Select address' : 'Loading addresses...' }}</option>
                      
                      <!-- Loop through available addresses -->
                      <option *ngFor="let p of addresses" [value]="p.id">{{ p.display_name }}</option>
                    </select>
                    
                </div>

              </div>
  
            </div>
            <div class="d-flex justify-content-center map-container position-relative" *ngIf="map_loading">
              <!-- <img src="" class="clickable w-100 h-100 position-absolute" style="margin: auto"> -->
              <iframe width="350" height="350" style="border:0" loading="lazy" [src]="map_url"></iframe>
            </div>
  
            <div class="d-flex justify-content-center">
              <span style="margin-right: 3px">Powered by </span>
              <img src="assets/external/ofcom_logo.png" class="ofcom_img"/>
            </div>
  
          </div>
        </div>
  
  
      </div>

      <hr style="margin-top: 15px; margin-bottom: 15px;" />
  