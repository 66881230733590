import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FadeAnimation } from 'src/app/animations/simple_animations';
import { BaseConsentService } from 'src/app/services/consent/base-consent.service';
import { CONSENT_SERVICE_TOKEN } from 'src/app/services/consent/consent-token.di';
import { ProductService } from 'src/app/services/product-service/product-service.service';
import { ProductTrackerService } from 'src/app/services/product-tracker/product-tracker.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-redirect-modal',
  templateUrl: './redirect-modal.component.html',
  styleUrls: ['./redirect-modal.component.scss'],
  animations: [FadeAnimation] 
})
export class RedirectModalComponent implements OnInit {
  connectingIcon: boolean = true;
  @Input() logo_url: string
  @Input() name: string
  @Input() amount?: number
  @Input() url?: string
  @Input() category?: string

  constructor(    
    @Inject(CONSENT_SERVICE_TOKEN) public _consentService: BaseConsentService,

    public _activeModal: NgbActiveModal,
    public _product: ProductService,
    public _activated_route: ActivatedRoute,
    private product_tracker : ProductTrackerService,

  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.connectingIcon = false;
    }, 3000);
    
  }

  redirect() {
    // if (this._product.isFunctionDisabled('improveable-invest')) {
    //   this._activeModal.close();
    //   return;
    // }
    // this.product_tracker.setTag("switch.mobile.order", {order_stauts : (feedback ? 'success' : 'fail')});

    this.product_tracker.setTag('switch.invest.click',);
    window.open(this.url, '_blank');
    this._activeModal.close();
  }

  closeModal() {
    this._activeModal.close();
  }

}
