import { Injectable, } from '@angular/core';
import { BankDetails, Dashboard, DashboardOverview, Invoice, PaylowSubscription, PersonalizationModel, SubscriptionStatus, UserDetails } from 'src/app/types';
import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged, filter, first, map, of, shareReplay, switchMap, take, tap, throwError } from 'rxjs';
import { ProductApiService } from '../product-api/product-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ProductTrackerService } from '../product-tracker/product-tracker.service';
import { ContractLockDetails, UserAttribute, UserAttributesUpdateRequest } from 'src/app/types/beta-optimize-models.model';
import { ModalService } from '../modal-service/modal-service.service';
import { MsgPopuopModel, ActionPopupModel } from 'src/app/components/product/common/msg-popup/msg-popup.component';
import { ProductState, setPanelState, updateSubscription } from 'src/app/components/product/state/product-state.state';
import { Store } from '@ngrx/store';
import { local_copy } from 'src/app/helpers/utils';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { UserAttributesState, addBatchUpdate, fetchAttributes, fetchBatchUpdate, fetchUpdateAttributes, setNewAttributes } from 'src/app/components/product/common/modals/personalization-modal/state';
import { JourneyState, updateJourneyObject } from 'src/app/components/global/journey/state';

export type PanelState = 'default' | 'optimize_1' | 'optimize/energy' | 'optimize/mobile' | 'optimize/mortgage' | 'cancel' |
                         'optimize/broadband' | 'optimize/bundle' | 'optimize/benefits' | 'optimize/alternatives';
export interface ProductConfiguration {
  target: string,
  partner_theme: string,
  disabled_functions: string[]
}

@Injectable()
export class ProductService {
  public environement = '';
  
  private initialLoad = false;
  private dashboard$ = new BehaviorSubject<Dashboard>(null);
  public msgModel: MsgPopuopModel;
  public actionModel: ActionPopupModel;
  // public dashboard_overview : DashboardOverview;
  private subscriptions$ = new BehaviorSubject<PaylowSubscription[]>(null);
  private selectedSubscription$ = new BehaviorSubject<PaylowSubscription>(null);

  private subscriptionsEvents: BehaviorSubject<Map<string, string>> = new BehaviorSubject<Map<string, string>>(new Map());
  public subscriptionsEvents$ = this.subscriptionsEvents.asObservable();

  public demoMode$ = new BehaviorSubject<ProductConfiguration>({ target: 'paylow', partner_theme: 'paylow', disabled_functions: [] });
  public demoFeatures = new BehaviorSubject<string[]>([]);
  private dashboard_exists = null;

  private notifications: BehaviorSubject<{pointer:string, value:any}> = new BehaviorSubject<{pointer:string, value:any}>({ pointer: '', value: null });
  private user_details : UserDetails;
  private user_details$ : Observable<UserDetails>;

  public personalization_attributes = new BehaviorSubject<PersonalizationModel>(null);
  public personalization_indications = new BehaviorSubject<PersonalizationModel>(null);
  
  constructor(
    private _betaApi: ProductApiService,
    private route: ActivatedRoute, 
    private router: Router,
    private _modal_service: ModalService,
    private store: Store<{product: ProductState, journey:JourneyState}>,
    private attribute_store: Store<{user_attributes: UserAttributesState}>
  ) {
    const host = window.location.hostname;
    this.environement = host.split('.')[0];

    this.store.select(state => state.product.initial_load_complete).subscribe(r => {this.initialLoad = r;});

    combineLatest([
      this.route.queryParams,
      this.store.select(state=>state.product.dashboard.dashboard.subscriptions)
    ]).subscribe(([params, subscriptions]) => {
      if (!subscriptions) {
        this.selectedSubscription$.next(null);
        return;
      }
      if (!params['subscription']) {
        this.selectedSubscription$.next(null);
        return;
      }

      const s = subscriptions.filter(s => s.id == params['subscription']);
      if (s.length == 0) {
        this.selectedSubscription$.next(null);
      } else {
        if (s[0] != this.selectedSubscription$.value) {
          this.selectedSubscription$.next(s[0]);
        }
      }
    });

    this.init_demo_features();

    this.msgModel = new MsgPopuopModel();
    this.actionModel = new ActionPopupModel();
  }

  public init_demo_features() {
    this.setConfTarget(environment.demo_target);
    this.setConfExternalFunctionality(environment['disable_functions'] ?? []);

    const df = localStorage.getItem('demoFeatures') || '[]';
    this.demoFeatures.next(JSON.parse(df));    

    this.demoFeatures.subscribe( r => {
      localStorage.setItem('demoFeatures', JSON.stringify(r))
      
      this.setSubscriptions(this.subscriptions$.value);
    });
  }

  public setSubscriptions(item: PaylowSubscription[]) {
    if(item == null) {
      return;
    }
    
    this.dashboard_exists = item != null;

    let tranformed_items = item.map(s => {
      if(!s.category.name) {
        s.category.name = 'Unknown';
      }
      return s;
    });

    this.subscriptions$.next(tranformed_items);
  }

  public setDashboard(dashboard: Dashboard) {
    // this.dashboard_exists = true;
    // this.dashboard_overview = {
    //   dashboard_exists : true,
    // };

    const good_list = ['improvable-auto', 'category-ambiguity', 'improvable-bundle'];
    const telcom = ['Mobile', 'Broadband'];

    // this.dashboard_overview.have_telecom_ambiguity = dashboard.subscriptions.filter(subscription => subscription.category.name === 'Telecom').length > 0 &&
    //                                        telcom.filter(category => dashboard.subscriptions.some(subscription => subscription.category.name === category)).length != 1;


    // this.dashboard_overview.total_monthly_pay = dashboard.subscriptions.map(item => item.activePlan?.price).filter(item => !isNaN(item)).reduce((a, b) => a + b, 0);
    // this.dashboard_overview.optimization_count = dashboard.subscriptions.filter(subscription => good_list.includes(subscription.status.type)).length;
    // this.dashboard_overview.missing_optimizations = good_list.filter(status => !dashboard.subscriptions.some(subscription => subscription.category.name === status));    
    // this.dashboard_overview.available_optimizations = good_list.filter(status => dashboard.subscriptions.some(subscription => subscription.category.name === status));    

    this.dashboard$.next(dashboard);
  }

  public getSubscriptions() {
    return this.subscriptions$.asObservable();
  }

  public getSubscriptionById(id : string) {
    return this.subscriptions$.asObservable().pipe(take(1), filter(s=>!!s), map(s=>s.filter(s=>s.id == id)[0]));
  }
  
  public getDashboard() {
    return this.dashboard$.asObservable();
  }

  public getPanelState() {
    return  this.store.select(state => state.product.panel.state)
  }

  public setPanelState(panelState: PanelState) {
    this.store.dispatch(setPanelState({ panelState: panelState }));

  }

  public setSelectedSubscription(item: PaylowSubscription): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { subscription: item?.id || null },
      queryParamsHandling: 'merge',
    });
  }

  public setSelectedSubscriptionId(item_id: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { subscription: item_id || null },
      queryParamsHandling: 'merge',
    });
  }

  public getSelectedSubscription(take_many = false): Observable<PaylowSubscription> {
    const obs = this.selectedSubscription$.asObservable();
    if (take_many) { return obs; }
    return obs.pipe(take(1));
  }

  public getSelectedSubscriptionValue() {
    return this.selectedSubscription$.value;
  }

  public getSingleSelectedSubscription() {
    return this.selectedSubscription$.value;
  }

  public optimizeSubscription(): Observable<SubscriptionStatus> {
    let s = this.selectedSubscription$.value;
    return this._betaApi.optimizeSubscription(s.id).pipe(
      tap(res => {
        this.selectedSubscription$.value.status = res;
        this.setSelectedSubscription(this.selectedSubscription$.value);
      }
      )
    )
  }

  public contractLock(lock_model) : Observable<UserAttribute> {
    return this._betaApi.contractLockSubscription(this.selectedSubscription$.value.id, lock_model).pipe(
      tap(res => {
        this.store.dispatch(setNewAttributes({newAttributes: [res]}));
        this.personalization_attributes.next({...this.personalization_attributes.value, [res.key]: res});
      })
    )
  }

  public upvoteSubscription() {
    let s = this.selectedSubscription$.value;

    return this._betaApi.upvoteSubscription(s.id).pipe(
      tap(res => {
        const cs = local_copy(s);
        cs.status = res;
        this.updateSubscription(cs)
        this.setSelectedSubscription(cs);
      })
    )
  }

  public getNotifiedSubscription() {
    let s = this.selectedSubscription$.value;

    return this._betaApi.comingSoonSubscription(s.id).pipe(
      tap(res => {
        const cs = local_copy(s);
        cs.status = res;
        this.updateSubscription(cs)
        this.setSelectedSubscription(cs);
      })
    )
  }

  public refreshCurrentSubscription(should_refresh_panel = false) {
    let s = this.selectedSubscription$.value;

    return this._betaApi.getSubscription(s.id).pipe(
      
      tap(res => {
        const cs = local_copy(s);

        const refresh_panel = cs.status.type !== res.status.type;
        cs.status = res.status;
        this.updateSubscription(res);
        this.setSelectedSubscription(cs);
        if (refresh_panel || should_refresh_panel) {
          // this.setPanelState('default');
        }
      })
    )
  }



  public getLastEventObservable() {
    return this.subscriptionsEvents.asObservable().pipe(
      map(indices => {
        const items = Array.from(indices.entries());
        return items[items.length - 1];
      })
    )
  }

  public updateSubscription(subscription: PaylowSubscription) {
    const subs = this.subscriptions$.value;

    const index = this.subscriptions$.value.findIndex(s => s?.id === subscription.id);
    if (index !== -1) {
      subs[index] = subscription;
      this.subscriptions$.next(subs);
    }

    this.store.dispatch(updateSubscription({subscription: subscription}));
    this.dashboard$.next(
      {
        ...this.dashboard$.value, subscriptions: subs
      }
    );
  }

  public addSubscriptionEvent(subscription_index: string, event_name: string) {
    console.log(`${subscription_index} new evet`);

    const indices = this.subscriptionsEvents.value;
    indices.set(event_name, subscription_index);
    this.subscriptionsEvents.next(indices);
  }

  public setConfTarget(target: string) {
    const conf = this.demoMode$.value;
    conf.target = target;
    this.demoMode$.next(conf);
  }

  public setConfPartnerTheme(partner_theme:string) {
    console.log("setConfPartnerTheme", partner_theme);
    
    const conf = this.demoMode$.value;
    conf.partner_theme = partner_theme;
    this.demoMode$.next(conf);

    if (partner_theme != 'paylow') {
      const rmv = this.demoFeatures.value.filter((f) => !f.startsWith('user:'));
      this.demoFeatures.next([...rmv, `user:${partner_theme}-main`]);
    }
  }

  public setConfExternalFunctionality(disabled_functions: string[]) {
    const conf = this.demoMode$.value;
    conf.disabled_functions = disabled_functions;
    this.demoMode$.next(conf);
  }

  public isDashboardExists() {
    return this.store.select(state => state.journey.dashboard_exists).pipe(
      take(1),
      switchMap(r=>{
        if (r==null){
          return this._betaApi.isDashboardExists().pipe(
            tap(r => this.store.dispatch(updateJourneyObject({updated_state: {dashboard_exists: r}})))
          )
        } else {
          return of(r);
        }
      })
    );

  }

  public registerToNoftifications(pointer: string) {
    return this.notifications.asObservable().pipe(filter(r => r.pointer == pointer));
  }

  public notify(pointer: string, value: any) {
    this.notifications.next({ pointer, value });
  }

  public updateCategory(subscription_id: string, new_category: string) {
    return this._betaApi.updateCategory({subscription_id: subscription_id, new_category: new_category}).pipe(
      tap(
        r=> {
          this.updateSubscription(r);
        }
      )
    )
  }

  // public getDemoMode() { 
  //   const curr_partner_name = this.route.snapshot.paramMap.get('partner_name');
  //   console.log(curr_partner_name);

  //   if (curr_partner_name) {
  //     this.demoMode$.next({...this.demoMode$.value, partner_theme:curr_partner_name})
  //   }
  //   return this.demoMode$.asObservable(); 
  // }

  public getDemoMode() { 
    return this.demoMode$.asObservable(); 
  }
  public isFunctionDisabled(function_name: string) { return this.demoMode$.value?.disabled_functions.includes(function_name) || this.demoMode$.value?.disabled_functions.includes('all') ; }


  public getUserDetails() : Observable<UserDetails> {
    if (this.user_details) {
      return of(this.user_details);
    } else if (this.user_details$) {
      return this.user_details$;
    } else {
      this.user_details$ = this._betaApi.getAccountInfo().pipe(
        map(response => { return {consents : response.accounts} }),
        tap(response => { this.user_details = response; this.user_details$ = null; }),
        shareReplay(1)
      );
      return this.user_details$;
    }
  }
  
  public updatePersonalizationAttributes(attributes: UserAttributesUpdateRequest, direct_update : boolean = false) {
    this.attribute_store.dispatch(addBatchUpdate({attributes: attributes}));
    if (direct_update) {
      this.attribute_store.dispatch(fetchBatchUpdate());
    }
  }

  public getPersonalizatioAttributes() : Observable<PersonalizationModel> {
    return this.attribute_store.select(state => state.user_attributes.user_attributes).pipe(
      tap((userAttributes) => {
        if (this.initialLoad && userAttributes === null) {
          this.attribute_store.dispatch(fetchAttributes());
        }
      }),
      filter((userAttributes) => userAttributes !== null),
    );
  }

  public getPersonalizationAttributeSliceUpdates(slice_name) {
    return this.getPersonalizatioAttributes().pipe(
      filter(r => Object.keys(r).some(key => key.startsWith(slice_name))),
      distinctUntilChanged()
    );
  }

  public getPersonalizationAttributeHangning(name) : Observable<boolean> {
    return this.attribute_store.select(state => state.user_attributes.api_calls).pipe(
      map(calls => calls.includes(name)),
    );
  }

  public getPersonalizationIndications() : Observable<PersonalizationModel> {
    if (!this.personalization_indications.value) {
        this._betaApi.readPersonalizationIndications([]).subscribe(response => {
          const updates = response.reduce((acc, item) => {
            acc[item.key] = item;
            return acc;
          }, {});
          this.personalization_indications.next({...this.personalization_indications.value, ...updates});
        });  
    }
    return this.personalization_indications.asObservable().pipe(filter(r => r != null));
  }

  public ifNotLocked(subscription_id : string) {
    return this.getPersonalizatioAttributes().pipe(
      take(1),
      map(r => {
        return r.contract_locks.value?.find(c => c.subscription_id == subscription_id);
      }),
      switchMap(r => {
        if (!r) {
          return fromPromise(this._modal_service.open('contract_lock'));
        } else {
          return of(r);
        }
      }), switchMap(r => !r || r.is_locked ? throwError(()=> new Error('lockederror')) : of(r)),
    );
  }
}
