import { Injectable } from '@angular/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { ProductService } from '../product-service/product-service.service';

import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ActivatedRoute } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import { Store } from '@ngrx/store';
import { BehaviorSubject, take } from 'rxjs';
import { ProductState } from 'src/app/components/product/state/product-state.state';
import { UserAction } from 'src/app/types';
import { environment } from 'src/environments/environment';
import { ModalService } from '../modal-service/modal-service.service';
import { SessionState } from 'src/app/components/global/session/state';


@Injectable()
export class ProductTrackerService {
  user: any;
  user_sub: string;
  user_session: string;
  id_reported = false;
  available_optimizations: any
  user_action_subs: Map<string, BehaviorSubject<UserAction>> = new Map();
  public user_actions: UserAction[];

  constructor(
    private _product: ProductService,
    private _modal : ModalService,
    private activate_route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    protected store : Store<{product: ProductState}>,
    private session_state: Store<{ session: SessionState }>,
  ) {
    this.initiateUserActionTracking();
    this.subscribeToEvents();    

    this.store.select(state=>state.product.dashboard.dashboard_overview.available_optimizations).subscribe( m => {
      this.available_optimizations = m;
    }); 

    const disable_tracking = this.activate_route.snapshot.queryParamMap.get('test_mode') || localStorage.getItem('test_mode');
    if (disable_tracking) {
      localStorage.setItem('test_mode', disable_tracking);
      return;
    }

    if (!environment.amp_tracking) {
      return;
    }

    datadogRum.init({
      applicationId: 'fe12eb42-9606-4d4d-91cf-d6be2f3d03c6',
      clientToken: 'pub2147801fc2318a0d689f1131959e4db6',
      site: 'datadoghq.com',
      service: 'paylow-product',
      env: `product-${this._product.environement}`,
      // Specify a version number to identify the deployed version of your application in Datadog 
      // version: '1.0.0', 
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      useCrossSiteSessionCookie: true,
      defaultPrivacyLevel: 'mask-user-input',
      actionNameAttribute: 'data-custom-name',
    });
    datadogRum.startSessionReplayRecording();

    amplitude.init('c29bc0ee89009bbc7168069976b3c3d0');
    datadogRum.startSessionReplayRecording();
    console.log('tracking enbaled');
    
    if (environment.amp_session_tracking) {
      const sessionReplayTracking = sessionReplayPlugin();
      amplitude.add(sessionReplayTracking);
    }
  }

  public setUser() {
    if (!environment.amp_tracking) {
      return;
    }

    datadogRum.getUser();
  }

  public setTrackIdentity(id_token_payload: any) {
    if (!environment.amp_tracking) {
      return;
    }

    if (this.id_reported) {
      return;
    }

    this.session_state.select(state => state.session.appConfig).subscribe(
      r=> {
        this.user_sub = id_token_payload['cognito:groups']?.some(group => group.includes('Google')) ? id_token_payload['cognito:username'] : id_token_payload['sub'];
        console.log(this.user_sub);
        datadogRum.setUser({
          id: this.user_sub,
          client: r.client_name || r.client_id,
          is_internal_user: id_token_payload['cognito:groups'].includes('internal-users'),
        });
      }
    )

    amplitude.setUserId(this.user_sub);
    this.id_reported = true;
  }

  public subscribeToEvents() {
    this.store.select(state => state.product.dashboard.dashboard).subscribe( d => {
      if(d) {
        if (this.available_optimizations?.length > 0) {
          this.trackUserAction('any_card_clicked', {
            'sub_id': d.subscriptions.find(item => item.status.type == this.available_optimizations[0])?.id
          });
        }
      }
    });

    this._product.getSelectedSubscription(true).subscribe(
      s => {
        if (s) {
          this.setTag("subscription_click", { category: s.category.name, status: s.status.type });

          if (this.available_optimizations.includes(s.category.name) || s.category.name == 'Telecom') {
            this.cancelUserAction('any_card_clicked');
          }
          if (this.getAction('optimize_panel_clicked').is_active) {
            this.cancelUserAction('optimize_panel_clicked');
          }
        }
      }
    );

    this._product.getPanelState().subscribe(
      state => {
        if (state != 'default') {
          this.setTag("panel_click", { state: state });
          this.cancelUserAction('optimize_panel_clicked');
        }

        this._product.getSelectedSubscription(false).subscribe(r => {
          if (!r) { return; }
          if (state == 'default' && this.getAction('optimize_panel_clicked').is_relevant && r.status.type == 'improvable-auto') {
            this.trackUserAction(('optimize_panel_clicked'), null);
          }
        });

      }
    )
  }

  public setTag(name, value_object = {}) {
    if (!environment.amp_tracking) {
      return;
    }

    datadogRum.addAction(
      name,
      value_object
    );
    amplitude.track(
      name,
      value_object
    )
  }

  public initiateUserActionTracking() {
    localStorage.removeItem('user_actions');
    if (localStorage.getItem('user_actions')) {
      this.user_actions = JSON.parse(localStorage.getItem('user_actions'));
    } else {
      this.user_actions = [
        {
          name: 'any_card_clicked',
          delay_seconds: 3,
          timeoutId: null,
          is_active: false,
          is_relevant: true,
          action_args: { 'sub_id': null }
        },
        {
          name: 'optimize_panel_clicked',
          delay_seconds: 10,
          timeoutId: null,
          is_active: false,
          is_relevant: true,
          action_args: null
        },
        {
          name: 'optimize_invest_panel_clicked',
          delay_seconds: 5,
          timeoutId: null,
          is_active: false,
          is_relevant: true,
          action_args: null
        },
        {
          name: 'broadband_switch_plan',
          delay_seconds: 10,
          timeoutId: null,
          is_active: false,
          is_relevant: true,
          action_args: null
        },
      ];
  
      localStorage.setItem('user_actions', JSON.stringify(this.user_actions));
    }

    this.user_actions.forEach(action => {
      this.user_action_subs.set(action.name, new BehaviorSubject(action));
      this.user_action_subs.get(action.name).subscribe(a => { localStorage.setItem('user_actions', JSON.stringify(this.user_actions)); } );
    });
  }

  public trackUserAction(name: string, action_args: any) {
    const action = this.user_actions.find(a => a.name == name);
    if (!action) {
      return;
    }
    if (!action.is_relevant) {
      return;
    }
    action.action_args = action_args;

    action.timeoutId = setTimeout(() => {
      if (this.breakpointObserver.isMatched('(max-width: 996px)')) {
        return;
      }
      
      if (action.is_relevant && !this._modal.is_open) {
        action.is_active = true;
        action.is_relevant = false;
        this.user_action_subs.get(name).next(action);
      }
    }, action.delay_seconds * 1000);
  }

  public cancelUserAction(name: string) {
    const action = this.user_actions.find(a => a.name == name);
    if (!action) {
      return;
    }
    if (!action.is_relevant) {
      action.is_active = false;
      this.user_action_subs.get(name).next(action);
      return;
    }

    clearTimeout(action.timeoutId);
    action.is_active = false;
    action.is_relevant = false;
    this.user_action_subs.get(name).next(action);
  }

  private getAction(name: string) {
    return this.user_actions.find(a => a.name == name);
  }
  
  public getUserActionSubscription(name: string) {
    return this.user_action_subs.get(name);
  }

  public dismissActiveAction() {
    this.user_actions.forEach(action => {
      if (action.is_active) {
        this.cancelUserAction(action.name);
      }
    });
  }

}
