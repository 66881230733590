import { Inject, Injectable } from '@angular/core';
import { AUTH_API_TOKEN, AuthApiService } from "../api-service/auth-api.service";
import { ApiServiceParams, HttpMethod } from "../../models/HttpRequest";
import { Observable, Subscription, of, throwError } from "rxjs";
import { catchError, map, delay, retry, take, filter, tap, mergeMap } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { CancelSwitchObject, OptimizeBranch, OptimizeRequest, OptimizeResponse, UserAttribute, UserAttributesUpdateRequest, MobileCoverage, ContractLockDetails, MobileSwitchObject, BroadbandSwitchObject, GeneralAddress, EnergySwitchObject, EnergyTariff, MobileFilterModel, MobileAdvancedSearchModel, BroadbandFilterModel, BroadbandAdvancedSearchModel } from 'src/app/types/beta-optimize-models.model';
import { DashboardStateModel } from 'src/app/types/beta-dashboard.model';
import * as forge from 'node-forge';

import { Dashboard, CustomizationFile, SubscriptionStatus, AccountDetailsResponse, LeaveFeedbackRequest, SubscriptionCategoryUpdateRequest, PaylowSubscription, ChangeDetailsRequest, BaseNotificationItem, EventRequest, Provider, ManualSubscription } from "../../types";
import { RedeemTokenModel, UserDetailModel } from 'src/app/types/product-auth-models.model';
import insightsData from './insights.data';
import { Store } from '@ngrx/store';
import { fetchAsyncNotifications } from 'src/app/components/product/dashboard/action-center/state/state';
import { notLoggedIn } from 'src/app/components/product/state/product-state.state';
import { BenefitsSwitchObject } from 'src/app/components/product/dashboard/subscription-panel/switch-panel-benefits/state';
import { InterestsSwitchObject } from 'src/app/components/product/dashboard/subscription-panel/switch-panel-interests/state';
import { AlternativesSwitchObject } from 'src/app/components/product/dashboard/subscription-panel/switch-panel-alternatives/state';
import { navigateRestrictedAccess } from 'src/app/components/product/state/lifecycle.effect';


export interface BaseRequestModel<T> {
  statusCode: number,
  body: T,
  notification_flag: boolean
}

@Injectable(
)
export class ProductApiService {
  // private readonly _host = `https://api.paylow.app`;
  // private readonly _host = `https://817jpnj5af.execute-api.eu-central-1.amazonaws.com`;
  // private readonly _host = ``;
  private readonly _ep_info_terminate = 'info/terminate';
  private readonly _ep_info_change_details = 'info/change-details';
  private readonly _ep_info_contract_lock = 'event/contract-lock';
  private readonly _ep_consent_list = 'consent/list';

  private readonly _ep_notifications = 'notifications';
  private readonly _ep_notifications_dismiss = 'notifications/dismiss?notification_id={notification_id}';
  private readonly _ep_notifications_update = 'notifications/update?result={result}&notification_id={notification_id}';
  private readonly _ep_utils_addresses = 'utils/addresses?postcode={postcode}&provider={provider}';
  private readonly _ep_utils_provider = 'utils/providers?category={category}';
  private readonly _ep_utils_mobile_brands = 'utils/mobile-brands';
  private readonly _ep_utils_energy_tariffs = 'utils/energy-tariffs';

  private readonly _ep_dashboard_read = 'dashboard/read';
  private readonly _ep_dashboard_exists = 'dashboard/exists';
  private readonly _ep_dashboard_subscription = 'dashboard/subscription';
  private readonly _ep_dashboard_subscription_update = 'subscription/{subscription_id}/update';
  private readonly _ep_dashboard_process = 'dashboard/process';
  private readonly _ep_dashboard_update = 'dashboard/optimize';
  private readonly _ep_optimize_contract_lock = 'optimize/{subscription_id}/contract-lock';

  private readonly _ep_dashboard_category = 'dashboard/update-category';
  private readonly _ep_dashboard_customization = 'dashboard/customization';
  private readonly _ep_add_manual_subscription = 'dashboard/customization/add-sub'
  private readonly _ep_optimize_mobile_coverage = 'optimize/mobile/coverage';
  private readonly _ep_personalize_add_attribute = 'attribute/update';
  private readonly _ep_personalize_read_attribute = 'attribute/read';
  private readonly _ep_optimize_cancel = 'cancel';
  private readonly _ep_dashboard_state = 'dashboard/state';
  private readonly _ep_dashboard_state_update = 'dashboard/state/update';

  private readonly _ep_dashboard_upvote = 'dashboard/upvote';
  private readonly _ep_dashboard_comingsoon = 'dashboard/coming-soon';
  private readonly _ep_event_feedback = 'event/feedback';
  private readonly _ep_event_general = 'event';

  private readonly _ep_redeem_token_validate = 'redeem/validate';
  private readonly _ep_redeem_token_claim = 'redeem';
  private readonly _ep_auth_user = 'user';

  private readonly _waitlist_url = 'https://uskojbaw8h.execute-api.eu-central-1.amazonaws.com/v1/waitlist';
  private readonly _ep_optimize = 'optimize';
  private readonly _ep_optimize_benefits = 'optimize/benefits';
  private readonly _ep_optimize_benefits_light = 'optimize/benefits/light';
  private readonly _ep_optimize_benefits_deals = 'optimize/benefits/deals';
  private readonly _ep_optimize_benefits_claim = 'optimize/benefits/claim';

  private readonly _ep_refer_a_friend = 'refer?refer_to_mail={refer_to_mail}&refer_to_name={refer_to_name}';
  private readonly _ep_interests_offers = 'interests/offers';
  private readonly _ep_interests_dismiss = 'interests/dismiss';
  private readonly _ep_interests_claim = 'interests/claim';
  private readonly _ep_optimize_alternatives = 'optimize/alternatives';



  private mock_mode: string = '';
  private mock_transformer: Map<RegExp, any> = new Map();
  private mock_delays: Map<RegExp, number> = new Map();
  private cont_mode = false;

  constructor(
    protected _apiService: AuthApiService,
    protected store: Store<{}>,
    
  ) { 

  }

  public setModeMock(mode: string) {
    this.mock_mode = mode;
  }

  public addMockTransformer(resource: RegExp, transformer: any) {
    this.mock_transformer.set(resource, transformer);
  }

  public addMockDelay(resource: RegExp, duration: number) {
    this.mock_delays.set(resource, duration);
  }

  public setContMock() {
    this.cont_mode = true;
  }

  public getAccountInfo(): Observable<AccountDetailsResponse> {
    return this._get<AccountDetailsResponse>(this._ep_consent_list);
  }

  public optimizeMobileWriteOrder(switch_model: MobileSwitchObject) {
    return this._post<MobileSwitchObject>(`${this._ep_optimize}/mobile/order`, switch_model);
  }

  public getMobileCoverage(): Observable<MobileCoverage> {
    return this._get<MobileCoverage>(this._ep_optimize_mobile_coverage);
  }

  public readPersonalizationAttribute(key: string[]): Observable<UserAttribute[]> {
    const ep = key.length > 0 ? `${this._ep_personalize_read_attribute}?key=${key.join(',')}` : this._ep_personalize_read_attribute;
    return this._get<UserAttribute[]>(ep);
  }
  public readPersonalizationIndications(key: string[]): Observable<UserAttribute[]> {
    const ep = key.length > 0 ? `${this._ep_personalize_read_attribute}?key=${key.join(',')}&profile=indications` : `${this._ep_personalize_read_attribute}?profile=indications`;
    return this._get<UserAttribute[]>(ep).pipe(
      catchError((error) => {
        return of(null);
      }),
      // filter((attr) => attr !== null)
    );
  }

  public addPersonalizationAttribute(attributes: UserAttributesUpdateRequest): Observable<any> {
    return this._post<any>(this._ep_personalize_add_attribute, attributes);
  }

  public eventFeedback(feedbackModel: LeaveFeedbackRequest): Observable<any> {
    return this._post<any>(this._ep_event_feedback, feedbackModel);
  }

  public event(feedbackModel: EventRequest): Observable<any> {
    return this._post<any>(this._ep_event_general, feedbackModel);
  }


  public updateDetails(changeDetailsModel: ChangeDetailsRequest): Observable<any> {
    return this._post<any>(this._ep_info_change_details, changeDetailsModel);
  }

  public updateCategory(updateModel: SubscriptionCategoryUpdateRequest): Observable<any> {
    return this._post<any>(this._ep_dashboard_category, updateModel);
  }

  public customization(sub_id: string = null, action: string = null): Observable<CustomizationFile> {
    return this._get<CustomizationFile>(this._ep_dashboard_customization + (sub_id ? `?sub_id=${sub_id}` : '') + (action ? `&action=${action}` : ''));
  }

  // redeem
  public parseRedeemToken(token: string): Observable<RedeemTokenModel> {
    return this._unauth_post<RedeemTokenModel>(this._ep_redeem_token_validate, { redeem_token: token });
  }
  public parseShortRedeemToken(token: string): Observable<RedeemTokenModel> {
    return this._unauth_post<RedeemTokenModel>(this._ep_redeem_token_validate, { short_token: token });
  }
  public claimRedeemToken(token: string): Observable<RedeemTokenModel> {
    return this._post<RedeemTokenModel>(this._ep_redeem_token_claim, { redeem_token: token });
  }
  public userAuthDetails(): Observable<UserDetailModel> {
    return this._get<UserDetailModel>(this._ep_auth_user);
  }

  // public claimRedeemToken(token: string, ): Observable<any> {
  //   return this._apiService._nonAuthSendRequest<any>('POST', this._ep_redeem_token, { redeem_token: token });
  // }

  // Settings



  public terminateAccount(reasons_string): Observable<any> {
    return this._post<any>(this._ep_info_terminate, { reasons: reasons_string });
  }

  public contractLock(lock_model): Observable<any> {
    return this._post<any>(this._ep_info_contract_lock, lock_model);
  }

  // Dashboard

  private _readDashboard(): Observable<Dashboard> {
    return this._get<Dashboard>(this._ep_dashboard_read);
    // return this._get<Dashboard>("error");
  }

  private _processDashboard(): Observable<Dashboard> {
    return this._get<Dashboard>(this._ep_dashboard_process).pipe(
      catchError((error) => throwError(() => error))
    );
  }

  public isDashboardExists(): Observable<boolean> {
    return this._get<boolean>(this._ep_dashboard_exists).pipe(
      take(1),
      map(r => { return true; }),
      catchError((error) => {
        if (error.statusCode === 420) {
          return of(false);
        } else {
          throw error;
        }
      })
    );
  }

  public getDashboard(): Observable<Dashboard> {
    return this._readDashboard().pipe(
      catchError((error) => {
        if (error.statusCode === 420) { // UserFileMissingException: User never processed
          return this._processDashboard().pipe(

            catchError((error) => {
              if (error.status === 504) { // Gateway Timeout      
                return this._readDashboard().pipe(
                  retry(3),
                  delay(2000),
                )
              } else {
                throw error.body;

              }
            })

          );
        } else {
          return throwError(() => error.body);
        }
      })
    );
  }

  public getSubscription(subscription_id: string): Observable<PaylowSubscription> {
    return this._get<PaylowSubscription>(`${this._ep_dashboard_subscription}/${subscription_id}`);
  }
  // Panel

  public optimizeSubscription(subscription_id: string): Observable<SubscriptionStatus> {
    return this._post<SubscriptionStatus>(this._ep_dashboard_update, { subscription_id: subscription_id });
  }

  public contractLockSubscription(subscription_id: string, lock_model): Observable<UserAttribute> {
    return this._post<UserAttribute>(this._ep_optimize_contract_lock.replace('{subscription_id}', subscription_id), lock_model);
  }

  public updateSubscription(subscription: PaylowSubscription): Observable<boolean> {
    return this._post<boolean>(this._ep_dashboard_subscription_update.replace('{subscription_id}', subscription.id), subscription);
  }

  public optimizeAutoSubscription(subscription_id: string): Observable<OptimizeResponse> {
    return this._post<OptimizeResponse>(this._ep_optimize, { subscription_id: subscription_id });
  }

  public optimizeAutoBranch(branch: string, switch_model) {
    return this._post<any>(`${this._ep_optimize}/${branch}`, switch_model);
  }

  public cancelSubscription(cancel_model: CancelSwitchObject) {
    return this._post<CancelSwitchObject>(this._ep_optimize_cancel, cancel_model);
  }

  public getJourneyState(): Observable<DashboardStateModel> {
    return this._get<DashboardStateModel>(this._ep_dashboard_state);
  }

  public updateDashboardState(state: DashboardStateModel): Observable<DashboardStateModel> {
    return this._post<DashboardStateModel>(this._ep_dashboard_state_update, state);
  }

  public upvoteSubscription(subscription_id: string): Observable<SubscriptionStatus> {
    return this._post<SubscriptionStatus>(this._ep_dashboard_upvote, { subscription_id: subscription_id });
  }

  public comingSoonSubscription(subscription_id: string): Observable<SubscriptionStatus> {
    return this._post<SubscriptionStatus>(this._ep_dashboard_comingsoon, { subscription_id: subscription_id });
  }

  // public uploadInvoice( request : SubscriptionInvoiceRequest ) {
  //   return this._post<SubscriptionInvoiceResponse>(this._ep_dashboard_invoice, request)
  // }

  public getInsigntText(name: string, category: string): string {
    const insights = insightsData[name.toLowerCase()] || insightsData[category.toLowerCase()];

    if (insights) {
      const currentDate = new Date().toISOString().split('T')[0];

      const combinedString = currentDate + name;
      let hash = 0;
      for (let i = 0; i < combinedString.length; i++) {
        const char = combinedString.charCodeAt(i);
        hash = (hash << 5) - hash + char;
      }

      const index = (hash % insights.length + insights.length) % insights.length;
      return insights[index];
    }
    return "";
  }

  // Mobile switch

  public optimizeMobile(switch_model: MobileSwitchObject) {
    return this._post<MobileSwitchObject>(`${this._ep_optimize}/mobile`, switch_model);
  }

  public optimizeMobileAdvancedSearch(filter_model: MobileFilterModel, sub_id:string, is_handset: string, page_number: string): Observable<MobileAdvancedSearchModel> {
    return this._post<MobileAdvancedSearchModel>(`${this._ep_optimize}/mobile/advanced-search?sub_id=${sub_id}&is_handset=${is_handset}&page_num=${page_number}`, filter_model);
  }

  // Broadband switch

  public optimizeBroadband(switch_model: BroadbandSwitchObject) {
    return this._post<BroadbandSwitchObject>(`${this._ep_optimize}/broadband`, switch_model);
  }

  public optimizeBroadbandGetDealDetails(switch_model: BroadbandSwitchObject) {
    return this._post<BroadbandSwitchObject>(`${this._ep_optimize}/broadband/details`, switch_model);
  }

  public optimizeBroadbandFillDealDetails(switch_model: BroadbandSwitchObject) {
    return this._post<BroadbandSwitchObject>(`${this._ep_optimize}/broadband/details/fill`, switch_model);
  }

  public optimizeBroadbandSubmitOrder(switch_model: BroadbandSwitchObject) {
    return this._post<BroadbandSwitchObject>(`${this._ep_optimize}/broadband/order`, switch_model);
  }

  public optimizeBroadbandAdvancedSearch(filter_model: BroadbandFilterModel, sub_id:string, page_number: string): Observable<BroadbandAdvancedSearchModel> {
    return this._post<BroadbandAdvancedSearchModel>(`${this._ep_optimize}/broadband/advanced-search?sub_id=${sub_id}&page_num=${page_number}`, filter_model);
  }

  // Energy switch

  public optimizeEnergy(switch_model : EnergySwitchObject) {
    return this._post<EnergySwitchObject>(`${this._ep_optimize}/energy`, switch_model);
  }

  public optimizeEnergySubmitOrder(switch_model : EnergySwitchObject) {
    return this._post<EnergySwitchObject>(`${this._ep_optimize}/energy/order`, switch_model);
  }

  // Benefits

  public getBenefitsState(): Observable<BenefitsSwitchObject> {
    return this._get<BenefitsSwitchObject>(this._ep_optimize_benefits);
  }

  public getBenefitsLightEstimation(switch_model: BenefitsSwitchObject): Observable<BenefitsSwitchObject> {
    return this._post<BenefitsSwitchObject>(this._ep_optimize_benefits_light, switch_model);
  }

  public getBenefitsDeals(): Observable<BenefitsSwitchObject> {
    return this._get<BenefitsSwitchObject>(this._ep_optimize_benefits_deals);
  }

  public claimBenefitsDeal(deal_id: string): Observable<boolean> {
    return this._get<boolean>(this._ep_optimize_benefits_claim + `?benefit_id=${deal_id}`);
  }

  // Interests

  public getInterests(): Observable<InterestsSwitchObject> {
    return this._get<InterestsSwitchObject>(this._ep_interests_offers);
  }

  public dismissInterest(id: string): Observable<boolean> {
    return this._get<boolean>(this._ep_interests_dismiss + `?offer_id=${id}`);
  }

  public claimInterest(id: string): Observable<boolean> {
    return this._get<boolean>(this._ep_interests_claim + `?offer_id=${id}`);
  }

  public getAlternatives(switch_model: AlternativesSwitchObject): Observable<AlternativesSwitchObject> {
    return this._post<AlternativesSwitchObject>(this._ep_optimize_alternatives, switch_model);
  }

  // Waitlist

  public waitlistSniffer(request) {
    return this._apiService.httpPut(this._waitlist_url, request);
  }

  // Notifications 

  public getNotifications() {
    return this._get<BaseNotificationItem[]>(this._ep_notifications);
  }

  public dismissNotification(notification_id) {
    return this._get<any>(this._ep_notifications_dismiss.replace('{notification_id}', notification_id));
  }

  public getProviders(category) {
    return this._get<Provider[]>(this._ep_utils_provider.replace('{category}', category));
  }

  public getMobileBrands() {
    return this._get<Provider[]>(this._ep_utils_mobile_brands);
  }

  public getEnergyTariffs(provider_id: string, service_type: string=null): Observable<EnergyTariff[]> {
    const qp = new URLSearchParams();
    qp.append('provider_id', provider_id);
    if (service_type) {
      qp.append('service_type', service_type)
    }
    return this._get<EnergyTariff[]>(`${this._ep_utils_energy_tariffs}?${qp}`);
  }

  public addManualSubscription(manual_subscription : ManualSubscription): Observable<PaylowSubscription> {
    return this._post<PaylowSubscription>(this._ep_add_manual_subscription, manual_subscription );
  }

  public updateNotification(notification_id, new_result) {
    return this._get<boolean>(this._ep_notifications_update
      .replace('{notification_id}', notification_id)
      .replace('{result}', new_result)
    );
  }

  public getAddresses(postcode: string, provider : string) {
    return this._get<any>(this._ep_utils_addresses.replace('{postcode}', postcode).replace('{provider}', provider));
  }

  public referAFriend(refer_to: string, name: string) {
    return this._get<any>(this._ep_refer_a_friend.replace('{refer_to_mail}', refer_to).replace('{refer_to_name}', name));
  }

  // infra

  public encryptSensitiveData(data: string, publicKey: string): string {
    const publicKeyObj = forge.pki.publicKeyFromPem(publicKey);
    const encrypted = publicKeyObj.encrypt(data, 'RSA-OAEP');
    return forge.util.encode64(encrypted);
  }


  private _handle_response(res: BaseRequestModel<any>) {
    if (res.notification_flag) {
      this.store.dispatch(fetchAsyncNotifications());
    }

    switch (res.statusCode) {
      case 200:
        return res.body;
      case 401:
      case -1:
        // Api call wasn't executed due to missing auth token;
        this.store.dispatch(notLoggedIn());
        throw res;
      default:
        throw res;
    }
  }

  private _transform_response(resouce, body = null) {
    return (res) => {
      if (this.mock_mode == '') {

        return res;
      }
      let transformedResponse = res;
      for (const [key, value] of this.mock_transformer.entries()) {
        if (new RegExp(key).test(resouce)) {

          const url = new URL(`https://dontcare/${resouce}`);
          body = { ...body, ...Object.fromEntries(url.searchParams.entries()) };
          transformedResponse = value(transformedResponse, body);
        }
      }
      return transformedResponse;
    }
  }

  private _handle_error(error): Observable<never> {
    switch (error.statusCode) {
      case 401:
      case -1:
        this.store.dispatch(notLoggedIn());
        throw error;
      default:
        throw error;
    }
  }

  private _build_uri(resource: string): string {
    if (this.mock_mode != '') {
      const user_related_resources = {
        'dashboard/read': 'user'
      };

      if (user_related_resources[resource]) {
        const a = `../assets/mock/user/${this.mock_mode}/${resource.replace(/[\/\?\=\-]/g, '_')}.json`;
        return a.replace(/\?.*$/g, '');
      }
      resource = resource.replace(/\/[0-9a-fA-F-]{32,36}(\/|$)/, '//');
      let a = `../assets/mock/${resource.replace(/[\/\=\-]/g, '_')}`;

      return `${a.replace(/\?.*$/g, '')}.json`;
    }
    return `${environment.api_hostname}/${resource}`
  }

  private _build_method(method: HttpMethod): HttpMethod {
    if (this.mock_mode != '') {
      return 'GET';
    }
    return method;
  }

  protected _get<T>(resource: string): Observable<T> {
    return this._apiService.sendRequest<BaseRequestModel<T>>(this._build_method('GET'), this._build_uri(resource), {}).pipe(
      delay(this.getDelayByLastRegex(resource)),
      map(this._handle_response.bind(this)),
      map(this._transform_response(resource)),
      catchError(this._handle_error.bind(this))
    );
  }

  public _post<T>(resource: string, body: ApiServiceParams): Observable<T> {
    return this._apiService.sendRequest<BaseRequestModel<T>>(this._build_method('POST'), this._build_uri(resource), { body }).pipe(
      delay(this.getDelayByLastRegex(resource)),
      map(this._handle_response.bind(this)),
      map(this._transform_response(resource, body)),
      catchError(this._handle_error.bind(this))
    );
  }

  public _unauth_post<T>(resource: string, body: ApiServiceParams): Observable<T> {
    return this._apiService._nonAuthSendRequest<BaseRequestModel<T>>(this._build_method('POST'), this._build_uri(resource), { body }).pipe(map(this._handle_response));
  }

  public _unauth_get<T>(resource: string): Observable<T> {
    return this._apiService._nonAuthSendRequest<BaseRequestModel<T>>(this._build_method('GET'), this._build_uri(resource), { }).pipe(map(this._handle_response));
  }

  catchFlowError(onFlowError: (error: any) => Observable<any>) {
    return catchError((error) => {
      if (error.statusCode === 420) {
        return onFlowError(error);
      } else {
        throw error;
      }
    })
  }

  getDelayByLastRegex(resource: string): number {
    let matchedDelay = 0; // Default delay if no pattern matches
    for (const [pattern, delay] of this.mock_delays) {
      if (new RegExp(pattern).test(resource)) {
        matchedDelay = delay;
      }
    }
    return matchedDelay;
  }

}

export function addMinimumWaitTime<T>(observable: Observable<T>, minimumWaitTime: number, localStorageKey=null): Observable<T> {
  const startTime = Date.now();

  if (localStorageKey != null) {
    const lastCallTime = localStorage.getItem(localStorageKey);
    
    if (lastCallTime && Date.now() - parseInt(lastCallTime) < 60 * 60 * 1000) {
      return observable;
    } else {
      localStorage.setItem(localStorageKey, Date.now().toString());
    }    
  }

  return observable.pipe(
    mergeMap(response => {
      const apiCallDuration = Date.now() - startTime;
      const remainingTime = Math.max(0, minimumWaitTime - apiCallDuration);
      console.log(`Remaining wait time: ${remainingTime} ms`);

      return of(response).pipe(delay(remainingTime));
    })
  );
}
