<div class="d-flex flex-column align-items-center justify-content-between text-center gap-16 h-100 overflow-auto">

    <div class="d-flex">
        <app-modal-faq></app-modal-faq>
    </div>

    <div class="modal-footer" style="max-height: 150px;">
        <div class="d-flex gap-32 w-100 justify-content-between">
            <a class="link-text" href="https://www.paylow.app/terms-of-use" target="_blank">
                <span class="body2">Terms Of Use</span>
            </a>
            <a class="link-text" href="https://www.paylow.app/privacy-policy" target="_blank">
                <span class="body2">Privacy Policy</span>
            </a>
        </div>
            <span class="body3">    Paylow Systems UK Limited (14599130) - trading as “Paylow” – is a registered company in England, having its registered place of business at 7 Savoy Court, London WC2R 0EX; registered with the Financial Conduct Authority (FCA) reference number 903097 and with the Information Commissioner's Office (ICO) reference number ZB503371. Paylow is acting as an agent of TrueLayer, who is providing the regulated Account Information Service, and is Authorised and Regulated by the Financial Conduct Authority under the Payment Services Regulations 2017 and the Electronic Money Regulations 2011 (Firm Reference Number: 901096).</span>
    </div>
</div>
