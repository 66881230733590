

const broadbandContractLockGuide = [
    {
        "provider": "935", //"BT"
        "link": "https://home.bt.com/login/loginform",
        "phone": "0800 800 150",
        "instruction1": "Log in to your BT account.",
        "instruction2": "Select ‘Your bills and usage’.",
        "instruction3": "If your initial contract period is ending/ended, it should say so."
    },
    {
        "provider": "1025", //"Community Fibre"
        "link": "",
        "phone": "0800 082 0770",
        "instruction1": "Phone only",
        "instruction2": "",
        "instruction3": ""
    },
    {
        "provider": "703", //"EE"
        "link": "https://id.ee.co.uk/login",
        "phone": "0800 956 6000",
        "instruction1": "Log in to your EE account.",
        "instruction2": "Select 'My Plan'.",
        "instruction3": "Select 'My Plan details'."
    },
    {
        "provider": "1682", //"iTalk"
        "link": "https://italk.lifecyclebilling.com/iTalk/Login.html",
        "phone": "0330 1917 461",
        "instruction1": "Log in to your italk account.",
        "instruction2": "Select 'My Account'.",
        "instruction3": "Check your contract details."
    },
    {
        "provider": "862", //"NOW Broadband"
        "link": "https://www.nowtv.com/gb/sign-in?return=/gb/account/summary",
        "phone": "0330 3323 050",
        "instruction1": "Log in to your NOW account.",
        "instruction2": "Select 'Manage Account' (in case you're not directed).",
        "instruction3": "Check your contract details."
    },
    {
        "provider": "713", //"Plusnet"
        "link": "https://www.plus.net/member-centre/login",
        "phone": "0800 432 0200",
        "instruction1": "Log in to your Plusnet account (Broandband/Mobile accounts are seperated).",
        "instruction2": "Select 'My Account'.",
        "instruction3": "Check your contract details."
    },
    {
        "provider": "1680", //"POP Telecom"
        "link": "https://www.poptelecom.co.uk/login",
        "phone": "0343 5386611",
        "instruction1": "Log in to your POP account.",
        "instruction2": "Select 'My Account'.",
        "instruction3": "Check your contract details."
    },
    {
        "provider": "1632", //"Shell Energy Broadband"
        "link": "https://accounts.shellenergy.co.uk/authn/authenticate/one-login",
        "phone": "0330 094 5801",
        "instruction1": "Log in to your Shell account.",
        "instruction2": "Select Broadband.",
        "instruction3": "Check your contract details."
    },
    {
        "provider": "1321", //"Sky Broadband"
        "link": "https://messages.sky.com/message-centre",
        "phone": "0333 7591 018",
        "instruction1": "Log in to your Sky Message Centre.",
        "instruction2": "Look for a message titled 'Confirming your Sky offer'.",
        "instruction3": "The start date and length of your contract should be presented."
    },
    {
        "provider": "1011", //"TalkTalk"
        "link": "https://myaccount.talktalk.co.uk/login/login/login",
        "phone": "0345 172 0088",
        "instruction1": "Log in to your TalkTalk account.",
        "instruction2": "Select ‘My Account’, Select ‘My Package’.",
        "instruction3": "The end date of your contract is shown under ‘Package overview."
    },
    {
        "provider": "900", //"Virgin Media"
        "link": "https://my.virginmedia.com/home/signIn",
        "phone": "0345 454 1111",
        "instruction1": "Log in to your Virgin Media account.",
        "instruction2": "Select 'My Account'.",
        "instruction3": "Select 'Check your contract details', where you will find your end date."
    },
    {
        "provider": "697", //"Vodafone"
        "link": "https://www.vodafone.co.uk/myvodafone/faces/home",
        "phone": "0333 304 0191",
        "instruction1": "Log in to your Vodafone account.",
        "instruction2": "Select ‘Upgrade or Change plan’.",
        "instruction3": "This page shows your plan, payments and contract dates."
    },
]

export default broadbandContractLockGuide;