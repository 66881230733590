


export interface NewConsentModel {
  ob_link? : string,
  scope? : string,
  state? : string,
  code? : string,
  id_token? : string

}

export interface BankDetails {
  name : string,
  logo : string,
  account_holder_name? : string,
  type : string
  sort_code : string,
  number : string
}

export interface UserDetails {
  consents : BankDetails[];
  
}


export interface UserAction {
  name : string,
  delay_seconds : number,
  timeoutId : any,
  is_active : boolean,
  is_relevant : boolean,
  action_args : any
}

export interface UserActions {
  any_card_clicked : UserAction,
  optimize_card_clicked : boolean,
  optimize_panel_clicked : boolean,
  mobile_switch_clicked : boolean,
  broadband_switch_clicked : boolean,
}

export interface DashboardOverview {
  total_monthly_pay? : number,
  dashboard_exists : boolean,
  optimization_count? : number,
  missing_optimizations?: string[],
  available_optimizations?: string[],
  have_telecom_ambiguity? : boolean,
  subs_monthly_percentage?: number,
}

export interface UnlistedBankModel {
  name : string
}

export interface EventRequest {
  feedback_type : string
  feedback_value : string
  feedback_pointer : string
}

export interface LeaveFeedbackRequest {
  feedback_value : string
  feedback_pointer : string
}

export interface ChangeDetailsRequest {
  new_name : string
}

export interface SubscriptionCategoryUpdateRequest {
  subscription_id : string
  new_category : string
}

export interface TerminateAccountRequest {
  reasons : string
}

export interface ContractLockRequest {
  subscription_id : string
  category : string
  date : string
}

export interface AccountDetailsResponse {
  accounts : BankDetails[]
}
