import { Action, Store, createAction, createFeatureSelector, createSelector, props, select } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merge, of } from 'rxjs';
import { map, mergeMap, catchError, withLatestFrom, tap, filter, concatMap, delay, take, first, finalize, switchMap } from 'rxjs/operators';
import { ProductApiService, addMinimumWaitTime } from 'src/app/services/product-api/product-api.service';
import { MobileSwitchObject, UserAttribute } from 'src/app/types/beta-optimize-models.model';
import { ProductService } from 'src/app/services/product-service/product-service.service';
import { Draft, produce } from 'immer';
import { state } from '@angular/animations';
import { attributeUpdated } from '../switch-panel-broadband/state';
import { UserAttributesState, setAttributes, setNewAttributes } from '../../../common/modals/personalization-modal/state';
import { Provider } from 'src/app/types';
import { addGeneralErrorToast, addToast } from '../../../state/product-state.state';


export interface LightQuestions {
    relationship_status: string;
    num_children: number;
    housing_type: number;
    monthly_income: number;
    broadband_provider?: string | null;
    gas_electricity_providers?: string[] | ['none'];
}

export interface BenefitsEstimation {
    monthly_benefits_amount: number
    extra_monthly_benefits_amount: number
    overclaimed_monthly_benefits_amount: number
    benefits_calculator_link: string
    iframe_url: string
    is_likely_to_click: boolean
}

export interface CTA {
    text: string
    url: string
}

export interface InbestDeal {
    id: string
    title: string
    subtitle?: string
    description: string
    type: string
    amount?: number | null
    cta?: CTA[] | null
}

export interface InbestCategorizedDeals {
    month: InbestDeal[] | null
    lump_sum: InbestDeal[] | null
    discretionary: InbestDeal[] | null
    energy_support_social_tariffs: InbestDeal[] | null
    grants: InbestDeal[] | null
    other: InbestDeal[] | null
}

export interface BenefitsSwitchObject {
    calculated_light_estimation: boolean;
    calculated_benefits: boolean;
    light_questions: LightQuestions;
    light_estimation: BenefitsEstimation;
    deals: InbestCategorizedDeals;
    claimed: string[];
    selected_deal_id: string;
}

export interface BenefitsSwitchState {
    api_calls: string[],
    sub_step: string;
    step: string;
    model : BenefitsSwitchObject;
}

// Initial state
const initialState: BenefitsSwitchState = {
    model: null,
    api_calls: [],
    sub_step: 'welcome',
    step: '',
};


export interface BenefitQuestionObject {
    text: string;
  }

// Define actions
export const initiate = createAction('[Benefits] initiate');
export const updateModel = createAction('[Benefits] Update model', props<{ name: string, model: BenefitsSwitchObject }>());
export const removeSubscription = createAction('[Benefits] Remove model', props<{ subscription_id: string }>());

export const setActiveSubscriptionId = createAction('[Benefits] setActiveSubscriptionId', props<{ subscription_id: string }>());
export const setInactive = createAction('[Benefits] setInactive', props<{ subscription_id: string }>());
export const setViewMode = createAction('[Benefits] Set View Mode', props<{ view_mode: 'standalone' | 'bundle' }>());

export const addApiCall = createAction('[Benefits] Add hangning', props<{ name: string }>());
export const removeApiCall = createAction('[Benefits] Remove hangning', props<{ name: string }>());

export const updateStep = createAction('[Benefits] updateStep', props<{ step: string }>());

export const stepNext = createAction('[Benefits] stepNext');
export const stepBack = createAction('[Benefits] stepBack');

export const dealSelect = createAction('[Benefits] dealSelect', props<{ deal_id: string | number }>());
export const fetchDeals = createAction('[Benefits] fetchDeals',  props<{ caller : string }>());
export const fetchEstimation = createAction('[Benefits] fetchEstimation');
export const setLightQuestions = createAction('[Benefits] setLightQuestions',  props<{ light_questions : LightQuestions }>());

export const fetchClaimBenefit = createAction('[Benefits] fetchClaimBenefit', props<{ benefit: InbestDeal }>());


export const updateOrderResult = createAction('[Benefits] updateOrderResult', props<{ result: string }>());
export const setHandset = createAction('[Benefits] setHandset', props<{ new_value: boolean }>());
export const updateClaimed = createAction('[Benefits] updateClaimed', props<{ id: string }>());
export const setSelectedDeal = createAction('[Benefits] setSelectedDeal', props<{ id: string }>());




export const mobileSwitched = createAction('[Benefits] switched');

export const noop = createAction('[Benefits] noop');

const steps = ['light-questions', 'estimation', 'full-questions', 'deals'];
const subSteps = [
    ['welcome', 'questions'],
    [],
    [],
    []
];

// Create reducer
export const benefitsReducer = createReducer(
    initialState,
    on(setViewMode, (state, { view_mode }) => ({
        ...state,
        view_mode: view_mode
    })),
    on(updateStep, (state, { step }) => ({
        ...state,
        step: step
    })),
    on(updateModel, (state, { model }) => ({
      ...state,
      model: model
  })),    
  on(addApiCall, (state, { name }) => ({
        ...state,
        api_calls: state.api_calls.includes(name) ? state.api_calls : [...state.api_calls, name]
    })),
    on(removeApiCall, (state, { name }) => ({
        ...state,
        api_calls: state.api_calls.filter(n => n != name)
    })),
    on(stepNext, (state) => {
        const currentStepIndex = steps.indexOf(state.step);
        const currentSubStepIndex = subSteps[currentStepIndex].indexOf(state.sub_step);

        if (currentSubStepIndex < subSteps[currentStepIndex].length - 1) {
            return {
                ...state,
                sub_step: subSteps[currentStepIndex][currentSubStepIndex + 1]
            };
        } else if (currentStepIndex < steps.length - 1) {
            return {
                ...state,
                step: steps[currentStepIndex + 1],
                sub_step: subSteps[currentStepIndex + 1][0] || ''
            };
        }
        return state;
    }),
    on(stepBack, (state) => {
        const currentStepIndex = steps.indexOf(state.step);
        const currentSubStepIndex = subSteps[currentStepIndex].indexOf(state.sub_step);

        if (currentSubStepIndex > 0) {
            return {
                ...state,
                sub_step: subSteps[currentStepIndex][currentSubStepIndex - 1]
            };
        } else if (currentStepIndex > 0) {
            const prevStepIndex = currentStepIndex - 1;
            return {
                ...state,
                step: steps[prevStepIndex],
                sub_step: subSteps[prevStepIndex][subSteps[prevStepIndex].length - 1] || ''
            };
        }
        return state;
    }),
   on(updateClaimed, (state, { id }) => ({
    ...state,
    model: {
        ...state.model,
        claimed: [...state.model.claimed, id]
    }
    })),
    on(setSelectedDeal, (state, { id }) => ({
        ...state,
        model: {
            ...state.model,
            selected_deal_id: id
        }
    })),
    on(setLightQuestions, (state, { light_questions }) => ({
        ...state,
        model: {
            ...state.model,
            light_questions: light_questions
        }
    })),
);


@Injectable()
export class BenefitsSwitchEffects {
    constructor(
        private actions$: Actions,
        private api: ProductApiService,
        private product: ProductService,
        private store: Store<{ benefits_switch: BenefitsSwitchState }>,
        private attribute_store: Store<{ user_attributes: UserAttributesState }>
    ) { }

    startJorney$ = createEffect(() =>
        this.actions$.pipe(
          ofType(initiate),
          withLatestFrom(this.store.select(state => state.benefits_switch)),
          tap(() => this.store.dispatch(addApiCall({ name: 'state' }))),
          concatMap(([action, state]) =>
            this.api.getBenefitsState().pipe(
              concatMap(res => {
                let step = 'light-questions';
                if (res.calculated_light_estimation && res.light_estimation) {
                    step = 'estimation';
                } if (res.calculated_benefits && res.deals) {
                    step = 'deals';
                }
                return [
                  updateModel({ name: 'startJorney', model: res }),
                  updateStep({ step: step })
                ];
              }),
              catchError(() => of(addGeneralErrorToast())),
              finalize(() => {
                this.store.dispatch(removeApiCall({ name: 'state' }));
              })
            )
          )
        )
      );

    fetchDeals$ = createEffect(() =>
        this.actions$.pipe(
          ofType(fetchDeals),
          withLatestFrom(this.store.select(state => state.benefits_switch)),
          tap( a => this.store.dispatch( addApiCall({name:'deals'}) )),
          concatMap(([action, state]) =>
            this.api.getBenefitsDeals().pipe(
                concatMap(res => [
                    updateModel({ name: 'fetchDeals', model: res }),
                    updateStep({ step: 'deals' })
                  ]
                ),
              catchError(() => of(addGeneralErrorToast())),
              finalize(() => {
                this.store.dispatch(removeApiCall({ name: 'deals'}));
              })
            )
          )
        )
      );

    fetchEstimation$ = createEffect(() =>
        this.actions$.pipe(
          ofType(fetchEstimation),
          withLatestFrom(this.store.select(state => state.benefits_switch)),
          tap( a => this.store.dispatch( addApiCall({name:'estimation'}) )),
          concatMap(([action, state]) =>
            this.api.getBenefitsLightEstimation(state.model).pipe(
                concatMap(res => [
                    updateModel({ name: 'fetchEstimation', model: res }),
                    updateStep({ step: 'estimation' }),

                  ]
                ),
              catchError(() => of(addGeneralErrorToast())),
              finalize(() => {
                this.store.dispatch(removeApiCall({ name: 'estimation'}));
              })
            )
          )
        )
      );


      fetchClaimBenefit$ = createEffect(() =>
        this.actions$.pipe(
          ofType(fetchClaimBenefit),
          withLatestFrom(this.store.select(state => state.benefits_switch)),
          tap(([action,state]) => this.store.dispatch( addApiCall({name: `_claim#${action.benefit.id}`}) )),
          concatMap(([action, state]) =>
            this.api.claimBenefitsDeal(action.benefit.id).pipe(
              map(res => updateClaimed({ id: action.benefit.id })),
              catchError(() => of({ type: '[Benefit] Error' })),
              finalize(() => {
                this.store.dispatch(removeApiCall({name: `_claim#${action.benefit.id}`}));
              })
            )
          )
        )
      );
}