import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, catchError, distinctUntilChanged, filter, map, of, take, tap } from 'rxjs';
import { modalsMapping } from './modals-mapping';
import { BreakpointObserver } from '@angular/cdk/layout';


@Injectable()
export class ModalService {
  private _modalRef: NgbModalRef;
  private _clean_qp : any = {};
  public is_open : boolean = false;
  public modalChanges : BehaviorSubject<{name: string, is_open: boolean}> = new BehaviorSubject<{name: string, is_open: boolean}>({name: null, is_open: false});

  constructor(
      private _modalService: NgbModal,
      private _activatedRoute: ActivatedRoute,
      private _router: Router,
      private _breakpointObserver: BreakpointObserver,
  ) {

    this._activatedRoute.queryParams.pipe(
      map(params => params['sm']),
      distinctUntilChanged( (prev, curr) => prev === curr ),
    ).subscribe(sm => {
      if (sm && !this._modalRef) {
        const modalComponent = this.getModalComponent(sm);  
        const options = modalComponent.options || {size: 'md'};
        this._modalRef = this._modalService.open(modalComponent.name, {centered: true, ...options});
        
        this._modalRef.hidden.pipe(take(1)).subscribe(() => this.close());
        this.is_open = true;
        this.modalChanges.next({name: sm, is_open: true});
      }
    }); 
  }

  public openFaq(faqTags? : string[]) {
    const qp = faqTags ? {sm : 'faq', tags : faqTags.join(',')} : {sm : 'faq'}

    this._router.navigate([], {
      queryParams: qp,
      relativeTo: this._activatedRoute,
      queryParamsHandling: 'merge'
    }).then().catch();
  }

  public mobile_open_modal(sm, additional_qp){
    const page = sm['url'];

    const currentUrl = this._router.url.split('?')[0];
    const prefix = currentUrl.split('/dashboard')[0];
    const targetPage = page ? `${prefix}/dashboard/${page}` : `${prefix}/dashboard`;
    
    let qps = { ...this._activatedRoute.snapshot.queryParams };
    Object.keys(qps).forEach(key => {
      if (key.includes('tab')) {
      qps[key] = null;
      }
    });
    qps = {...qps, ...additional_qp, ...sm['params']};

    this._router.navigate([targetPage], {
      queryParams: qps,
      relativeTo: this._activatedRoute,
      queryParamsHandling: 'merge'
    }).then().catch();
  }

  public open(sm : string, additional_qp = {}, force_modal = false) {
    if (this._breakpointObserver.isMatched('(max-width: 997px)') && !force_modal && modalsMapping[sm].url) {
      this.mobile_open_modal(modalsMapping[sm], additional_qp);
      return Promise.resolve(null);
    } 


    this._clean_qp = {...this._clean_qp, ...additional_qp};
    this._clean_qp = Object.fromEntries(Object.keys(this._clean_qp).map(key => [key, null]));

    return this._router.navigate([], {
      queryParams: {...additional_qp, sm: sm, },
      relativeTo: this._activatedRoute,
      queryParamsHandling: 'merge'
    }).then( ()=>{
      return this.getResult();
    }
    ).catch(() => {
      return Promise.resolve(null);
    });
  }

  public getResult() {
    if (this._modalRef) {
      return this._modalRef.result;
    }
    return null;
  }

  protected getModalComponent(sm : string) {
    return modalsMapping[sm];
  }

  public close(): void {
    this.is_open = false;
    this.modalChanges.next({name: this.modalChanges.value.name, is_open: false});
    this._router.navigate([], {
      queryParams: {sm: null, tags: null, ...this._clean_qp},
      relativeTo: this._activatedRoute,
      queryParamsHandling: 'merge'
    }).then(() => this._modalRef = null)
      .catch(() => this._modalRef = null);
  }

  public getModalRef() {
    return this._modalRef;
  }

  public subscribeToModalChanges() {
    return this.modalChanges;
  }
}
