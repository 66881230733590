import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductApiService } from 'src/app/services/product-api/product-api.service';

@Component({
  selector: 'app-refer-a-friend',
  templateUrl: './refer-a-friend.component.html',
  styleUrls: ['./refer-a-friend.component.scss']
})
export class ReferAFriendComponent implements OnInit {
  referForm: FormGroup;
  allowedReferals: number = null;
  sent : boolean = false;
  hanging = false;

  constructor(
    protected _product_api: ProductApiService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.referForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });

    const stored_value = localStorage.getItem('allowed_referals');

    if (stored_value == null) {
      localStorage.setItem('allowed_referals', '2');
      this.allowedReferals = 2;
    } else {
      this.allowedReferals = parseInt(stored_value, 10);
    }
  }


  onSubmit() {
    if (!this.referForm.valid) {
      this.referForm.markAllAsTouched();
      return;
    }
    this.hanging = true;

    this._product_api.referAFriend(this.referForm.get('email').value, this.referForm.get('name').value).subscribe(
      (response: any) => {
        this.hanging = false;
        console.log(response);
        this.allowedReferals = this.allowedReferals - 1;
        this.sent = true;
        localStorage.setItem('allowed_referals', this.allowedReferals.toString());
      },
      (error: any) => {
        this.hanging = false;
        console.log(error);
      }
    );
  }

}
