
export type OptimizeBranch = 'energy' | 'mobile';

export interface OptimizeRequest {
    subscription_id : string;
}

export interface OptimizeResponse {
    branch : string;
}

export class ContractLockDetails {
    subscription_id : string
    date : string
    is_locked? : string 

    static getTextData(instance : ContractLockDetails ): string {
        const date = new Date(instance.date); // Replace this with your actual date
        const options:  Intl.DateTimeFormatOptions = { month: 'short', year: 'numeric' };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    }

    static getNumberLockedMonths(instance : ContractLockDetails) {
        const lockDate = new Date(instance.date);
        const now = new Date();
        const months = (lockDate.getFullYear() - now.getFullYear()) * 12;
        const monthDiff = lockDate.getMonth() - now.getMonth();
        return Math.abs(months + monthDiff);
      }
}

export interface GeneralOrderDetails {
    order_type : string
    status : string
    new_deal : BaseDeal
  }

export class MobileCoverage {
    postcode : string;
    items : { network_name : string, coverage_score : number }[];

    static getCoverageScore(coverage_scores : MobileCoverage, name : string) {
        if (!coverage_scores) return undefined;
        const item = coverage_scores.items.find(item => item.network_name === name);
        return item ? item.coverage_score : undefined;    
    }
}

export interface UserAttribute {
    key : string
    value : any
    source : string
}

export interface UserAttributesUpdateRequest {
    attributes : UserAttribute[]
}

export interface CancelDetails {
    already_cancelled : boolean;
}

// Mobile 
export type FilterType = 'Multichoice' | 'YN' | 'Multi-YN' | 'Slider' | 'brand-select'| 'tarif-select' | 'electricity-select';

export interface DealsInteractiveFilter {
    id : number;
    filter_type : FilterType;
    filter_text : string
    filter_choices : string[];

    filter_value : string | boolean[] | number;
}

export interface Gift {
    percent_discount? : number | null;
    num_months? : number | null;
    promo_code? : string | null;
}

export interface BaseDeal {
    id : number | string,
    provider_name : string,
    image_url : string,
    status : string,

    num_months_contract : number, 
    monthly_price : number,
    yearly_save : number
}

export interface Brand {
    name : string;
}

export interface Colour {
    name : string;
}

export interface Model {
    id? : string;
    name? : string;
    brand? : Brand;
    colour? : Colour;
    image_urls? : string[];
    memory? : number;
    screen_size? : number;
    camera_mpx? : number;
    battery_capacity? : number;
    description? : string;
}

export interface MobileDeal extends BaseDeal {
    num_gb_data : string,
    num_minuets : string,
    num_texts : string,
    data_type : string,

    checkout_url : string,
    roaming_plan : string,
    infra_network : string

    gift? : Gift | null;
    model? : Model;
    upfront_cost : number;
}

export interface MobileSwitchObject {
    subscription_id : string
    total_deals? : number
    deals? : MobileDeal[]
    selected_deal_id? : number | string;
    order_result? : string
    is_bundle? : boolean;
    is_handset? : boolean;
    page_number? : number;
}


// Energy 

export interface EnergyAddress {  
    display_name: string;  // FLAT 1 GATEKEEPER HOUSE  1 HARRISON DOWN SOUTH STOKE BATH
    mpan?: string;
    mprn?: string;
  }

export interface EnergyUserAddress {
    postcode?: string;
    display_name?: string;  // Flat 1, 1 Harrison Down, Bath
    years_at_residence?: number;
    months_at_residence?: number;
}

export interface EnergyUser {
    sale_type: string;
    agent_id: string;
    service_type_to_compare: string;
    first_name: string;
    last_name: string;
    gas_supplier?: number;
    energy_gas_tariff_id?: number;
    gas_payment?: string;
    current_tariff_gas_consumption?: number;
    current_tariff_gas_bill?: number;
    elec_supplier?: number;
    energy_elec_tariff_id?: number;
    elec_payment?: string;
    current_tariff_elec_consumption?: number;
    current_tariff_elec_bill?: number;
    e7?: boolean;
    e7_usage?: number;
    new_supplier_name?: string;
    tariff_id?: number;
    bill?: number;
    bill_gas?: number;
    bill_elec?: number;
    saving?: number;
    saving_percentage?: number;
    title?: string;
    telephone_no?: string;
    mobile_no?: string;
    email?: string;
    dob?: string;
    current_address?: EnergyUserAddress;
    billing_address?: EnergyUserAddress;
    previous_address?: EnergyUserAddress;
    previous_address_two?: EnergyUserAddress;
    smart_meter?: string;
    faster_switch?: boolean;
    tariff_position?: number;
    same_current_address?: boolean;
}

export interface EnergyPayment {
    account_name: string;
    sort_code?: string;
    account_number?: string;
    bank_name?: string;
    preferred_day?: number;
    dd_authorisation?: boolean;
    receive_bills?: string;
    supplier_opt_in?: boolean;
    supplier_letter_opt_in?: boolean;
    supplier_phone_opt_in?: boolean;
    supplier_text_opt_in?: boolean;
    special_needs?: boolean;
    payment_method?: string;
}

export interface EnergyOrder {
    user: EnergyUser;
    payment: EnergyPayment;
}

export interface EnergyDeal extends BaseDeal {
    supplier_external_id: number;
    supplier_external_name: string;
    green_certified: boolean;
    fixed_price_months?: number;
    gas_unit_price?: number;
    gas_daily_charge?: number;
    elec_unit_price?: number;
    elec_daily_charge?: number;
}

export interface EnergySwitchObject extends OptimizeRequest {
    subscription_id : string;
    postcode? : string;
    addresses?: EnergyAddress[];
    selected_address?: EnergyAddress;
    selected_address_id?: string;
    deals?: EnergyDeal[]
    total_deals?: number;
    selected_deal_id?: number;
    order?: EnergyOrder;
    order_result?: string;
}

export interface EnergyTariff {
    image_name?: string;
    tariff_id?: number;
    tariff_name?: string;
    supplier_id?: number;
    supplier_name?: string;
    supplier_code?: string;
    supplier_telephone?: string;
    supplier_cooling_off?: string;
    region_id?: number;
    region_name?: string;
    payment_method?: string;
    payment_method_name?: string;
    e7?: boolean;
    bill?: number;
    saving?: number;
    saving_percentage?: number;
    units?: number;
    service_type?: string;
    service_type_name?: string;
    action?: boolean;
    tariff_valid_from_date?: string;
    tariff_effective_date?: string;
    tariff_valid_to_date?: string;
    tariff_end_date_type?: string;
    tariff_end_date_period_fixed?: string;
    contract_length?: number;
    exit_penalty_amount?: number;
    exit_penalty_end_date?: string;
    campaign_name?: string;
    cashback?: number;
    supplier_rating?: number;
    summary_content?: string;
    standing_charge_gas?: number;
    price1_gas?: number;
    threshold1_gas?: number;
    price2_gas?: number;
    threshold2_gas?: number;
    price3_gas?: number;
    threshold3_gas?: number;
    price4_gas?: number;
    standing_charge_elec?: number;
    price1_elec?: number;
    threshold1_elec?: number;
    price2_elec?: number;
    threshold2_elec?: number;
    price3_elec?: number;
    threshold3_elec?: number;
    price4_elec?: number;
    price_e7?: number;
    discount_amount_gas?: number;
    discount_amount_elec?: number;
    discount_amount_df?: number;
    features?: string;
    tariff_type?: string;
    tariff_end_date?: string;
    exit_penalty_gas?: number;
    exit_penalty_elec?: number;
    tcr_gas?: number;
    tcr_elec?: number;
    bill_gas?: number;
    bill_elec?: number;
}

// Mortgage 

export interface MortgageSwitchObject extends OptimizeRequest {
    
}

// Cancel 

export interface CancelSubscriptionInput {
    name : string;
    type : 'email' | 'phone' | 'postcode' | 'text' | 'multichoice';
    allowed_values? : string[],
    value?: string;
}


export interface CancelSwitchObject extends OptimizeRequest {
    inputs? : CancelSubscriptionInput[];
    result? : string;
}

// Broadband

export interface GeneralAddress {
    display_name : string;
    id : string;
  }

export interface BroadbandDeal extends BaseDeal {
    name : string,
    
    download_speed : number,
    upload_speed : number,
    upfront_fee : number,
    is_fiber : boolean,
    has_landline : boolean,
    
    terms_url : string,
    privacy_url : string,
    external_switch? : boolean;
    checkout_url? : string;
}

export interface BroadbandOrderGeneralDetails {
    title? : string;
    firstName? : string;
    lastName? : string;
    email? : string;
    contactNumber? : string;
    dob? : string;
}

export interface BroadbandOrderAddressHistory {
    addressSince : string;
    prev_address? : {postcode: string, address_id : string};
    employee_status : string;
}



export interface BroadbandDealLandline {
    keep_number?: boolean;
    number?: string;
    protable?: boolean;
}

export interface BroadbandDealInstallationTimeSlot {
    id : string
    startTime : string
    endTime : string
}

export interface BroadbandDealInstallationAppointment {
    date: string;
    timeSlots: BroadbandDealInstallationTimeSlot[];
}

export interface BroadbandDealInstallation {
    hasInstallationInfo: boolean;
    isInstallationRequired?: boolean;
    goLiveDate?: string;
    installationAppointments?: BroadbandDealInstallationAppointment[];
}

export interface BroadbandDealLandlineSpeedRange {
    lowerMbpsSpeedRange: number;
    upperMbpsSpeedRange: number;
}

export interface BroadbandDealSpeedInfo {
    hasKnownSpeed: boolean;
    downloadSpeed?: BroadbandDealLandlineSpeedRange;
    uploadSpeed?: BroadbandDealLandlineSpeedRange;
    guaranteedMbpsSpeed?: number;
    guaranteedUploadMbpsSpeed?: number;
}

export interface BroadbandDealDetails{
    installation_details? : BroadbandDealInstallation;
    landline_details? : BroadbandDealLandline;
    speed_info? : BroadbandDealSpeedInfo;
}

export interface BroadbandOrderInstallationAndLandline {
    landlineNumber? : string;
    installationAppointmentDate? : string;
    installationAppointmentTimeSlot? : BroadbandDealInstallationTimeSlot;
}

export interface BroadbandOrder {
    generalDetails? : BroadbandOrderGeneralDetails;
    credit_check? : BroadbandOrderAddressHistory;
    installationAndLandline? : BroadbandOrderInstallationAndLandline;
}

export interface BroadbandPaymentDetails {
    sortCode : string;
    accountNumber : string;
}

export interface BroadbandSwitchObject extends OptimizeRequest {
    postcode? : string;
    addresses? : GeneralAddress[];
    contract_lock? : ContractLockDetails;
    selected_address_id? : string;
    total_deals? : number;
    deals? : BroadbandDeal[];
    recommended_ids? : string[];
    selected_deal_id? : string | number;
    deal_details? : BroadbandDealDetails;

    order? : BroadbandOrder;
    payment? : string;
    order_result? : string;
}
