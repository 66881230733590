import { PaylowBadge } from './paylow-badge.model';

interface MockPlanBase {
    name: string;
    id: string;
    description: string;
    logoUrl: string | null;
    price: number;
    paymentPeriod: string;
}  

export interface MockActivePlan extends MockPlanBase { }  

export interface MockSuggestedPlan extends MockPlanBase { 
    reason: string;
    yearlySave?: number;
    reasonHighlight?: string;
}  

export interface MockSubscriptionItem { 
    name: string;
    logoUrl: string;
    tags: string[];
    activePlan: MockActivePlan;
    badges: PaylowBadge[];
    plans: MockSuggestedPlan[];
    activeFlow: 'cancel' | 'switch' | 'none';
isSwitching?: boolean;
isCanceled?: boolean;
}