import { Directive, ElementRef, Renderer2, OnInit, DebugEventListener } from '@angular/core';

@Directive({
  selector: '[appIsInsideModal]'
})
export class IsInsideModalDirective implements OnInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    const isInsideModal = this.hasAncestorWithClass(this.el.nativeElement, 'modal-content');
    if (!isInsideModal) {
      this.renderer.addClass(this.el.nativeElement, 'd-none');
    } 
  }

  private hasAncestorWithClass(element: HTMLElement, className: string): boolean {
    let parent = element.parentElement; // Start with the parent element
    while (parent) {
      if (parent.classList.contains(className)) {
        return true; // Found the ancestor with the specified class
      }
      parent = parent.parentElement; // Move to the next parent
    }
    return false; // No ancestor with the specified class found
  }
}
