import { Component, Inject, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UnlistedBankModalComponent } from '../bank-selection/unlisted-bank-modal/unlisted-bank-modal.component';
import { FadeAnimation } from 'src/app/animations/simple_animations';
import { Stepper } from 'src/app/helpers/stepper';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { ProductService } from 'src/app/services/product-service/product-service.service';
import { CommonBankProvider } from 'src/app/types/product-consent.model';
import { CONSENT_SERVICE_TOKEN } from 'src/app/services/consent/consent-token.di';
import { BaseConsentService } from 'src/app/services/consent/base-consent.service';

@Component({
  selector: 'app-consent-modal',
  templateUrl: './consent-modal.component.html',
  styleUrls: ['./consent-modal.component.scss'],
  animations: [FadeAnimation]
})
export class ConsentModalComponent implements OnInit {

  caller = "add-provider";
  stepper = new Stepper();
  provider = null;

  constructor(
    public _activeModal: NgbActiveModal,
    public _activated_route: ActivatedRoute,
    protected _product: ProductService,
    @Inject(CONSENT_SERVICE_TOKEN) public _consentService: BaseConsentService,
  ) { }


  ngOnInit(): void {
    this.caller = this._activated_route.snapshot.queryParams['caller'] || null;
    
    if (this.caller === "add-provider") {
      this.stepper.setStep(1);
    } else {
      this.stepper.setStep(2);
    }
  }

  public selectBank(provider: CommonBankProvider) {
    this.provider = provider;
    this.stepper.setStep(3);
  }

  public consentAgree(provider: CommonBankProvider) {
    this.provider = provider;
    this._consentService.selectBankProvider(provider);
  }

  public consentDisagree() {
    this.provider = null;
    this.stepper.setStep(2);
  }

}
