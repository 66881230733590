import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable, map, tap } from 'rxjs';


export class Stepper {
    private delay_duration : number;
    private first_update : boolean = true;

    private timer : any;
    private prev_step : number;
    private _step_from : number = null;
    private _step_to : number = null;
    private _step  = null;
    private _hanging_step = false;
    private _hanging_type = null;

    private step$ = new BehaviorSubject<number>(null);


    constructor(_activatedRoute : ActivatedRoute = null,  qp_name : string = 'step', delay_duration = 500) {
        this.delay_duration = delay_duration;
        
        if (_activatedRoute != null) {
            this.bindToQueryParam(_activatedRoute, qp_name);
        }
    }

    public bindToQueryParam(_activatedRoute : ActivatedRoute, qp_name : string) {
        _activatedRoute.queryParams.subscribe(
            (queryParams: any) => {
                let qp_step = +queryParams[qp_name] || 1;
                this.setStep(qp_step);
                
            }
        );
    }

    public setHanging(hanging_type = null) {
        if (this.timer != null) clearTimeout(this.timer);
        this._hanging_step = true;
        this._hanging_type = hanging_type;
    }

    public unsetHangging() {
        this._hanging_step = false;
        this._hanging_type = null;
    }

    public setStep(new_step) {
        if(this.step == new_step) {
            // False navigation
            this._hanging_step = false;
            this._hanging_type = null;
            return;
        }

        this.prev_step = this._step;
        
        this._step_from = this._step;
        this._step_to = new_step;

        this._step = null;

        this.timer = setTimeout(() => {
            this._step = this._step_to;
            this._hanging_step = false;
            this._hanging_type = null;
            this.step$.next(this._step);
            this.timer = null;
        }, this.first_update ? 0 : this.delay_duration);
        this.first_update = false;
    }

    public StepObserveable() { return this.step$.asObservable(); }

    /**
     * For the triplet (from, curr, to) going from X to Y the timeline is
     * (time=[0, delay]), (X, null, Y) -> Begin(null, Y)=true, End(null, Y)=false
     * (time=[delay, ]), (X, Y, Y) -> Begin(null, Y)=true, End(null, Y)=true
     * @param from 
     * @param to 
     * @returns 
     */
    private isTransition(from : number, to) { return (to == null || this._step_to == to) && (from == null || this._step_from == from); }
    public isTransitionBegin(from : number, to) { return this._step == to || this.isTransition(from, to) }
    public isTransitionEnd(from : number, to : number) { return this._step == to && this.isTransition(from, to) }
    public get step() { return this._step; }
    public get stepWithTransition() { return this._step || this.prev_step; }
    public get stepWithNext() { return this._step || this._step_to; }

    equals(other ): boolean { return this.isTransitionEnd(null, other) && !this._hanging_step; }
    public is_hanging() { return this._hanging_step; }
    public is_hanging_type(type) { return this._hanging_step && this._hanging_type == type; }
}