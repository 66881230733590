import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, map, Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { updateAppConfig } from 'src/app/components/global/session/state';

export interface AppConfig {
    cognito_client_id: string;
    client_id: string;
    client_type: string;
    verification_method: string;
    parent_domain: string;
    colors?: {
        primary: string;
        secondary: string;
    };
}

@Injectable()
export class AppConfigService {
    private _appConfig: AppConfig;

    constructor(
        private _httpClient: HttpClient,
        private _activatedRoute: ActivatedRoute,
        private store : Store
    ) { }

    public async config() {
        const configKey = 'appConfig';
        const configTimestampKey = 'appConfigTimestamp';

        let cacheAllowedAge = 5 * 60 * 60 * 1000;
        if (this._activatedRoute.snapshot.queryParams['skip_cache']) {
            cacheAllowedAge = 0;
        }

        const storedConfig = localStorage.getItem(configKey);
        const storedTimestamp = localStorage.getItem(configTimestampKey);
        const now = new Date().getTime();

        if (storedConfig && storedTimestamp && (now - parseInt(storedTimestamp, 10)) < cacheAllowedAge) {
            this._appConfig = JSON.parse(storedConfig) as AppConfig;
            console.log('[CONF] AppConfig cache load:', this._appConfig);
        } else {
            await this.getAppConfig().then((appConfig: AppConfig) => {
                this._appConfig = appConfig;
                localStorage.setItem(configKey, JSON.stringify(appConfig));
                localStorage.setItem(configTimestampKey, now.toString());
                console.log('[CONF] AppConfig fetched load:', this._appConfig);
            });
        }
        this.applyConfig();
        this.store.dispatch(updateAppConfig({appConfig: this._appConfig}));
        return this._appConfig;
    }

    private applyConfig() {
        // Colors
        if (!this._appConfig) {
            return;
        }
        if (this._appConfig.colors) {
            const theme = {
                'primary': '#111111',
                'secondary': '#5d5a5a'
            }
            document.documentElement.style.setProperty('--paylow-primary-color', theme.primary || 'var(--default-primary-color)');
            document.documentElement.style.setProperty('--paylow-primary-text-color', theme.primary || 'var(--default-primary-text-color)');
            document.documentElement.style.setProperty('--paylow-secondary-color', theme.secondary || 'var(--default-secondary-color)');
        }
    }

    protected getAppConfig(): Promise<AppConfig> {
        const headers = {
            'Authorization': '',
            'Content-Type': 'application/json',
        };

        return lastValueFrom(
            this._httpClient.request<AppConfig>('GET', `${environment.api_hostname}/app`, { headers: headers }).pipe(
            map((res: any) => {
                if (res.statusCode !== 200) {
                    console.error('Error fetching app config:', res);
                    throw new Error(`Error fetching app config: ${res.body.msg}`);
                }
                return res.body as AppConfig;
            }),
            tap((appConfig) => { console.log('AppConfig fetched:', appConfig); }),
            catchError((error) => {
                throw error;
            })
            )
        );
    }
}
