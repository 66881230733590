import { Renderer2 } from "@angular/core";




export class UserActivityDetection {

  private activity_listener: any;
  private inactivityStartTime: number = 0;

  constructor(
    private renderer: Renderer2,
  ) { }

  public setupActivityDetection(handler: any) {
    if (!this.activity_listener) {

      this.activity_listener = this.renderer.listen('window', 'visibilitychange', () => {
        if (document.hidden) {
          console.log('User is inactive');
          this.inactivityStartTime = Date.now();
        } else {
          if (this.inactivityStartTime > 0) {
            const away_duration = (Date.now() - this.inactivityStartTime) / 1000;
            console.log(`user activity resumed after ${away_duration}`);
            if (away_duration > 1) {
              if (handler) {
                handler();
                this.removeActivityDetection();
              }
            } else {
              this.inactivityStartTime = 0;
              this.removeActivityDetection();
            }
          }
        }
      });

    }
  }

  public removeActivityDetection() {
    if (this.activity_listener) {
      this.activity_listener();
      this.activity_listener = null;
    }
  }
}