export const environment = {
  disable_mobile : false,
  production: false,
  staged: true,
  demo: false,

  type_circlet: true,

  amp_tracking: true,
  amp_session_tracking: true,
  demo_target: '',

  branding: '',

  base_url: 'https://paylow-app-product-git-staged-paylow.vercel.app',
  cognito_federated_domain: '',
  cognito_pool_id: 'eu-central-1_QxoomXPbU',
  disable_functions: [],

  api_hostname: 'https://product-api.paylow.app',  
  sensitive_data_public_rsa_key: '-----BEGIN PUBLIC KEY-----\\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA1r4GWyPbGWjogN6N6AHJ\\n3+0lfqRFRAfd9rMrYon19gI4dKSRld7JwPPs2hTwmfrQoOjj2E+14cZxQt9XnmJG\\n6M4VsNg9sesQkhxgd12sfM+M/NLlyYfzK+OpNGGb3bb0i8OyNtu4NXKI51Gey58t\\nvUyqtbz+poD9nR3/WgKqO2o2lcmz2Pkaj5QClW9jHWcpESrrb66oEj8u96zqBGex\\njjIB/tgPpZLyBEt/qpwdTN+s0OvQwfEL7vJy5GQkUFjc+glBlmhFdy4gxVdfJJjr\\nGoOwfUrtq2f8yDTmteXBHV/i0AU8mU91mSC2jGBj1b5dsU02u46D5YM2lypuQcGM\\nlwIDAQAB\\n-----END PUBLIC KEY-----'
};
