import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createAction, createReducer, on, props } from '@ngrx/store';

export const updateJourneyObject = createAction('[Popup] updateJourneyObject', props<{ updated_state : JourneyState }>());


export interface JourneyState {
    dashboard_exists : boolean;
}


export const initialState: JourneyState = {
    dashboard_exists: null,
};


export const journeyReducer = createReducer(
  initialState,
  on(updateJourneyObject, (state, { updated_state }) => ({ ...state, ...updated_state})),

);

@Injectable()
export class JourneyEffects {
  constructor(
    private actions$: Actions,
  ) { }

}

