import { Amplify, Auth } from 'aws-amplify';
import { BehaviorSubject, catchError, filter, map, Observable, of, switchMap, tap } from 'rxjs';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { AppConfig, AppConfigService } from '../app_config/app-config.service';
import { Store } from '@ngrx/store';
import { updateModuleInitialized } from 'src/app/components/global/session/state';
import { environment } from 'src/environments/environment';
import { logout } from 'src/app/app-actions';
import { Router } from '@angular/router';

export abstract class BaseAuthService {
  protected pool_config: any;
  private isConfiguredSubject = new BehaviorSubject<boolean>(false);
  public isConfigured$ = this.isConfiguredSubject.asObservable();

  constructor(
    public appConfig: AppConfigService, 
    public store: Store,
    public _router : Router
  ) {
    this._setConfig();
  }

  abstract initiateLoginFlow(login_args: any): Promise<boolean>;

  protected _setConfig = async (): Promise<void> => {
    
    const base_url = this.getBaseUrl();
    const userPoolId = this.getUserPoolId();
    const config = await this.getConfigObject();

    if (!config || !config.cognito_client_id || !userPoolId) {
      console.error('Error: App configuration stage   failed');
      this.isConfiguredSubject.next(false);
      return;
    }

    this.pool_config = {
      region: 'eu-central-1',
      userPoolId: userPoolId,
      userPoolWebClientId: config.cognito_client_id,
      identityPoolId: null,
    };

    const fed = {
      domain: environment.cognito_federated_domain,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: `${base_url}/sso-callback`,
      redirectSignOut: `${base_url}`,
      clientId: config.cognito_client_id,
      responseType: 'token',
    }; 

    this.redirectOauthResponse();
    Amplify.configure({
      Auth: this.pool_config,
      oauth:  config.client_type != 'circlet' ? fed : null,
      // oauth:  fed,
      Storage: {
        AWSS3: {
          bucket: 'product-demo-files',
          region: 'eu-central-1',
          defaultLevel: 'private',
        },
      },
    });

    this.store.dispatch(updateModuleInitialized({ module: 'auth' }));
    console.log('[CONF] Amplify configured', Amplify.Auth);
    this.isConfiguredSubject.next(true);
  }

  private waitForConfig<T>(obs: () => Observable<T>): Observable<T> {
    return this.isConfigured$.pipe(
      // tap((isConfigured) => console.log('isConfigured before filter', isConfigured)),
      filter(isConfigured => isConfigured),
      // tap((isConfigured) => console.log('isConfigured after filter', isConfigured)),
      switchMap(
        () => obs()
      )
    );
  }

  public isAuthenticated(): Observable<boolean> {
    return this.waitForConfig(() =>
      fromPromise(Auth.currentAuthenticatedUser()).pipe(
        map(() => true),
        catchError(() => of(false))
      )
    );
  }

  public getUser(bypassCache: boolean = false): Observable<CognitoUser> {
    return this.waitForConfig(() => fromPromise(Auth.currentAuthenticatedUser({ bypassCache: bypassCache })));
  }

  public signOut(route: string[] = ['login']): Promise<any> {
    this.store.dispatch(logout());
    
    if (route) {
      return Auth.signOut().then(res => {
        this._router.navigate(route);
      }
      )
    } else {
      return Auth.signOut();
    }
  }

  protected getUserPoolId(): string {
    return environment.cognito_pool_id;
  }

  protected getBaseUrl(): string {
    if (window.location.hostname === 'localhost') {
      return `http://${window.location.host}`;
    }
    return `https://${window.location.hostname.split('.')[0]}.paylow.app`;
  }

  protected async getConfigObject(): Promise<AppConfig> {
    return await this.appConfig.config();
  }

  redirectOauthResponse() {
    const currentUrl = window.location.href;
    if (currentUrl.includes('consent-callback?')) {
      const hasFragment = currentUrl.split('?')[1];
      const [baseUrl, queryString] = currentUrl.split('?');
      const urlWithoutFragment = baseUrl.split('?')[0];

      const params = new URLSearchParams(queryString || '');

      if (hasFragment && (params.has('code') || params.has('error'))) {
        const newParams = new URLSearchParams();
        params.forEach((value, key) => {
          newParams.set(`mh_${key}`, value);
        });

        const newUrl = `${urlWithoutFragment}#${newParams.toString()}`;
        window.location.href = newUrl;
      }
    }

    if (currentUrl.includes('consent-callback#')) {
      const hasFragment = currentUrl.split('#')[1];
      const [baseUrl, queryString] = currentUrl.split('#');
      const urlWithoutFragment = baseUrl.split('#')[0];

      const params = new URLSearchParams(queryString || '');

      if (hasFragment && (params.has('code') || params.has('error'))) {
        const newParams = new URLSearchParams();
        params.forEach((value, key) => {
          newParams.set(`mh_${key}`, value);
        });

        const newUrl = `${urlWithoutFragment}#${newParams.toString()}`;
        window.location.href = newUrl;
      }
    }
  }
  
  resetAuthService() {
    return Promise.resolve();
  }

}
