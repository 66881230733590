import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { fetchNotifications } from 'src/app/components/product/dashboard/feed/state/state';

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {

  constructor(private store: Store) {}


  protected notifications_resources : string[] = [
    'attribute/update',
    'dashboard/update-category',
    'dashboard/process',
    'dashboard/coming-soon',
    'optimize/broadband/order',
    'dashboard/customization/add-sub',
    'dashboard/customization'
  ]

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          if (this.notifications_resources.some(endpoint => req.url.includes(endpoint))) {
            this.store.dispatch(fetchNotifications());
          }
        }
      })
    );
  }
}
