import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {filter, map, Subscription} from "rxjs";
import { ProductTrackerService } from 'src/app/services/product-tracker/product-tracker.service';

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss']
})
export class ProfileModalComponent implements OnInit, OnDestroy {

  public tabNames: string[] = ['My Account', 'Contact Us', 'Logout'];
  public subTabs = {
    'Disconnect': ['Logout', 'Delete my account'],
    'FAQ': ['About Paylow', 'Using Paylow', 'Account and Data', 'Security and Compliance', 'General'],
    'Personal Account': null,
  } 

  public selectedTab: string = 'My account';
  public selectedSubTab: string = 'My account';
  public faq_tags: string[] = null;

  public FAQ_terminate: boolean = false;

  private _subs = new Subscription();

  constructor(public _activeModal: NgbActiveModal,
              private _activatedRoute: ActivatedRoute,
              private _router: Router,
              private product_tracker : ProductTrackerService
              ) {
  }

  ngOnInit(): void {
    this._subs.add(
      this._activatedRoute.queryParams.pipe(
        filter(queryParams => !!queryParams['settingsTab']),
      ).subscribe(qp => { 
        this.selectedTab = qp['settingsTab'];
        this.selectedSubTab = qp['subTab'];

        if(this.selectedTab == 'FAQ') {
          this.faq_tags = this.faqTags();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public onTabClick(tab: string): void {
    this.product_tracker.setTag('view_settings', { tab: tab})

    this._router.navigate([], {
      queryParams: {
        settingsTab: tab,
      },
      relativeTo: this._activatedRoute,
      queryParamsHandling: 'merge'
    }).then().catch();
  }

  public faqTags() {
    switch(this.selectedSubTab) {
      case 'About Paylow':
        return ['about'];
      case 'Using Paylow':
        return ['usage'];
      case 'Account and Data':
        return ['account'];
      case 'Security and Compliance':
        return ['secure'];
      case 'General':
        return ['general'];
      default:
        return null;
    }
  }

  public onCancelClick() {
    this._activeModal.dismiss('Cancel');
  }

  public onSaveClick() {
    this._activeModal.close('SAVE');
  }
}
