<div class="modal-content" style="min-height: 212px">
  <button class="d-none d-lg-flex modal-close-btn" autofocus="false" (click)="_activeModal.dismiss()">
    <img alt="close panel button" src="assets/icons/thick-x.svg">
  </button>

  <div class="d-flex flex-column text-center gap-16 align-items-center" *ngIf="stepper.equals(1)" [@FadeAnimation]>
    <div class="d-flex flex-column gap-8">
      <span class="subtitle1">Lets track your Telecom provider</span>
      <span class="body2">Link your relevant bank account to maximise saving on telecom bills and switch to personalised
        alternatives.</span>
    </div>
    <button class="btn btn-primary" (click)="stepper.setStep(2)">
      <i class="bi bi-lightning-fill"></i>
      Next
    </button>
  </div>

  <div class="flex-grow-1" *ngIf="stepper.equals(2)" [@FadeAnimation]>
    <app-bank-selection (selectBank)="selectBank($event)" [compact_view]="true">
    </app-bank-selection>
  </div>

  <div class="h-100" *ngIf="stepper.equals(3)" [@FadeAnimation]>
    <app-bank-connection class="responsive-scale-left" [provider]="provider"
      (done)="consentAgree($event)" (continueClick)="consentDisagree()"></app-bank-connection>
  </div>

</div>

<div class="loading-connection" *ngIf="_consentService.bankConnection" [@FadeAnimation]>
  <app-bank-provider-loading-modal></app-bank-provider-loading-modal>
</div>