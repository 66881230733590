import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, filter, map, take, tap } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { PersonalizationModel } from 'src/app/types/beta-dashboard.model';
import { ProductApiService } from 'src/app/services/product-api/product-api.service';
import { ProductService } from 'src/app/services/product-service/product-service.service';
import { GeneralComponent } from './general/general.component';
import { Store } from '@ngrx/store';
import { UserAttributesState, fetchBatchUpdate } from './state';
import { ModalService } from 'src/app/services/modal-service/modal-service.service';
import { addToast } from '../../../state/product-state.state';
import { FeedState } from '../../../dashboard/feed/state/state';

interface PersonalizationItem {
  name : string;
  coming_soon : boolean;
}

export function html_id(str) {
  return str.replace(/[^a-zA-Z0-9]/g, "_");
}

@Component({
  selector: 'app-personalization-modal',
  templateUrl: './personalization-modal.component.html',
  styleUrls: ['./personalization-modal.component.scss']
})
export class PersonalizationModalComponent implements OnInit {

  @ViewChild(GeneralComponent) generalChildComponent!: GeneralComponent;

  closing = false;
  tabsNames : PersonalizationItem[] = [ 
    {name: 'General', coming_soon: false},
    {name: 'Mobile (Sim)', coming_soon: false},
    {name: 'Mobile (Handset)', coming_soon: false},
    {name: 'Broadband', coming_soon: false},
    {name: 'Energy', coming_soon: false},

    // {name: 'Energy', coming_soon: true},
  ]

  selectedTab: string = 'General';
  target: string = '';
  public notifications;
  public is_mobile_missing = false;
  public is_broadband_missing = false;


  update_status = this.store.select(state => state.user_attributes).pipe(
    map(state => state.api_calls.filter(x=>x?.includes('update_attribute')).length > 0 ? 'updating' : 
                 state.pre_updated_batch?.length > 0 ? `${state.pre_updated_batch?.length} to update` : ''),
  );

  constructor(
    protected _router: Router,
    private _activatedRoute: ActivatedRoute,
    public _activeModal: NgbActiveModal,
    public _api : ProductApiService,
    public _product : ProductService,
    public _modal : ModalService,
    public _ngbModal: NgbModal,
    protected store: Store<{ user_attributes: UserAttributesState }>,
    protected feed_store: Store<{ feed: FeedState }>,

  ) { 

    this._modal.modalChanges.pipe(filter(o=>o.name == 'personalization' && o.is_open), take(1)).subscribe( (modal) => {
      this._modal.getModalRef().hidden.subscribe(() => { this.updateAttributes(); });
    });
  }

  ngOnInit(): void {
    this.notifications = this.feed_store.select(state => state.feed.disply_notifications);

    this.notifications.subscribe(notifications => {
      this.is_mobile_missing = notifications?.some(notification => notification.type === "missing_mobile");
      this.is_broadband_missing = notifications?.some(notification => notification.type === "missing_broadband");
    });
    
    this._activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams['tags']) {
        this.selectedTab = queryParams['tags'];
      }
      if (queryParams['target']) {
        this.target = queryParams['target'];
      }
    });
  }

  public onTabClick(tab, event: MouseEvent): void {
    if (tab.coming_soon) {
      const button = event.currentTarget as HTMLElement;
      button.classList.add('clicked');
      setTimeout(() => {
        button.classList.remove('clicked');
      }, 100);
      return;
    }
    this._router.navigate([], {
      queryParams: {
        tags: tab.name,
      },
      relativeTo: this._activatedRoute,
      queryParamsHandling: 'merge'
    }).then().catch();
  }

  onDone() {
    this._activeModal.dismiss();
  }

  updateAttributes() {
    this.store.select(state => state.user_attributes).pipe(take(1)).subscribe(state => {
      if (state.pre_updated_batch?.length > 0) {
        this.store.dispatch(addToast({title: 'Updating', text: 'Personalising...', toast_type: 'hanging'}));
        this.store.dispatch(fetchBatchUpdate());
      }
    });
  }

 openBankModal() {
  this._activeModal.dismiss();
  this._modal.open('bank_selection');
  }

}
