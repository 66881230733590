
<div class="d-flex flex-column align-items-center gap-16">
    <button class="modal-close-btn" autofocus="false" (click)="_activeModal.dismiss()" appIsInsideModal> >
        <img alt="close panel button" src="assets/icons/thick-x.svg">
      </button>

    <div class="d-flex flex-column gap-16 p-32" *ngIf="this.stepper.equals('submit')" [@FadeAnimation]>           
        <div class="d-flex flex-column">
            <span class="subtitle1 paylow-dark-purple-text">Please select the new category:</span>
        </div>
        <div class="d-flex flex-wrap gap-8">
            <div *ngFor="let category of category_tabs" (click)="removeCategory(category)" class="category-tag d-flex align-items-center paylow-purple-text clickable">
              {{ category }} <i class="bi bi-x fs-6"></i>
            </div>
          </div>
        <div class="d-flex gap-16">
        <div class="d-flex justify-content-between align-items-center">
            <select class="p-8 select-scroll" id="category" [(ngModel)]="category_value" >
              <option *ngFor="let c of this.categories; let i = index" [value]="c" >{{c}}</option>
            </select>
        </div>
        <div *ngIf="category_value == 'Other'">
            <input type="text" class="input-category" placeholder="Category" [(ngModel)]="input_category_value">
        </div>
        <button class=" btn purple-badge" [disabled]="!category_value" (click)="changeCategory()">
            <i class="fa fa-spinner fa-spin" *ngIf="hanging"> </i> {{ !hanging ?  'Add' : '' }} 
        </button>
    </div>
    </div>
    
    <div class="d-flex flex-column align-items-center gap-16 p-32" *ngIf="this.stepper.equals('post-submit')" [@FadeAnimation]>
        <div class="d-flex flex-column align-items-center text-center">
            <i class="bi bi-check-circle icon-badge " ></i>
            <span class="subtitle1 paylow-dark-purple-text">Your request has been submitted.</span>
        </div>
    </div>

</div>
