import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FadeAnimation } from 'src/app/animations/simple_animations';

import { Stepper } from 'src/app/helpers/stepper';
import { dealSelect, stepNext, updateStep, InterestsOffer, setSelectedOfferId, updateClaimed, setDisplayMode, fetchClaimed } from '../switch-panel-interests/state';
import { Store } from '@ngrx/store';
import { ProductTrackerService } from 'src/app/services/product-tracker/product-tracker.service';
import { ProductService } from 'src/app/services/product-service/product-service.service';
import { distinctUntilChanged, map, take } from 'rxjs';
import { InterestsSwitchState } from './state';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserActivityDetection } from 'src/app/helpers/activityDetection';
import { RedirectModalComponent } from '../../../common/modals/redirect-modal/redirect-modal.component';


@Component({
  selector: 'app-switch-panel-interests',
  templateUrl: './switch-panel-interests.component.html',
  styleUrls: ['./switch-panel-interests.component.scss'],
  animations: [FadeAnimation]

})
export class SwitchPanelInterestsComponent implements OnInit, OnDestroy {
  public activityDetection: UserActivityDetection;
  public stepper = new Stepper();
  public step_names = ['Select deal', 'Deal'];
  @Input() mode = 'standalone' as 'standalone' | 'bundle';
  public request_feedback: boolean;
  public switch_hanging = false;
  public selected_deal_id: string;
  public selected_deal_url: string
  public dissmised_offers : string[]
  public display_offers: InterestsOffer[]
  public deals: InterestsOffer[]

  interests_display_mode$ = this.store.select(state => state.interests_switch.display_mode)

  public steps = [
    { display_name: 'Select deal', name: 'select-deal', index: 0 },
    { display_name: 'Deal', name: 'deal', index: 1 },

  ]


  get step_index() {
    const item = this.steps.find(s => s.name == this.stepper.stepWithTransition);
    if (!item) return 0;
    return item.index + 1;
  }

  public deals$ = this.store.select(state => state.interests_switch.model.offers);
  public dissmised_offers$ = this.store.select(state => state.interests_switch.model.dismissed);

  

  constructor(
    protected store: Store<{ interests_switch: InterestsSwitchState }>,
    private product_tracker: ProductTrackerService,
    protected product: ProductService,
    private renderer: Renderer2,
    protected _activeModal : NgbActiveModal,
    private  ngbModal: NgbModal,


  ) {
    this.store.select(state => state.interests_switch.api_calls)
      .pipe(map(calls => calls.filter(c => !c?.startsWith('_'))), distinctUntilChanged())
      .subscribe(r => {
        if (r.length > 0 && !this.stepper.is_hanging_type(r[0])) {
          this.stepper.setHanging(r[0]);
        } else if (r.length == 0) {
          this.stepper.unsetHangging();
        }
      });

    this.store.select(state => state.interests_switch.step)
      .pipe(distinctUntilChanged())
      .subscribe(r => {
        if (r != this.stepper.stepWithNext) {
          this.stepper.setStep(r);
        }
      });
    // this.store.dispatch(initiate());
    this.store.dispatch(updateStep({step:'select-deal'}));
  }
  ngOnDestroy(): void {
    this.store.dispatch(updateStep({step:''}));
  }

  ngOnInit(): void {

    this.activityDetection = new UserActivityDetection(this.renderer);


    this.store.dispatch(setDisplayMode({mode: 'active'}))

    this.deals$.subscribe(deals => {
      this.deals = deals;
    });
  
    this.store.select(state => state.interests_switch.model.dismissed).subscribe(dismissed => {
      // Ensure dismissed is an array before filtering
      const validDismissed = Array.isArray(dismissed) ? dismissed : [];
      
      this.display_offers = this.deals?.filter(deal => !validDismissed.includes(deal.offer_id));
    });
    

    // this.store.dispatch(addSubscription({ subscription_id: s.id, model: { subscription_id: s.id, is_handset: true, page_number: 1 } }));
  }

  public nextClicked(source) {
    this.store.dispatch(stepNext());
  }

  public dealClicked(deal) {
    this.store.dispatch(dealSelect({ deal_id: deal.id }));
    this.product_tracker.setTag("switch.interests.step", { step: this.step_names[this.stepper.stepWithTransition] });
  }

  public stepClicked(step) {
    const step_name = this.steps.find(s => s.index == step - 1)?.name;
    if (!step_name) return;
    const current_step_nunber = this.step_index;
    this.store.dispatch(updateStep({ step: step_name })); //todo

    if (current_step_nunber > step) {
      if (false) {

        return;
      } else {
        this.store.dispatch(updateStep({ step: step_name }));
      }
    }
  }


  public dealCheckout(offer: InterestsOffer) {
    this.switch_hanging = true;

    this.store.dispatch(setSelectedOfferId({ offer_id: offer.offer_id.toString() }));

    const modalRef = this.ngbModal.open(RedirectModalComponent, {
      centered: true,
      backdrop: 'static'
    });

    if (this.activityDetection) {
    this.activityDetection.setupActivityDetection(() => { this.request_feedback = true; });

    }
    this.product_tracker.setTag("interest.offer.checkout", { id: offer.offer_id});



    modalRef.componentInstance.logo_url = offer.logo_url; 
    modalRef.componentInstance.name = offer.title;
    modalRef.componentInstance.amount = offer.price;
    modalRef.componentInstance.url = offer.checkout_url;
    modalRef.componentInstance.category = 'interests';
  }

  public switchFeedback(feedback: boolean) {
    //get the selected deal id and add to the dispatch

    this.request_feedback = false;
    if (feedback) {

        this.store.select(state => state.interests_switch.model).pipe(take(1)).subscribe(m => {
          const offer = m.offers.find(d => d.offer_id.toString() == m.selected_offer_id);
          if (offer) {
            this.store.dispatch(fetchClaimed({ id: offer.offer_id.toString() }));
            this.store.dispatch(setSelectedOfferId({ offer_id: null }));
          }
        });
  
    }
  }

  public changeDisplayMode() {
    this.store.dispatch(setDisplayMode({mode: null}))

  }

}
