import { ContractLockDetails, GeneralOrderDetails, UserAttribute } from "./beta-optimize-models.model";
import { ProviderRes } from "./subscription-plan.model";

// abstract class PaylowModel {
//   static backwardsCompatible<T extends PaylowModel>(this: new () => T, data: any): T {
//       return data as T;
//   }
// }



export interface Invoice {
  content : string,
  filetype : string
}

export interface SubscriptionInvoiceRequest {
  invoice_model : {
    subscription_id : string
    content : string
    filetype : string
  }
}

export interface SubscriptionInvoiceResponse {
  status : SubscriptionStatus
  invoices : SubscriptionInvoice[]
}

export interface Dashboard {
  subscriptions : Array<PaylowSubscription>;
  new_bank_ids : string[];
  total_monthly_payments? : number;

}

export type SubscriptionStatusType = 'improvable' | 'improvable-auto' | 
                                     'contract-lock' | 'order-submitted'| 'order-detected' |
                                     'category-ambiguity' |
                                     'cancel-pre' | 'cancel-in-progress' | 'cancel-post' |
                                     'upVote-pre' | 'upVote-post' |
                                     'comingSoon-pre' | 'comingSoon-post' |
                                     'inProgress' | 'invoiceInProgress' | 
                                     'update-category' |
                                     'perkbox-improvable' | 'perkbox-improvable-mock' | 'perkbox-newable' | 'partner-improvable' | 'improvable-alternative' | 'partner-newable' | 'sprive-improvable' |
                                  'improvable-bundle' | 'improvable-interest' | 'add-provider';

export interface SubscriptionInvoice {
  filename : string;
}

export interface SubscriptionStatus {
  type: SubscriptionStatusType;
  timestamp : string;
  details? : any; // ContractLockDetails | GeneralOrderDetails | ; 
}

export interface CustomizationFile {
  filename : string;
  hidden_sub_ids : string[];
  manual_subs : ManualSubscription[];
}

export interface Transaction {
  id : string,
  group : number,
  amount : number, 
  month : string
}

export interface SubscriptionSource {
  display_name : string;
  logo_url : string;
}

export interface SubscriptionCategory {
  name : string;
  ambiguity_names? : string[];
}

export class PaylowSubscription  {
  id: string;
  name: string;
  logoUrl: string;
  category: SubscriptionCategory;
  activePlan: SubscriptionPlan;
  status: SubscriptionStatus;
  lastPayments: Transaction[];
  source : SubscriptionSource;
  num_groups : number;
  admin_data?: AdminSub;
}

type Tag = 'true' | 'false' | 'untag';

export interface SubscriptionProviderData {
  provider_id: string;
  provider_name: string;
  presentative_name: string;
  category: string;
  logo_url: string;
  domain: string;
  single_transactions: number;
}

export interface SubscriptionProvider {
  provider_data : SubscriptionProviderData;
  regex : string;
  review : string;
}

export interface AdminSub {
  tag: string;
  transactions_refs: string[];
  source: string;
  provider : SubscriptionProvider
}

type Period = 'yearly' | 'monthly' | 'daily' | string;

export interface SubscriptionPlan {
  name: string;
  id: string;
  description: string;
  logoUrl?: string;
  price: number;
  period: Period;
}


export interface PersonalizationModel {
  postcode : UserAttribute;
  mob_data_package: UserAttribute;
  mob_roaming: UserAttribute;
  mob_coverage: UserAttribute;
  mob_prefer_5g: UserAttribute;
  mob_current_provider : UserAttribute; 
  mob_current_monthly_price : UserAttribute;
  bb_price_vs_speed : UserAttribute;
  bb_short_contracts : UserAttribute;
  bb_landline : UserAttribute; 
  bb_switch_remote : UserAttribute;
  bb_activities : UserAttribute;
  address_id : UserAttribute;
  energy_address_id : UserAttribute;
  contract_locks : UserAttribute;
  handset_up_front_vs_monthly_price : UserAttribute;
  handset_feature_preferences : UserAttribute;
  handset_model_preference : UserAttribute;
  handset_colour_preference : UserAttribute;
  handset_top_monthly_price : UserAttribute;
  handset_brand_preference : UserAttribute;
  handset_activities : UserAttribute;
  energy_payment_method : UserAttribute;
  num_people: UserAttribute;
  num_bedrooms: UserAttribute;
  energy_economy_7: UserAttribute;
  energy_ev: UserAttribute;
  energy_gas_tariff_id: UserAttribute;
  energy_elec_tariff_id: UserAttribute;
  energy_mprn: UserAttribute;
  energy_smart_meter: UserAttribute;
}


export interface BaseNotificationItem {
    type: string,
    id: string,
    dismissed: boolean,
    result:string,
    severity: "HIGH" | "MEDIUM" | "LOW",
    subscription_id ? : string,
    category ? : string,
    timestamp: string,
    total_deals? : number,
    relevant_deals? : number,
    min_saving? : number,
    max_saving? : number,
    avg_saving? : number
}

export interface OptimizationNotificationItem extends BaseNotificationItem {
  total_deals : number,
  relevant_deals : number,
  min_saving : number,
  max_saving : number,
  avg_saving : number
}


export interface Provider {
  display_name: string;
  id: number;
  logo_url: string;
}


export interface ManualSubscription {
  category: string;
  provider: string | number;
  amount: number;
  subscription_id?: string;
}


export type NotificationsList = BaseNotificationItem[];


export interface StateObject {
  value: any;
  timestamp: string;
}

export interface DashboardStateModel {
  clicked_benefits?: StateObject;
  clicked_interests?: StateObject;
  delayed_interests_shown?: StateObject;
}
