// src/app/store/auth/logout.actions.ts
import { ActionReducer, MetaReducer, createAction } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

export const logout = createAction('[Auth] Logout');

export function clearStateMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === logout.type) {
      const { session, circlet,  ...rest } = state || {};

      const new_state = { session, circlet };
      return reducer(new_state, action);
    }
    return reducer(state, action);
  };
}

function serializeWithDate(state: any) {
  return {
    ...state,
    timestamp: Date.now(),
  };
}

function getDeserializerWithDate(hours: number) {
  return (state: any) => {
    if (state) {
      const now = Date.now();
      const expirationTime = hours * 60 * 60 * 1000;

      if (now - state.timestamp < expirationTime) {
        return state;
      }
    }
    return undefined;
  };
}

// export function localStorageSyncReducer(reducer: any) {
//   return (state: any, action: any) => {
//     const syncedReducer = localStorageSync({
//       keys: [
//         {
//           journey: {
//             serialize: serializeWithDate,
//             deserialize: getDeserializerWithDate(1),
//           },
//         },
//       ],
//       rehydrate: true,
//       storageKeySerializer: (key) => `Paylow.State.${key}`,
//       storage: localStorage,
//     })(reducer);

//     return syncedReducer(state, action);
//   };
// }


export const metaReducers: MetaReducer<any>[] = [
  clearStateMetaReducer, 
  // localStorageSyncReducer
];
