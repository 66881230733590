import { BenefitsClaimComponent } from "src/app/components/product/common/modals/benefits-claim/benefits-claim.component";
import { CrossBrowsersComponent } from "src/app/components/product/common/modals/cross-browsers/cross-browsers.component";
import { FaqStandaloneComponent } from "src/app/components/product/common/modals/faq-standalone/faq-standalone.component";
import { LeaveFeedbackComponent } from "src/app/components/product/common/modals/leave-feedback/leave-feedback.component";
import { UpdateCategoryModalComponent } from "src/app/components/product/common/modals/update-category-modal/update-category-modal.component";
import { PersonalizationModalComponent } from "src/app/components/product/common/modals/personalization-modal/personalization-modal.component";
import { TncComponent } from "src/app/components/product/common/modals/tnc/tnc.component";
import { ConsentModalComponent } from "src/app/components/product/consent/consent-modal/consent-modal.component";
import { ProfileModalComponent } from "src/app/components/product/dashboard/profile-modal";
import { ContractLockComponent } from "src/app/components/product/dashboard/subscription-panel/common/contract-lock/contract-lock.component";
import { RedirectModalComponent } from "src/app/components/product/common/modals/redirect-modal/redirect-modal.component";
import {AddSubscriptionComponent} from "src/app/components/product/common/modals/add-subscription/add-subscription.component";
import { CalendlyModalComponent } from "src/app/components/product/common/modals/calendly-modal/calendly-modal.component";
import { ChangeCategoryModalComponent } from "src/app/components/product/common/modals/change-category-modal/change-category-modal.component";
import { SwitchPanelBenefitsComponent } from "src/app/components/product/dashboard/subscription-panel/switch-panel-benefits/switch-panel-benefits.component";
import { ReferAFriendComponent } from "src/app/components/product/common/modals/refer-a-friend/refer-a-friend.component";
import { SwitchPanelInterestsComponent } from "src/app/components/product/dashboard/subscription-panel/switch-panel-interests/switch-panel-interests.component";




export const modalsMapping = {
    tnc: {name: TncComponent, options: {size: 'lg', modalDialogClass: 'modal-60vh'} },
    feedback: {name: LeaveFeedbackComponent },
    crossbrowser: {name: CrossBrowsersComponent },
    bank_selection: {name: ConsentModalComponent },
    contract_lock: {name: ContractLockComponent, options: {modalDialogClass: 'modal-padded'} },
    benefit_coming_soon: {name: BenefitsClaimComponent, options: {size: 'md'}},
    update_category: {name: UpdateCategoryModalComponent},
    improve_invest: {name: RedirectModalComponent},
    add_subscription: {name: AddSubscriptionComponent},
    schedule: {name:CalendlyModalComponent, options: {size: 'xl'}},
    
    change_category: {name: ChangeCategoryModalComponent},
    personalization: {name: PersonalizationModalComponent, options: {size: 'xl'}, url: 'personalization' },
    settings: {name: ProfileModalComponent, options: {size: 'lg'} , url: 'settings', 'tab': 'profile'},
    faq: {name: FaqStandaloneComponent, options: {size: 'lg', modalDialogClass: 'modal-60vh'}, url: 'settings', 'tab': 'faq' },
    benefits: {name: SwitchPanelBenefitsComponent, options: {size: 'xl', modalDialogClass: 'modal-60vh'}, url: 'discover', params: {tab: 'benefits'}},
    interests: {name: SwitchPanelInterestsComponent, options: {size: 'xl', modalDialogClass: 'modal-60vh'}, url: 'discover', params: {tab: 'interests'}},

    refer: {name: ReferAFriendComponent},

}