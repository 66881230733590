import { Action, Store, createAction, createFeatureSelector, createSelector, props, select } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, act, createEffect, ofType } from '@ngrx/effects';
import { merge, of } from 'rxjs';
import { map, mergeMap, catchError, withLatestFrom, tap, filter, concatMap, delay, take } from 'rxjs/operators';
import { ProductApiService, addMinimumWaitTime } from 'src/app/services/product-api/product-api.service';
import { BroadbandDealDetails, BroadbandSwitchObject, MobileSwitchObject, UserAttribute, UserAttributesUpdateRequest } from 'src/app/types/beta-optimize-models.model';
import { ProductService } from 'src/app/services/product-service/product-service.service';
import { Draft, produce } from 'immer';
import { state } from '@angular/animations';
import { PersonalizationModel } from 'src/app/types';


export interface UserAttributesState {
    api_calls: string[],
    user_attributes: PersonalizationModel,
    pre_updated_batch: UserAttribute[]
}

// Initial state
const initialState: UserAttributesState = {
    api_calls: [],
    user_attributes: null,
    pre_updated_batch: [],

};


export const addApiCall = createAction('[Attributes] Add hangning', props<{ name: string }>());
export const removeApiCall = createAction('[Attributes] Remove hangning', props<{ name: string }>());

export const setAttributes = createAction('[Attributes] setAttributes', props<{ attributes: PersonalizationModel }>());
export const setNewAttributes = createAction('[Attributes] setNewAttributes', props<{ newAttributes: UserAttribute[] }>());
export const setNewAttribute = createAction('[Attributes] setNewAttribute', props<{ newAttribute: UserAttribute[] }>());


export const addBatchUpdate = createAction('[Attributes] addBatchUpdate', props<{ attributes: UserAttributesUpdateRequest }>());
export const clearBatchUpdated = createAction('[Attributes] clearBatchUpdated');
export const fetchBatchUpdate = createAction('[Attributes] fetchBatchUpdate');

export const fetchAttributes = createAction('[Attributes] fetchAttributes');
export const fetchAttribute= createAction('[Attributes] fetchSingleAttribute', props<{ key: string }>());

export const fetchUpdateAttributes = createAction('[Attributes] fetchUpdateAttribute', props<{ attributes: UserAttributesUpdateRequest }>());


export const setError = createAction('[Attributes] Error', props<{ error }>());


export const personalizationReducer = createReducer(
    initialState,
    on(setAttributes, (state, { attributes }) => ({
        ...state,
        user_attributes: attributes
    })),
    on(setNewAttributes, (state, { newAttributes }) => {
        return produce(state, draft => {
            newAttributes.forEach(update_attribute => {
                draft.user_attributes[update_attribute.key] = update_attribute;
            });
            draft.pre_updated_batch = [];
        });
    }),
    on(setNewAttribute, (state, { newAttribute }) => {
        return produce(state, draft => {
            newAttribute.forEach(update_attribute => {
                draft.user_attributes[update_attribute.key] = update_attribute;
            });
            draft.pre_updated_batch = [];
        });
    }),
    on(addApiCall, (state, { name }) => ({
        ...state,
        api_calls: [...state.api_calls, state.api_calls.includes(name) ? null : name]
    })),
    on(removeApiCall, (state, { name }) => ({
        ...state,
        api_calls: state.api_calls.filter(n => n != name)
    })),
    on(clearBatchUpdated, (state) => ({
        ...state,
        api_calls: state.api_calls.filter(n => !n?.includes('update_attribute'))
    })),
    on(addBatchUpdate, (state, { attributes }) => ({
        ...state,
        pre_updated_batch: [...state.pre_updated_batch.filter(i => !attributes.attributes.some(j => i.key == j.key)), ...attributes.attributes]
    })),
    on(setError, (state, {error}) => {
        console.error(error);
        return state;
    }),
);


@Injectable()
export class PersonalizationEffects {
    constructor(
        private actions$: Actions,
        private api: ProductApiService,
        private product: ProductService,
        private store: Store<{ user_attributes: UserAttributesState }>
    ) { }

    fetchAttributes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchAttributes),
            withLatestFrom(this.store.select(state => state.user_attributes)),
            tap(([action, state]) => { this.store.dispatch(addApiCall({ name: 'user_attributes' })) }),
            mergeMap(([action, state]) =>
                this.api.readPersonalizationAttribute([]).pipe(
                    map(res => {
                        const updates = res.reduce((acc, item) => {
                            acc[item.key] = item;
                            return acc;
                          }, {});
                        return updates as PersonalizationModel;
                    }),
                    concatMap(ret => [
                        setAttributes({ attributes: ret }),
                        removeApiCall({ name: 'user_attributes' }),
                    ]),
                    catchError((e) => of(setError({error:e}))) // Handle errors appropriately
                )
            )
        )
    );

fetchAttribute$ = createEffect(() =>
    this.actions$.pipe(
        ofType(fetchAttribute),
        withLatestFrom(this.store.select(state => state.user_attributes)),
        tap(([action, state]) => { 
            this.store.dispatch(addApiCall({ name: 'user_attributes' })) 
        }),
        mergeMap(([action, state]) =>
            this.api.readPersonalizationAttribute([action.key]).pipe(
                map(res => {
                    const updates = res.map(item => ({
                        key: item.key,
                        value: item.value,
                        source: item.source
                    })); // Transform response into an array of UserAttribute objects
                    
                    return setNewAttribute({ newAttribute: updates }); // Dispatch setNewAttributes
                }),
                concatMap(action => [
                    action,
                    removeApiCall({ name: 'user_attributes' })
                ]),
                catchError((e) => of(setError({ error: e }))) // Handle errors
            )
        )
    )
);


    fetchUpdateAttribute$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchBatchUpdate),
            withLatestFrom(this.store.select(state => state.user_attributes)),
            filter(([action,state]) => state.pre_updated_batch.length > 0), 
            tap(([action, state]) => { 
                state.pre_updated_batch.forEach(attr => {
                    this.store.dispatch(addApiCall({ name: 'update_attribute#' + attr.key }));
                });
            }),
            mergeMap(([action, state]) =>
                this.api.addPersonalizationAttribute({attributes: state.pre_updated_batch}).pipe(
                    concatMap(ret => {
                        return [
                            setNewAttributes({newAttributes : ret}),
                            ...ret.map(attr => removeApiCall({ name: 'update_attribute#' + attr.key }))
                        ];
                    }),
                    catchError(e => of(setError({error: e}
                    ))) // Handle errors appropriately
                )
            )
        )
    );

}